import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  format,
  addDays,
  differenceInCalendarDays,
  isBefore,
  isValid,
  isFuture,
} from 'date-fns';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  usePermissions,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormGroup,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {
  AutocompleteInput,
  ReferenceInput,
  FormTextField,
  SelectComponent,
  FileInput,
  CheckboxInput,
  handleNumbers,
  getDateInputValue,
  RadioInput,
  minDate,
  validateDate,
  composeValidators,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import { authGet } from '../../../../server';
import { weekDays, constants } from '../../../../utils';
import { useGetCaseId } from '../../../../hooks';
import { uiActions } from '../../../../state/actions';
import { SaveButton } from '../../../../design';
const { updateTypes, caseTypes, pendingChances } = constants;

const UPDATES_ONLY_ID = 16;
const AUTH_MISSING_ID = 6;
const AUTH_ON_HOLD_ID = 8;
const AUTH_UPDATES_STATUS_PENDING = 5;
const PART_B_AUTH_ID = 18;
const DENIAL_RECEIVED = 3;
const DENIED = 4;

export const AuthorizationForm = ({
  isEdit,
  caseId,
  record,
  handleClose,
  refresh,
  authorizationId,
}) => {
  const classes = useStyles();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const dispatch = useDispatch();
  const caseIdFromUrl = useGetCaseId();
  const userId = useSelector(state => state.user.profile.id);
  const { permissions = '' } = usePermissions();
  const [formData, setFormData] = useState({
    case_id: caseId,
    authorization_status_id: !isEdit ? 1 : undefined,
    update_type: [],
    update_weekday: [],
    schedule_type: '',
  });

  const [pt_option, setPtOption] = useState('units');
  const [ot_option, setOtOption] = useState('units');
  const [speech_option, setSpeechOption] = useState('units');
  const [submit, setSubmit] = useState(false);
  useEffect(() => {
    if (isEdit && record && !authorizationId) {
      populateForm(record, setFormData);
    } else {
      const userPermissions = permissions.split(',');
      setFormData(f => ({
        ...f,
        user_id:
          userPermissions.indexOf('case_manager') > -1 ? userId : undefined,
      }));
    }
  }, [isEdit, record, permissions, userId, authorizationId]);

  useEffect(() => {
    async function fetchAuth() {
      const data = await fetchResource(`/authorizations/${authorizationId}`);
      if (data !== 'error') {
        populateForm(data, setFormData);
      }
    }
    if (authorizationId) {
      fetchAuth();
    }
  }, [authorizationId]);

  const onSubmit = value => {
    setSubmit(true);
    const { update_frequency: _update_frequency } = value;
    const update_frequency =
      _update_frequency !== 'freq_days' ? _update_frequency : null;
    const outpatient_services = getOutpatientServicesValues(value, notify);
    if (outpatient_services === 'error') return Promise.resolve();
    if (isEdit) {
      const { rate, ...rest } = value;
      return dataProvider
        .update('authorizations', {
          id: record.id || value.authorization_id,
          data: {
            ...rest,
            update_frequency,
            rate: rate ? parseFloat(rate) : null,
            schedule_type: undefined,
            outpatient_services,
          },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          setSubmit(false);
          handleClose();
          if (caseIdFromUrl) {
            dispatch(uiActions.getCaseTasksCount(caseIdFromUrl));
          }
          typeof refresh === 'function' && refresh();
          dispatch(uiActions.getSidebarCases());
        })
        .catch(
          error =>
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning',
            ),
          setSubmit(false),
        );
    } else {
      return dataProvider
        .create('authorizations', {
          data: {
            ...value,
            update_frequency,
            schedule_type: undefined,
            outpatient_services,
          },
        })
        .then(({ data }) => {
          notify('form.created');
          setSubmit(false);
          handleClose();
          if (caseIdFromUrl) {
            dispatch(uiActions.getCaseTasksCount(caseIdFromUrl));
          }
          typeof refresh === 'function' && refresh();
          dispatch(uiActions.getSidebarCases());
        })
        .catch(
          error =>
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning',
            ),
          setSubmit(false),
        );
    }
  };

  const customOnChange = async (eventOrValue, _name, type) => {
    return authOnChange({
      eventOrValue,
      _name,
      type,
      isEdit,
      setFormData,
      formData,
    });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <DialogContent>
                {!isEdit && !caseId && (
                  <div style={{ paddingBottom: 15, marginRight: 3 }}>
                    <AutocompleteInput
                      reference='cases/list'
                      customOnChange={customOnChange}
                      name='case_id'
                      label='Case'
                      validate={required()}
                      required
                      fullWidth
                      openOnFocus
                      autocompleteProps={{ openOnFocus: true }}
                      options={{
                        filter: { division: 'all' },
                      }}
                    />
                  </div>
                )}

                {values.case_id >= 0 && (
                  <div className={clsx(classes.inputContainerWrap)}>
                    <ReferenceInput
                      key={values.case_id}
                      reference='cases-payers/list'
                      customOnChange={customOnChange}
                      name='cases_payers_id'
                      label='Payer'
                      validate={required()}
                      required
                      fullWidth
                      options={{
                        filter: { case_id: values.case_id },
                      }}
                      style={{ marginRight: 0 }}
                    />
                  </div>
                )}
                <AuthFields
                  isEdit={isEdit}
                  customOnChange={customOnChange}
                  caseId={caseId}
                  values={values}
                  record={record}
                  formData={formData}
                  setFormData={setFormData}
                  authorizationId={authorizationId}
                  ot_option={ot_option}
                  pt_option={pt_option}
                  speech_option={speech_option}
                  setOtOption={setOtOption}
                  setPtOption={setPtOption}
                  setSpeechOption={setSpeechOption}
                />
                {!isEdit && (
                  <div className={classes.insuranceTitle}>
                    Attachments
                    <FileInput
                      title='title'
                      name='attachments'
                      setFormData={setFormData}
                      formData={formData}
                      classes={{ dropZone: classes.dropZone }}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting || submit}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

export const AuthFields = ({
  isEdit,
  customOnChange,
  caseId,
  values,
  record,
  formData,
  setFormData,
  authorizationId,
  pt_option,
  ot_option,
  speech_option,
  setPtOption,
  setOtOption,
  setSpeechOption,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={clsx(classes.inputContainerWrap)}>
        {values.case_id >= 0 && (
          <ReferenceInput
            reference='auth-number/list'
            customOnChange={customOnChange}
            name='authorization_id'
            label='Auth number'
            disabled={!!authorizationId}
            style={{
              marginRight: values.authorization_id === 0 ? 8 : 0,
            }}
            resetOption={{ id: 0, name: 'Add new auth #' }}
            options={{
              filter: { case_id: values.case_id },
            }}
          />
        )}
        {values.authorization_id === 0 && (
          <FormTextField
            name='insurance_auth_number'
            validate={required()}
            required
            customOnChange={customOnChange}
            label='Auth number'
          />
        )}
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        <ReferenceInput
          reference='facility-users/list'
          customOnChange={customOnChange}
          name='user_id'
          label='Case manager'
          validate={required()}
          shouldFetchMore
          required
          options={{
            filter: { create: !!!isEdit },
          }}
        />
        <ReferenceInput
          customOnChange={customOnChange}
          label='Auth status'
          validate={composeValidators([required(), validateAuthStatus])}
          required
          name='authorization_status_id'
          reference='auths-status/list'
          options={{
            filter: {
              create: !!!isEdit,
              current: record ? record.authorization_status_id : undefined,
            },
          }}
        />
      </div>
      {values.authorization_status_id === AUTH_UPDATES_STATUS_PENDING && (
        <Fragment>
          <SelectComponent
            customOnChange={customOnChange}
            label='Likelihood of approval'
            validate={required()}
            required
            name='approval_chance'
            choices={pendingChances}
            fullWidth
          />
          <FormTextField
            name='pending_reason'
            label='Pending reason'
            multiline
            fullWidth
            customOnChange={customOnChange}
            validate={required()}
            required
          />
        </Fragment>
      )}
      <div className={clsx(classes.inputContainerWrap)}>
        <SelectComponent
          customOnChange={customOnChange}
          label='Case type'
          validate={required()}
          required
          name='is_mltc'
          choices={caseTypes}
        />
        <ReferenceInput
          key={values.is_mltc}
          reference='auth-types/list'
          options={{
            filter: {
              mltc: formData.is_mltc ? 1 : [],
              subacute: formData.is_mltc ? [] : 1,
            },
          }}
          customOnChange={customOnChange}
          name='authorization_type_id'
          label='Auth type'
          validate={required()}
          required
        />
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        <FormTextField
          name='rev_code'
          label='Rev code'
          customOnChange={customOnChange}
        />
        <FormTextField
          name='start_date'
          validate={composeValidators([required(), validateDate()])}
          required
          customOnChange={customOnChange}
          type='date'
          label='Start date'
        />
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        <FormTextField
          name='days_approved'
          customOnChange={customOnChange}
          validate={
            values.authorization_status_id !== AUTH_UPDATES_STATUS_PENDING &&
            values.authorization_status_id !== AUTH_MISSING_ID &&
            values.authorization_status_id !== AUTH_ON_HOLD_ID &&
            values.authorization_type_id !== UPDATES_ONLY_ID &&
            values.authorization_type_id !== PART_B_AUTH_ID
              ? required()
              : undefined
          }
          required={
            values.authorization_status_id !== AUTH_UPDATES_STATUS_PENDING &&
            values.authorization_status_id !== AUTH_MISSING_ID &&
            values.authorization_status_id !== AUTH_ON_HOLD_ID &&
            values.authorization_type_id !== UPDATES_ONLY_ID &&
            values.authorization_type_id !== PART_B_AUTH_ID
          }
          disabled={
            values.authorization_status_id === DENIED ||
            values.authorization_status_id === DENIAL_RECEIVED
          }
          label='Days approved'
          type='number'
        />
        <FormTextField
          name='end_date'
          validate={getEndDateValidators(values)}
          min={values.start_date}
          required={
            values.authorization_status_id !== AUTH_UPDATES_STATUS_PENDING &&
            values.authorization_status_id !== AUTH_MISSING_ID &&
            values.authorization_status_id !== AUTH_ON_HOLD_ID &&
            values.authorization_type_id !== UPDATES_ONLY_ID &&
            values.authorization_type_id !== PART_B_AUTH_ID
          }
          customOnChange={customOnChange}
          type='date'
          label='Initial end date'
        />
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        {isEdit && (
          <Fragment>
            <FormTextField
              name='actual_end_date'
              customOnChange={customOnChange}
              type='date'
              label='Actual end date'
              min={values.start_date}
              validate={composeValidators([
                validateDate(),
                minDate(
                  values.start_date,
                  'End date cannot be before the start date',
                ),
              ])}
            />
            <div style={{ flex: '1 0 160px' }} />
          </Fragment>
        )}
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        <FormTextField
          name='days_available'
          customOnChange={customOnChange}
          label='Days available'
          disabled
        />
        <FormTextField
          name='rate'
          customOnChange={customOnChange}
          type='number'
          label='Rate'
          step='any'
        />
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        <FormTextField
          name='letter_received'
          customOnChange={customOnChange}
          type='date'
          label='Auth letter received'
          validate={composeValidators([validateDate()])}
        />
        <ReferenceInput
          reference='auth-letter-status/list'
          customOnChange={customOnChange}
          name='auth_letter_status_id'
          label='Auth letter status'
        />
      </div>
      <FormTextField
        name='auth_notes'
        label='Notes'
        multiline
        fullWidth
        customOnChange={customOnChange}
      />
      {values.authorization_type_id === PART_B_AUTH_ID && (
        <Fragment>
          <DialogContentText
            align='left'
            variant='h6'
            className={classes.header}
          >
            Services
          </DialogContentText>
          <FormGroup row>
            <CheckboxInput
              name='pt'
              customOnChange={customOnChange}
              label='PT'
              checked={values.pt}
            />
            <CheckboxInput
              name='ot'
              customOnChange={customOnChange}
              label='OT'
              checked={values.ot}
            />
            <CheckboxInput
              name='speech'
              customOnChange={customOnChange}
              label='Speech'
              checked={values.speech}
            />
          </FormGroup>
          {values.pt && (
            <Fragment>
              <p className={classes.sectionSubHeader}>PT requested</p>
              <div style={{ marginTop: 15, marginBottom: -15 }}>
                <MinuteUnitToggle setState={setPtOption} curValue={pt_option} />
              </div>
              <FormTextField
                name='pt_frequency'
                customOnChange={customOnChange}
                label='Amount of visits'
                type='number'
                validate={required()}
                required
              />
              {pt_option === 'minutes' ? (
                <FormTextField
                  name='pt_minutes'
                  customOnChange={customOnChange}
                  label='Minutes'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              ) : (
                <FormTextField
                  name='pt_units'
                  customOnChange={customOnChange}
                  label='Units'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              )}
            </Fragment>
          )}
          {values.ot && (
            <Fragment>
              <p className={classes.sectionSubHeader}>OT requested</p>
              <div style={{ marginTop: 15, marginBottom: -15 }}>
                <MinuteUnitToggle setState={setOtOption} curValue={ot_option} />
              </div>
              <FormTextField
                name='ot_frequency'
                customOnChange={customOnChange}
                label='Amount of visits'
                type='number'
                validate={required()}
                required
              />
              {ot_option === 'minutes' ? (
                <FormTextField
                  name='ot_minutes'
                  customOnChange={customOnChange}
                  label='Minutes'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              ) : (
                <FormTextField
                  name='ot_units'
                  customOnChange={customOnChange}
                  label='Units'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              )}
            </Fragment>
          )}
          {values.speech && (
            <Fragment>
              <p className={classes.sectionSubHeader}>Speech requested</p>
              <div style={{ marginTop: 15, marginBottom: -15 }}>
                <MinuteUnitToggle
                  setState={setSpeechOption}
                  curValue={speech_option}
                />
              </div>
              <FormTextField
                name='speech_frequency'
                customOnChange={customOnChange}
                label='Amount of visits'
                type='number'
                validate={required()}
                required
              />
              {speech_option === 'minutes' ? (
                <FormTextField
                  name='speech_minutes'
                  customOnChange={customOnChange}
                  label='Minutes'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              ) : (
                <FormTextField
                  name='speech_units'
                  customOnChange={customOnChange}
                  label='Units'
                  type='number'
                  style={{ marginRight: 0 }}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
      <DialogContentText align='left' variant='h6' className={classes.header}>
        Updates
      </DialogContentText>
      <p className={classes.subHeader}>
        Set up a single or recurring updates schedule
      </p>
      {!values.inactive_schedule && (
        <div>
          <Divider className={classes.divider} />
          <div style={{ marginBottom: 10 }}>
            <RadioInput
              name='schedule_type'
              className={classes.radio}
              customOnChange={customOnChange}
              value={formData.schedule_type}
              validate={
                values.authorization_status_id !== AUTH_ON_HOLD_ID && required()
              }
              required={values.authorization_status_id !== AUTH_ON_HOLD_ID}
              label='Update schedule'
              radios={[
                {
                  label: 'No update schedule',
                  value: 'no_update',
                },

                { label: 'One-time update', value: 'one_time' },
                { label: 'Recurring by date', value: 'recurring_date' },
                {
                  label: 'Recurring by day/days of the week ',
                  value: 'recurring_day',
                },
              ]}
            />
          </div>
          <div className={clsx(classes.inputContainerWrap)}>
            {values.schedule_type === 'no_update' && (
              <FormTextField
                name='no_update_reason'
                label='No updates reason'
                multiline
                fullWidth
                customOnChange={customOnChange}
                validate={required()}
                required
              />
            )}
          </div>
          <div className={clsx(classes.inputContainerWrap)}>
            {(values.schedule_type === 'one_time' ||
              values.schedule_type === 'recurring_date') && (
              <FormTextField
                name='initial_update_date'
                customOnChange={customOnChange}
                type='date'
                label='Select date'
                required={values.schedule_type === 'recurring_date'}
                validate={composeValidators([validateDate(), ...[required()]])}
              />
            )}
            {values.schedule_type === 'recurring_day' && (
              <SelectComponent
                customOnChange={customOnChange}
                label='Specific days of week'
                multiple
                renderWith='chip'
                formField={formData.update_type}
                name='update_weekday'
                choices={weekDays}
                validate={required()}
              />
            )}

            {values.schedule_type === 'recurring_date' ||
            values.schedule_type === 'recurring_day' ? (
              <SelectComponent
                customOnChange={customOnChange}
                label='Recurring frequency'
                name='update_frequency'
                choices={getRecurringChoices(
                  values.schedule_type === 'recurring_date',
                )}
                validate={required()}
                required
                disabled={
                  Array.isArray(values.update_weekday) &&
                  values.update_weekday.length > 1
                }
                style={{ marginRight: 0 }}
              />
            ) : (
              <div style={{ flex: '1 0 160px' }} />
            )}
          </div>
          <div className={clsx(classes.inputContainerWrap)}>
            {values.update_frequency === 'freq_days' && (
              <FormTextField
                name='update_frequency_days'
                customOnChange={customOnChange}
                label='Frequency days'
                type='number'
                validate={required()}
                required
              />
            )}
          </div>
          {formData.schedule_type !== 'no_update' &&
            formData.schedule_type !== '' && (
              <SelectComponent
                customOnChange={customOnChange}
                label='Update type'
                multiple
                renderWith='chip'
                formField={formData.update_type}
                name='update_type'
                choices={updateTypes}
                fullWidth
                setFormData={setFormData}
                validate={required()}
              />
            )}
        </div>
      )}
      {formData.schedule_type !== 'no_update' &&
        formData.schedule_type !== '' && (
          <FormTextField
            name='update_notes'
            label='Update notes'
            multiline
            fullWidth
            customOnChange={customOnChange}
          />
        )}
      {isEdit && (
        <FormGroup row>
          <CheckboxInput
            name='inactive_schedule'
            customOnChange={customOnChange}
            label='Disable this update schedule'
            checked={values.inactive_schedule}
          />
        </FormGroup>
      )}
    </Fragment>
  );
};

export const authOnChange = async ({
  eventOrValue,
  _name,
  type,
  isEdit,
  setFormData,
  formData,
}) => {
  // when using a react-admin input, onChange returns
  // the value instead of the target.
  let value = eventOrValue;
  let name = _name;
  if (typeof eventOrValue === 'object') {
    const target = eventOrValue.target;
    value = target.type === 'checkbox' ? target.checked : target.value;
    name = target.name;
  }
  if (type === 'number') {
    value = handleNumbers(value);
  }
  if (type === 'date') {
    value = getDateInputValue(value);
  }

  if (name === 'authorization_id') {
    if (!isEdit && +value !== 0) {
      const data = await fetchResource('/auth-data', {
        id: value,
      });
      if (data !== 'error') {
        const {
          user_id,
          authorization_type_id,
          is_mltc,
          update_weekday,
          update_type,
          update_frequency,
          update_frequency_days,
          line_of_business_id,
        } = data;

        let rate, rev_code;
        if (formData.cases_payers_id) {
          const { cases_payers_id } = {
            cases_payers_id: formData.cases_payers_id,
          };
          const data = await fetchRateRevCode({
            authorization_type_id,
            cases_payers_id,
            line_of_business_id,
          });
          if (data !== 'error') {
            ({ rate, rev_code } = data);
          }
        }

        setFormData(f => ({
          ...f,
          [name]: value,
          ...data,
          rate,
          rev_code,
          user_id,
          authorization_type_id,
          line_of_business_id,
          is_mltc,
          update_weekday: Array.isArray(update_weekday) ? update_weekday : [],
          update_type: Array.isArray(update_type) ? update_type : [],
          schedule_type: setScheduleType(data),
          update_frequency: update_frequency
            ? update_frequency
            : update_frequency_days
            ? 'freq_days'
            : undefined,
        }));
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
    return;
  }

  if (
    name === 'authorization_type_id' ||
    name === 'cases_payers_id' ||
    name === 'payer_id' ||
    name === 'line_of_business_id'
  ) {
    const fetchValues = {
      authorization_type_id: formData.authorization_type_id,
      cases_payers_id: formData.cases_payers_id,
      payer_id: formData.payer_id,
      line_of_business_id: formData.line_of_business_id,
      [name]: value,
    };
    const {
      authorization_type_id,
      cases_payers_id,
      payer_id,
      line_of_business_id,
    } = fetchValues;
    if (authorization_type_id && cases_payers_id) {
      const data = await fetchRateRevCode({
        authorization_type_id,
        cases_payers_id,
        line_of_business_id,
      });
      if (data !== 'error') {
        const { rate, rev_code } = data;
        setFormData(f => ({
          ...f,
          [name]: value,
          rate: rate,
          rev_code: rev_code,
        }));
      }
    } else if (authorization_type_id && payer_id && line_of_business_id) {
      const data = await fetchRateRevCode({
        authorization_type_id,
        payer_id,
        line_of_business_id,
      });
      if (data !== 'error') {
        const { rate, rev_code } = data;
        setFormData(f => ({
          ...f,
          [name]: value,
          rate: rate,
          rev_code: rev_code,
        }));
      }
    } else {
      setFormData(f => ({ ...f, [name]: value }));
    }
    return;
  }

  if (name === 'authorization_status_id') {
    const end_date = new Date(`${formData.end_date} 00:00`);
    const start_date = new Date(`${formData.start_date} 00:00`);
    const isValidStart = isValid(start_date);
    const isValidEnd = isValid(end_date);
    setFormData({
      ...formData,
      authorization_status_id: value,
      days_approved:
        value !== DENIED && value !== DENIAL_RECEIVED
          ? isValidStart && isValidEnd
            ? differenceInCalendarDays(end_date, start_date) + 1
            : null
          : 0,
    });
    return;
  }
  if (name === 'start_date') {
    const { days_approved } = formData;
    const start_date = new Date(`${value} 00:00`);
    if (isValid(start_date) && days_approved) {
      const end_date = new Date(
        addDays(new Date(`${value} 00:00`), +days_approved - 1),
      );
      const now = new Date();
      const availableFromDate = isFuture(start_date) ? start_date : now;
      setFormData({
        ...formData,
        start_date: value,
        end_date: format(end_date, 'yyyy-MM-dd'),
        days_available: isBefore(availableFromDate, end_date)
          ? differenceInCalendarDays(end_date, availableFromDate)
          : 0,
      });
    } else {
      setFormData({ ...formData, start_date: value });
    }
    return;
  }
  if (name === 'days_approved') {
    const { start_date: _start_date } = formData;
    const start_date = new Date(`${_start_date} 00:00`);
    const { authorization_status_id } = formData;
    if (value && isValid(start_date)) {
      const end_date = new Date(addDays(start_date, value - 1));
      const now = new Date();
      const availableFromDate = isFuture(start_date) ? start_date : now;

      setFormData({
        ...formData,
        days_approved:
          authorization_status_id !== DENIED &&
          authorization_status_id !== DENIAL_RECEIVED
            ? value
            : 0,
        end_date: format(end_date, 'yyyy-MM-dd'),
        days_available: isBefore(availableFromDate, end_date)
          ? differenceInCalendarDays(end_date, availableFromDate)
          : 0,
      });
    } else {
      setFormData({
        ...formData,
        days_approved:
          authorization_status_id !== DENIED &&
          authorization_status_id !== DENIAL_RECEIVED
            ? value
            : 0,
      });
    }
    return;
  }
  if (name === 'end_date') {
    const { start_date: _start_date } = formData;
    const start_date = new Date(`${_start_date} 00:00`);
    const end_date = new Date(`${value} 00:00`);
    const { authorization_status_id } = formData;
    if (isValid(end_date) && isValid(start_date)) {
      const now = new Date();
      const availableFromDate = isFuture(start_date) ? start_date : now;
      setFormData({
        ...formData,
        days_approved:
          authorization_status_id !== DENIED &&
          authorization_status_id !== DENIAL_RECEIVED
            ? differenceInCalendarDays(end_date, start_date) + 1
            : 0,
        end_date: value,
        days_available: isBefore(availableFromDate, end_date)
          ? differenceInCalendarDays(end_date, availableFromDate)
          : 0,
      });
    } else {
      setFormData({ ...formData, end_date: value });
    }
    return;
  }

  if (name === 'initial_update_date') {
    setFormData(f => ({ ...f, [name]: value, update_weekday: [] }));
    return;
  }

  if (name === 'schedule_type') {
    const newState = { [name]: value };
    if (value === 'one_time' || value === 'recurring_day') {
      newState.update_frequency = null;
      newState.update_frequency_days = null;
      newState.no_update_reason = null;
    }
    if (value === 'one_time' || value === 'recurring_date') {
      newState.update_weekday = [];
      newState.no_update_reason = null;
    }
    if (value === 'recurring_day') {
      newState.initial_update_date = null;
      newState.no_update_reason = null;
    }
    if (value === 'no_update') {
      newState.update_weekday = [];
      newState.initial_update_date = null;
      newState.update_frequency = null;
      newState.update_frequency_days = null;
      newState.update_type = [];
      newState.update_notes = null;
    }
    setFormData(f => ({ ...f, ...newState }));
    return;
  }

  if (name === 'update_weekday') {
    if (Array.isArray(value) && value.length > 1) {
      setFormData(f => ({ ...f, [name]: value, update_frequency: 'weekly' }));
      return;
    }
  }

  if (name === 'update_frequency') {
    if (value !== 'freq_days') {
      setFormData(f => ({
        ...f,
        [name]: value,
        update_frequency_days: null,
      }));
      return;
    }
  }

  if (
    name === 'ot_minutes' ||
    name === 'pt_minutes' ||
    name === 'speech_minutes'
  ) {
    const newValues = { ...formData, [name]: value };
    const property = name.split('_')[0];
    const unitValue = value ? parseInt(value) / 15 : null;
    newValues[`${property}_units`] = unitValue;
    setFormData({ ...newValues });
    return;
  }

  if (name === 'ot_units' || name === 'pt_units' || name === 'speech_units') {
    const newValues = { ...formData, [name]: value };
    const property = name.split('_')[0];
    const minutesValue = value ? parseInt(value) * 15 : null;
    newValues[`${property}_minutes`] = minutesValue;
    setFormData({ ...newValues });
    return;
  }

  setFormData({ ...formData, [name]: value });
};

async function fetchResource(path, query = {}) {
  const response = await authGet([path, { ...query }]);
  if (response.error) return 'error';
  return response.data;
}

async function fetchRateRevCode(data = {}) {
  const response = await authGet(['auth-level-rate', { ...data }]);
  if (response.error) return 'error';
  return response.data;
}

function getRecurringChoices(isRecurringDate) {
  const options = [
    { id: 'weekly', name: 'Weekly' },
    { id: 'biweekly', name: 'Biweekly' },
    { id: 'monthly', name: 'Monthly' },
  ];
  if (isRecurringDate) {
    options.push({ id: 'freq_days', name: 'Frequency of days' });
  }
  return options;
}

function setScheduleType({
  initial_update_date,
  update_frequency_days,
  update_frequency,
  update_weekday,
  no_update_reason,
}) {
  if (Array.isArray(update_weekday) && update_weekday.length) {
    return 'recurring_day';
  } else if (update_frequency_days || update_frequency) {
    return 'recurring_date';
  } else if (initial_update_date) {
    return 'one_time';
  } else if (no_update_reason) {
    return 'no_update';
  } else {
    return '';
  }
}

function getEndDateValidators(values) {
  const validators = [
    validateDate(),
    minDate(values.start_date, 'End date can not be before the start date'),
  ];
  if (
    values.authorization_status_id !== AUTH_UPDATES_STATUS_PENDING &&
    values.authorization_status_id !== AUTH_MISSING_ID &&
    values.authorization_status_id !== AUTH_ON_HOLD_ID &&
    values.authorization_type_id !== UPDATES_ONLY_ID &&
    values.authorization_type_id !== PART_B_AUTH_ID
  ) {
    validators.push(required());
  }
  return composeValidators(validators);
}
function validateAuthStatus(value, values) {
  if (value === 2 /** approved ended */) {
    const endDate = values.actual_end_date || values.end_date;
    if (!endDate || isBefore(new Date(endDate), new Date())) {
      return;
    }
    return 'Auths that have not yet ended cannot be set to Approved-Ended';
  }
}

function populateForm(record, setFormData) {
  const now = new Date();
  const end_date = new Date(`${record.end_date} 00:00`);
  const start_date = new Date(`${record.start_date} 00:00`);
  const isValidStart = isValid(start_date);
  const isValidEnd = isValid(end_date);
  const {
    id,
    insurance_auth_number,
    cases_payer,
    authorization_status,
    authorization_status_id,
    status,
    authorization_type,
    case: _case,
    created_at,
    deleted_at,
    updated_at,
    name,
    payer_name,
    update_type,
    update_weekday,
    update_frequency,
    inactive_schedule,
    reated_by,
    updated_by,
    authorizationUpdatedBy,
    authorizationCreatedBy,
    outpatient_services: _outpatient_services,
    ...rest
  } = record;

  const outpatient_services = _outpatient_services ?? [];
  outpatient_services.forEach(s => {
    const { frequency, minutes, service } = s;
    const serviceName = service?.toLowerCase();
    rest[serviceName] = true;
    rest[`${serviceName}_frequency`] = frequency;
    rest[`${serviceName}_minutes`] = minutes;
    rest[`${serviceName}_units`] = minutes ? parseInt(minutes) / 15 : undefined;
  });

  setFormData(f => ({
    ...f,
    ...rest,
    days_approved:
      authorization_status_id !== DENIED &&
      authorization_status_id !== DENIAL_RECEIVED
        ? isValidStart && isValidEnd
          ? differenceInCalendarDays(end_date, start_date) + 1
          : null
        : 0,
    days_available:
      isValidEnd && isBefore(now, end_date)
        ? differenceInCalendarDays(end_date, now)
        : 0,
    authorization_id: id,
    update_type: Array.isArray(update_type) ? update_type : [],
    update_weekday: Array.isArray(update_weekday) ? update_weekday : [],
    schedule_type: setScheduleType(record),
    update_frequency: update_frequency
      ? update_frequency
      : record.update_frequency_days
      ? 'freq_days'
      : undefined,
    inactive_schedule: !!inactive_schedule,
    authorization_status: authorization_status,
    authorization_status_id: authorization_status_id,
  }));
}

function getOutpatientServicesValues(values, notify) {
  if (values.authorization_type_id !== PART_B_AUTH_ID) return;
  const arr = [];
  // eslint-disable-next-line default-case
  if (values.pt) {
    arr.push({
      service: 'PT',
      frequency: values.pt_frequency,
      minutes: values.pt_minutes,
    });
  }
  if (values.ot) {
    arr.push({
      service: 'OT',
      frequency: values.ot_frequency,
      minutes: values.ot_minutes,
    });
  }
  if (values.speech) {
    arr.push({
      service: 'Speech',
      frequency: values.speech_frequency,
      minutes: values.speech_minutes,
    });
  }
  if (!arr.length) {
    notify('You must enter outpatient services', 'warning');
    return 'error';
  }
  return arr.length && arr;
}

export function MinuteUnitToggle({ setState, curValue }) {
  const handleChange = useCallback(
    e => {
      setState(e.target.value);
    },
    [setState],
  );

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>Minutes/Units</FormLabel>
      <RadioGroup value={curValue} onChange={handleChange} row>
        <FormControlLabel value='minutes' control={<Radio />} label='Minutes' />
        <FormControlLabel value='units' control={<Radio />} label='Units' />
      </RadioGroup>
    </FormControl>
  );
}
