import { uiTypes } from '../actions/file-management';
const initialState = {
  activeFile: {
    drawStatus: 'closed',
    fileIds: [],
  },
};
export function fileManagementReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case uiTypes.UPDATE_FILE_MANAGEMENT_DATA:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          ...action.payload,
        },
      };
    case uiTypes.ADD_FILE_IDS:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          fileIds: [...state.activeFile.fileIds, ...action.payload],
          drawStatus:
            state.activeFile.drawStatus === 'closed'
              ? 'open'
              : state.activeFile.drawStatus,
        },
      };
    case uiTypes.REMOVE_FILE_ID:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          fileIds: state.activeFile.fileIds.filter(
            f => f + '' !== action.payload + '',
          ),
        },
      };
    case uiTypes.RESET_FILE_MANAGEMENT_DATA:
      return {
        ...state,
        activeFile: {
          ...initialState.activeFile,
        },
      };
    default:
      return state;
  }
}
