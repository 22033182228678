import React, { Fragment, useState, useEffect } from 'react';
import {
  useRefresh,
  TextField,
  TopToolbar,
  Pagination,
  usePermissions,
  useRedirect,
  List,
  FunctionField,
} from 'react-admin';
import { Datagrid } from '../components/common';
import { NewEvent } from '../components/common/modals';
import { CustomButton } from '../design/material-ui';
import { useStyles } from '../resources/payers/payers.styles';
import { AddIcon, EditIcon } from '../design';
import { IconButton } from '@material-ui/core';

const ConnectionsActions = ({ basePath, data = {}, resource }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [eventOpen, setEventModal] = useState(false);

  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Connections</span>

        {userPermissions.indexOf('admin') > -1 ||
        userPermissions.indexOf('contract_user') > -1 ? (
          <CustomButton
            Icon={AddIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Add'
            type='button'
            variant='text'
            size='small'
            onClick={() => setEventModal(true)}
          />
        ) : (
          //for spacing...
          <div />
        )}
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='connectionForm'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

export const ConnectionsList = props => {
  const classes = useStyles();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [connectionRecord, setConnectionRecord] = useState();
  const [eventOpen, setEventModal] = useState(false);
  useEffect(() => {
    // see comment on case_communication
    if (connectionRecord) {
      setEventModal(true);
    }
  }, [connectionRecord]);
  const handleModalClose = () => {
    setEventModal(false);
    setConnectionRecord(undefined);
  };
  const { permissions = '' } = props;
  if (!permissions) return null;
  const userPermissions = permissions.split(',');
  if (
    userPermissions.indexOf('admin') === -1 &&
    userPermissions.indexOf('supervisor') === -1 &&
    userPermissions.indexOf('contract_user') === -1
  ) {
    redirect('/');
    return null;
  }

  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        bulkActionButtons={
          Array.isArray(userPermissions) &&
          userPermissions.indexOf('admin') === -1 &&
          userPermissions.indexOf('supervisor') === -1 &&
          userPermissions.indexOf('contract_user') === -1
            ? false // disable delete
            : undefined // will show default delete
        }
        className={classes.list}
        actions={<ConnectionsActions />}
        sort={{
          field: 'clinical_keyword',
          order: 'ASC',
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid rowClick='show'>
          <TextField
            source='clinical_keyword'
            label='Clinical keyword'
            headerClassName={classes.listItemLabel}
          />
          <TextField
            source='category.category'
            label='Category'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          <TextField
            source='services'
            label='Services'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          <FunctionField
            source='status'
            label=''
            sortable={false}
            render={record => {
              return (
                <IconButton
                  style={{ color: '#5C738E' }}
                  onClick={() => setConnectionRecord(record)}
                  size='small'
                >
                  <EditIcon />
                </IconButton>
              );
            }}
          />
        </Datagrid>
      </List>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={connectionRecord}
          form='connectionForm'
          isEdit
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

export const connectionsResource = {
  name: 'connections',
  list: ConnectionsList,
  icon: AddIcon,
  options: { label: 'Connections' },
};
