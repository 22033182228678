import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
} from '@material-ui/core';

import { Dashboard } from '../../../pages';
import { required } from 'react-admin';
import { GroupFacilityToggle } from '../groupFacilityToggle';
import { useStyles } from '../../../pages/dashboard/dashboard.styles';
import { ReferenceInput } from './modalForms';
import { Form } from 'react-final-form';
import { usePermissions } from 'react-admin';

export function DashboardForm({ open, handleClose, facilityIds }) {
  const classes = useStyles();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [formData, setFormData] = useState({
    facility_ids: [facilityIds],
    group_id: [],
  });
  const [selectGroup, selectGroupToggle] = useState(false);
  const [otherUser, setOtherUser] = useState(false);
  useEffect(() => {
    selectGroup
      ? setFormData(f => ({ ...f, facility_ids: [], group_id: [] }))
      : setFormData(f => ({ ...f, group_id: null }));
  }, [selectGroup]);

  useEffect(() => {
    if (otherUser) {
      setFormData(f => ({
        ...f,
        facility_ids: [],
        group_id: [],
        user_id: null,
        selectGroup: false,
      }));
      selectGroupToggle(false);
    } else {
      setFormData(f => ({
        ...f,
        facility_ids: [],
        group_id: null,
        user_id: undefined,
      }));
    }
  }, [otherUser]);
  const onSubmit = value => {};
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xl' fullWidth>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                  }}
                >
                  {(userPermissions.indexOf('admin') > -1 ||
                    userPermissions.indexOf('supervisor') > -1) && (
                    <FormControlLabel
                      label={otherUser ? 'User Dashboards' : 'My Dashboards'}
                      control={
                        <Switch
                          className={classes.dashboard}
                          checked={otherUser}
                          onChange={() => setOtherUser(!otherUser)}
                        />
                      }
                    />
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                  }}
                >
                  <div>
                    {otherUser && (
                      <ReferenceInput
                        reference='facility-users/list'
                        customOnChange={customOnChange}
                        name='user_id'
                        label='Case manager'
                        required
                        fullWidth
                        style={{ marginRight: 0, width: 220, marginBottom: 0 }}
                        options={{
                          filter: { all_facilities: true, create: true },
                          sort: { field: 'last_name', order: 'ASC' },
                        }}
                        shouldFetchMore
                      />
                    )}

                    <div
                      style={{
                        width: '100%',
                      }}
                    ></div>

                    {userPermissions.indexOf('admin') > -1 && !otherUser && (
                      <GroupFacilityToggle
                        selectGroup={selectGroup}
                        selectGroupToggle={selectGroupToggle}
                        facilityLabel='Facilities'
                        groupLabel='Facility Groups'
                      />
                    )}
                    {userPermissions.indexOf('admin') > -1 &&
                      selectGroup &&
                      !otherUser && (
                        <ReferenceInput
                          style={{
                            width: 220,
                          }}
                          reference='facility-groups'
                          customOnChange={customOnChange}
                          name='group_id'
                          label='Select group'
                          validate={required()}
                          setFormData={setFormData}
                          required
                          perPage={500}
                          selectAll
                          options={{
                            filter: {},
                            sort: { field: 'name', order: 'ASC' },
                          }}
                        />
                      )}
                    {!selectGroup && (
                      <ReferenceInput
                        style={{
                          width: 220,
                        }}
                        key={formData.user_id}
                        reference='facilities/list'
                        customOnChange={customOnChange}
                        name='facility_ids'
                        label='Select facility'
                        validate={required()}
                        required
                        multiple
                        setFormData={setFormData}
                        perPage={500}
                        selectAll
                        disabled={otherUser && !values.user_id}
                        options={{
                          filter: { caseManagerId: formData.user_id },
                          sort: { field: 'name', order: 'ASC' },
                        }}
                      />
                    )}
                  </div>
                </div>
                <Dashboard
                  viewMultiple={true}
                  facility_ids={formData.facility_ids}
                  group_id={formData.group_id}
                  user_id={formData.user_id}
                />

                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </DialogContent>
            </form>
          );
        }}
      />
    </Dialog>
  );
}
