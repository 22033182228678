import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useRefresh } from 'react-admin';
import {
  AdmissionForm,
  AuthorizationForm,
  DenialForm,
  BedholdForm,
  DischargeForm,
  EditResidentForm,
  CasePayerForm,
  FacilityPayerForm,
  GlobalPayerForm,
  ConnectionForm,
  ServiceForm,
  QualifyingCriteriaForm,
  RatesForm,
  OutliersForm,
  PayerIntegrationDetailsForm,
  CommunicationGroup,
  FacilityContacts,
  FacilityGroups,
  FacilityForm,
  UsersForm,
  PharmLogForm,
  AppealEditForm,
  PayerRepForm,
  EditUpdateDate,
  CensusForm,
  DeactivateFacilityForm,
  EditPayerChange,
  BulkReassignUsers,
  EditCase,
  BulkReportsForm,
  FacilityTasksExportForm,
  TherapyForm,
  AssignFaxForm,
  ReportPermissionsForm,
  AutoTaskSettingsForm,
  PresetSettingsForm,
  EditMltcPayer,
  CloneContractForm,
} from './modalForms';
import { CloseIcon } from '../../../design';
import { useStyles } from './modal.styles';

export const NewEvent = ({
  open,
  handleClose,
  caseId: _caseId,
  payerFacilityId,
  isEdit,
  record,
  form = 'selectForm',
  refresh,
  closeAside,
  readmittable,
  residentId,
  onSuccess,
  params,
  payerId,
  meta = {},
}) => {
  const [eventForm, setEventForm] = useState(form);
  const [eventFormOptions, setEventFormOptions] = useState([
    { id: 'authorization', name: 'Authorization' },
    { id: 'payerChange', name: 'Payer change' },
    { id: 'denialAppeal', name: 'Denial and appeal' },
    { id: 'bedhold', name: 'Bedhold' },
    { id: 'discharge', name: 'Discharge' },
    { id: 'plannedDischarge', name: 'Planned discharge' },
  ]);
  const classes = useStyles();
  const location = useLocation();
  const adminRefresh = useRefresh();
  const { pathname } = location;
  const handleChange = event => {
    const {
      target: { value },
    } = event;
    const defaultProps = eventForms[value].props || {};
    setFormProps(defaultProps);
    setEventForm(value);
  };
  const changeForm = (newForm, newFormProps = {}) => {
    // NOTE any 'NewEvent' props that you don't override
    // will use the previous values. For example if you don't
    // want to use the original form's record then you must
    // pass another record to 'newFormProps'
    const defaultProps = eventForms[newForm].props || {};
    setFormProps({ ...defaultProps, ...newFormProps });
    setEventForm(newForm);
  };
  const ActiveForm = eventForms[eventForm].component;
  const [formProps, setFormProps] = useState(eventForms[eventForm].props || {});
  const routeArr = pathname.split('/');
  const routeId = routeArr[2];
  const caseId = _caseId
    ? _caseId
    : routeArr[1] === 'cases' && routeId && !isNaN(routeId)
    ? parseInt(routeId)
    : undefined;

  useEffect(() => {
    if (readmittable) {
      setEventFormOptions(options => [
        { id: 'admission', name: 'Admission' },
        { id: 'readmission', name: 'Readmission' },
        ...options,
      ]);
    } else {
      setEventFormOptions(options => [
        { id: 'admission', name: 'Admission/Readmission' },
        ...options,
      ]);
    }
  }, [caseId, residentId, readmittable]);
  return (
    <Dialog
      open={open}
      onClose={reason => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
    >
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id='form-dialog-title' align='left' disableTypography>
        <h2 className={classes.eventTitle}>{eventForms[eventForm].title}</h2>
      </DialogTitle>
      {eventForm === 'selectForm' && (
        <DialogContent style={{ overflowY: 'visible' }}>
          <TextField
            onChange={handleChange}
            className={classes.input}
            fullWidth
            select
            name='eventForm'
            value={eventForm}
            variant='outlined'
            style={{
              color: '#5C738E',
              marginBottom: eventForm === 'selectForm' ? 45 : 0,
            }}
          >
            <MenuItem value='selectForm' disabled>
              Select an event type
            </MenuItem>
            {eventFormOptions.map(option => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              );
            })}
          </TextField>
        </DialogContent>
      )}
      <ActiveForm
        handleClose={handleClose}
        caseId={caseId}
        payerFacilityId={payerFacilityId}
        payerId={payerId}
        isEdit={isEdit}
        record={record}
        residentId={residentId}
        formTitle={eventForms[eventForm] && eventForms[eventForm].title}
        refresh={refresh || adminRefresh}
        closeAside={closeAside}
        changeForm={changeForm}
        meta={meta}
        params={params}
        {...formProps}
        eventName={eventForm}
        onSuccess={onSuccess}
      />
    </Dialog>
  );
};

const eventForms = {
  selectForm: { component: () => null, title: 'New event' },
  admission: { component: AdmissionForm, title: 'Admission / New case' },
  importAdmission: { component: AdmissionForm, title: 'New admission' },
  readmission: { component: AdmissionForm, title: 'Readmission' },
  importReadmission: { component: AdmissionForm, title: 'Readmit' },
  authorization: { component: AuthorizationForm, title: 'Authorization' },
  denialAppeal: { component: DenialForm, title: 'Denial and appeal' },
  bedhold: { component: BedholdForm, title: 'Bedhold' },
  discharge: { component: DischargeForm, title: 'Discharge' },
  importDischarge: { component: DischargeForm, title: 'Discharge' },
  importDeleteDischarge: {
    component: DischargeForm,
    title: 'Delete discharge',
  },
  plannedDischarge: {
    component: DischargeForm,
    title: 'Planned discharge',
    props: { isPlannedDischarge: true },
  },
  importPlannedDischarge: {
    component: DischargeForm,
    title: 'Planned discharge',
    props: { isPlannedDischarge: true },
  },
  editResident: { component: EditResidentForm, title: 'Edit resident' },
  casePayer: { component: CasePayerForm, title: 'Case payer' },
  payerChange: {
    component: CasePayerForm,
    title: 'Payer change',
  },
  facilityPayer: { component: FacilityPayerForm, title: 'Payer contract' },
  globalPayer: { component: GlobalPayerForm, title: 'Payer' },
  connectionForm: { component: ConnectionForm, title: 'Add a connection' },
  serviceForm: { component: ServiceForm, title: 'Add a service' },
  qualifyingCriteria: {
    component: QualifyingCriteriaForm,
    title: 'Add qualifying criteria',
  },
  rates: {
    component: RatesForm,
    title: 'Add a rate',
  },
  outliers: { component: OutliersForm, title: 'Add an outlier' },
  payerIntegrationDetails: {
    component: PayerIntegrationDetailsForm,
    title: 'Payer integration detail',
  },
  facility: { component: FacilityForm, title: 'Facility main information' },
  communicationGroup: {
    component: CommunicationGroup,
    title: 'Communication group',
  },
  facilityContacts: {
    component: FacilityContacts,
    title: 'New facility contact',
  },
  facilityGroups: {
    component: FacilityGroups,
    title: 'Facility groups',
  },
  users: { component: UsersForm, title: 'Users' },
  pharmLog: { component: PharmLogForm, title: 'Pharmacy log' },
  appealEditForm: { component: AppealEditForm, title: 'Edit appeal' },
  payerRepForm: { component: PayerRepForm, title: 'Insurance case manager' },
  editUpdateDate: { component: EditUpdateDate, title: 'Edit update date' },
  censusForm: { component: CensusForm, title: 'Census' },
  editPayerChange: { component: EditPayerChange, title: 'Payer change' },
  bulkReassignUsers: {
    component: BulkReassignUsers,
    title: 'Reassign case managers',
  },
  editCase: {
    component: EditCase,
    title: 'Edit case',
  },

  bulkReportsForm: {
    component: BulkReportsForm,
    title: 'Reports - multiple facilities',
  },
  reportPermissionsForm: {
    component: ReportPermissionsForm,
    title: 'Report permissions',
  },
  facilityTasksExportForm: {
    component: FacilityTasksExportForm,
    title: 'Export facility tasks',
  },
  deactivateFacilityForm: {
    component: DeactivateFacilityForm,
    title: 'Deactivate facility',
  },

  reactivateFacilityForm: {
    component: DeactivateFacilityForm,
    title: 'Reactivate facility',
  },
  therapyForm: { component: TherapyForm, title: 'Therapy' },
  assignFaxForm: { component: AssignFaxForm, title: 'Assign Fax' },
  autoTaskSettingsForm: {
    component: AutoTaskSettingsForm,
    title: 'Auto task settings',
  },
  preset: {
    component: PresetSettingsForm,
    title: 'Save report',
  },
  editMltcPayer: { component: EditMltcPayer, title: 'MLTC payer change' },
  cloneContract: {
    component: CloneContractForm,
    title: 'Copy contract details',
  },
};
