import { Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { differenceInCalendarDays, isBefore } from 'date-fns';
import React, { Fragment, useState } from 'react';
import {
  BooleanField,
  FunctionField,
  Labeled,
  ReferenceField,
  Show,
  ShowView,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { DateField, TrackUser } from '../../components/common';
import { NotesModal } from '../../components/common/modals';
import { useShowController } from '../../hooks';
import { ResidentShowActions, ResidentShowFields } from '../../resources';
import {
  constants,
  currencyFormat,
  limitStringSize,
  weekDaysObj,
} from '../../utils';
import { TimelineShowActions } from './TimelineShowActions';

const { eventTypes } = constants;
const DENIAL_RECEIVED = 3;
const DENIED = 4;

export const CaseAuthShow = ({
  closeAside,
  classes,
  residentId,
  caseId,
  setRefreshTimeline,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const maxNotesLength = 160;
  const showProps = useShowController({ ...props });
  const { record = {} } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <TimelineShowActions
            closeAside={closeAside}
            title='Authorizations'
            resourceRecord={record}
            eventForm='authorization'
            eventId={record.id}
            eventType='Auth'
            eventName={eventTypes.AUTHORIZATION_NAME}
            caseId={caseId}
            related_to_data={{
              related_to_type: 'Auth',
              related_to_id: record.id,
              related_to_date: record.start_date,
              insurance_auth_number: record.insurance_auth_number,
            }}
            setRefreshTimeline={setRefreshTimeline}
            data={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <TextField
            source='payer_name'
            label='Payer'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='insurance_auth_number'
            label='Auth number'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            label='Case manager'
            source='user.name'
            className={classes.showItem}
            textAlign='right'
          />
          <FunctionField
            source='is_mltc'
            label='Case type'
            className={classes.showItem}
            render={record => (record.is_mltc ? 'MLTC' : 'Subacute')}
          />
          <TextField
            source='authorization_type.type'
            label='Auth type'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='rev_code'
            label='Rev code'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='start_date'
            label='Start date'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='is_mltc'
            label='Days approved'
            className={classes.showItem}
            render={record => {
              const { start_date, end_date, authorization_status_id } = record;
              return authorization_status_id !== DENIED &&
                authorization_status_id !== DENIAL_RECEIVED
                ? start_date &&
                    end_date &&
                    differenceInCalendarDays(
                      new Date(end_date),
                      new Date(start_date),
                    ) + 1
                : '0';
            }}
          />
          <DateField
            label='Initial end date'
            source='end_date'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            label='Actual end date'
            source='actual_end_date'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='is_mltc'
            label='Days available'
            className={classes.showItem}
            render={record => {
              const { end_date } = record;
              const now = new Date();
              return end_date && isBefore(now, new Date(end_date))
                ? differenceInCalendarDays(new Date(end_date), now)
                : 0;
            }}
          />
          <FunctionField
            source='rate'
            className={classes.showItem}
            label='Rate'
            textAlign='right'
            render={record => currencyFormat(record.rate)}
          />
          <DateField
            source='letter_received'
            className={classes.showItem}
            label='Received date'
            textAlign='right'
          />
          <ReferenceField
            label='Letter status'
            source='auth_letter_status_id'
            reference='auth-letter-status/list'
            textAlign='right'
            className={classes.showItem}
            link={false}
          >
            <TextField
              source='name'
              textAlign='right'
              className={classes.showItem}
            />
          </ReferenceField>
          {record?.approval_chance !== null && (
            <FunctionField
              source='approval_chance'
              className={classes.showItem}
              label='Approval likelihood'
              textAlign='right'
              render={record => `${record.approval_chance}%`}
            />
          )}
          {record?.pending_reason !== null && (
            <TextField
              source='pending_reason'
              label='Pending reason'
              textAlign='right'
              className={classes.showItem}
            />
          )}
          {/* needed for spacing */}
          <div className={classes.showItem} />
          <FunctionField
            source='auth_notes'
            label='Notes'
            textAlign='right'
            className={clsx(classes.showItem, classes.showFullWidth)}
            render={record => {
              const { auth_notes } = record;
              const truncated = limitStringSize(
                auth_notes,
                maxNotesLength,
                true,
              );
              return auth_notes && auth_notes.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setShowNotesModal(true)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          {Array.isArray(record?.outpatient_services) &&
            !!record.outpatient_services.length && (
              <Fragment>
                <div className={classes.showSectionTitle}>
                  Outpatient services
                </div>
                {record.outpatient_services.map(os => {
                  const { id, service, minutes, frequency } = os;
                  return (
                    <div style={{ display: 'flex' }} key={id}>
                      <Labeled label='Service' className={classes.showItem}>
                        <TextField
                          record={{ ...record, service }}
                          source='service'
                          className={classes.showItem}
                          label='Service'
                          textAlign='right'
                        />
                      </Labeled>
                      <Labeled label='Frequency' className={classes.showItem}>
                        <TextField
                          record={{ ...record, frequency }}
                          source='frequency'
                          className={classes.showItem}
                          label='Frequency'
                          textAlign='right'
                        />
                      </Labeled>
                      <Labeled
                        label='Minutes/Units'
                        className={classes.showItem}
                      >
                        <FunctionField
                          record={record}
                          source='minutes'
                          className={classes.showItem}
                          label='Minutes/Units'
                          textAlign='right'
                          render={() => (
                            <span>{`${minutes}/${minutes / 15}`}</span>
                          )}
                        />
                      </Labeled>
                    </div>
                  );
                })}
              </Fragment>
            )}
          <div className={classes.showSectionTitle}>Updates</div>
          <FunctionField
            source='initial_update_date'
            className={classes.showItem}
            label='Schedule type'
            render={record => getScheduleType(record)}
          />
          <DateField
            source='initial_update_date'
            className={classes.showItem}
            label='Initial update'
            textAlign='right'
          />
          <FunctionField
            source='update_weekday'
            label='Days of week'
            className={classes.showItem}
            render={record => {
              const { update_weekday } = record;
              return (
                Array.isArray(update_weekday) &&
                update_weekday.map(u => weekDaysObj[u + '']).join(', ')
              );
            }}
          />
          <TextField
            source='update_frequency'
            className={classes.showItem}
            label='Frequency'
            textAlign='right'
          />
          <FunctionField
            source='inactive_schedule'
            label='Disable sched'
            textAlign='right'
            className={classes.showItem}
            render={record => {
              const updatedRecord = {
                ...record,
                inactive_schedule: !!record.inactive_schedule,
              };
              return (
                <BooleanField
                  record={updatedRecord}
                  source='inactive_schedule'
                  className={classes.showItem}
                  style={{
                    color: updatedRecord.inactive_schedule
                      ? '#44C87A'
                      : '#5C738E',
                  }}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {/* <div className={classes.showItem} /> */}
          <FunctionField
            source='update_type'
            label='Update type'
            className={clsx(classes.showItem, classes.showFullWidth)}
            render={record => {
              const { update_type } = record;
              return Array.isArray(update_type) && update_type.join(', ');
            }}
          />
          <TextField
            source='update_notes'
            className={classes.showItem}
            label='Update notes'
            textAlign='right'
          />
          <FunctionField
            source='authorizationCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { authorizationCreatedBy, created_at } = record;
              return (
                <TrackUser
                  userField={authorizationCreatedBy}
                  timestamp={created_at}
                />
              );
            }}
          />
          <FunctionField
            source='authorizationUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { authorizationUpdatedBy, updated_at } = record;
              return (
                <TrackUser
                  userField={authorizationUpdatedBy}
                  timestamp={updated_at}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {showNotesModal && (
            <NotesModal
              open={showNotesModal}
              handleClose={() => setShowNotesModal(false)}
              note={record.auth_notes}
            />
          )}
        </SimpleShowLayout>
      </ShowView>
      <Divider className={classes.residentDivider} />
      <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
        Resident info
      </p>
      <Show
        actions={
          <ResidentShowActions
            closeAside={closeAside}
            isResidentPage={false}
            setRefreshTimeline={setRefreshTimeline}
            isViewAdmin={isViewAdmin}
          />
        }
        {...rest}
        resource='residents'
        id={residentId}
      >
        <ResidentShowFields classes={classes} includeCases />
      </Show>
    </div>
  );
};

function getScheduleType({
  initial_update_date,
  update_frequency_days,
  update_frequency,
  update_weekday,
}) {
  if (Array.isArray(update_weekday) && update_weekday.length) {
    return 'Recurring day';
  } else if (update_frequency_days || update_frequency) {
    return 'Recurring date';
  } else if (initial_update_date) {
    return 'One time';
  } else {
    return '';
  }
}
