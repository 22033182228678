import React, { useEffect, useState } from 'react';
import { usePermissions, useRedirect } from 'react-admin';
import { useSelector } from 'react-redux';
import { Dashboard } from '../../pages';
import { authGet } from '../../server';

export const DashboardHome = () => {
  const [facilityIds, setFacilityIds] = useState([]);
  const userId = useSelector(state => state.user.profile.id);
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const redirect = useRedirect();
  useEffect(() => {
    const fetchData = async () => {
      const facilities = await authGet([
        `/facilities/list`,
        {
          caseManagerId: userId,
        },
      ]);
      if (!facilities.data) return;
      const { data } = facilities;
      const ids = data.map(row => row.id);
      setFacilityIds(ids);
    };
    if (!facilityIds.length) {
      fetchData();
    }
    if (userPermissions.indexOf('contract_user') > -1)
      redirect('/payers-facility');
  }, [userId, facilityIds, userPermissions, redirect]);
  // TODO: determine if we can implement route based permissions (only white listed routes)
  if (userPermissions.indexOf('contract_user') > -1) return null;
  return userId && facilityIds.length ? (
    <Dashboard
      title='My Dashboard'
      viewMultiple={true}
      facility_ids={facilityIds}
      user_id={userId}
    />
  ) : null;
};
