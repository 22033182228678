import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import set from 'lodash/set';
import { useSelector } from 'react-redux';
import { isValid, isBefore, isFuture } from 'date-fns';
import NumberFormat from 'react-number-format';
import {
  FileInput as AdminFileInput,
  FileField,
  useTranslate,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { Field } from 'react-final-form';
import {
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Chip,
  Typography,
  Switch,
} from '@material-ui/core';
import { Autocomplete } from '../../../wrappers';
import { removeDupesObjArray } from '../../../../utils';
import { useStyles } from '../modal.styles';
import { UploadIcon } from '../../../../design';
import { useOnClickOutside } from '../../../../utils/helpers';

export const AutocompleteInput = ({
  reference,
  options: _options = {},
  trimLength,
  parentOnClick,
  viewAll,
  ...rest
}) => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState([]);
  const [searchFilter, setFilter] = useState('');
  // need this because of https://stackoverflow.com/a/54096391
  const [options] = useState(_options);

  const ref = useRef();
  useOnClickOutside(ref, () => setFilter(''));
  const debounceFilter = debounce(value => {
    setFilter(value);
  }, 300);
  useEffect(() => {
    if (searchFilter.trim().length > trimLength || 2) {
      const { filter = {}, sort = {}, ...otherOptions } = options;
      dataProvider
        .getList(reference, {
          pagination: { page: 1, perPage: viewAll ? 10000 : 50 },
          filter: {
            [options.query_param || 'q']: searchFilter,
            ...filter,
          },
          sort,
          ...otherOptions,
        })
        .then(({ data }) => {
          setData(data);
        });
    }
  }, [dataProvider, searchFilter, reference, options, trimLength, viewAll]);

  const onChange = e => {
    debounceFilter(e.target.value);
  };

  return (
    <SelectComponent
      modalRef={ref}
      choices={data}
      onFilterChange={onChange}
      parentOnClick={parentOnClick}
      {...rest}
      autocomplete
      style={{ marginBottom: 0 }}
    />
  );
};

export const ReferenceInput = ({
  reference,
  options: _options,
  perPage = 30,
  resetOption,
  addlOptions,
  ...rest
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const facilityId = useSelector(state => state.facility.id);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  // need this because of https://stackoverflow.com/a/54096391
  const [options] = useState(_options || {});
  const [hasMore, setHasMore] = useState(false);
  const lists = useSelector(state => state.lists.lists);

  useEffect(
    () => {
      const { filter, sort = {}, ...otherOptions } = options;
      if (!facilityId) return;
      if (lists[reference] !== undefined) {
        const data = lists[reference];
        setData(d => removeDupesObjArray(d, [...(addlOptions || []), ...data]));
        setHasMore(data.length >= perPage);
      } else {
        dataProvider
          .getList(reference, {
            pagination: { page, perPage },
            sort,
            filter,
            ...otherOptions,
          })
          .then(({ data }) => {
            setData(d =>
              removeDupesObjArray(d, [...(addlOptions || []), ...data]),
            );
            setHasMore(data.length >= perPage);
          })
          .catch(error =>
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning',
            ),
          );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dataProvider,
      options,
      reference,
      page,
      perPage,
      facilityId,
      notify,
      lists,
    ],
  );
  return Array.isArray(data) ? (
    <SelectComponent
      choices={data}
      hasMore={hasMore}
      setPage={setPage}
      resetOption={resetOption}
      {...rest}
    />
  ) : null;
};

export const SelectComponent = ({
  modalRef,
  name,
  label,
  autocomplete,
  customOnChange,
  choices: _choices,
  fullWidth,
  validate,
  disabled,
  resetOption,
  // initialInputText just sets the autocomplete entry but does not choose a selected value
  initialInputText,
  required,
  multiple,
  formField = [],
  setFormData,
  style,
  className,
  renderWith,
  onFilterChange,
  hasMore,
  setPage,
  shouldFetchMore,
  autocompleteProps = {},
  classes,
  selectMltc,
  parentOnClick,
  clearInputOnClick,
  getName,
  multiSelect,
  highlightItems,
}) => {
  const styleClasses = useStyles();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const choices = useMemo(
    () => (resetOption ? [resetOption, ..._choices] : _choices),
    [_choices, resetOption],
  );
  const [inputObj, setInputObj] = useState({});
  const [allValues, setAllValues] = useState([]);
  const [subacuteValues, setSubacuteValues] = useState([]);
  const [mltcValues, setMltcValues] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [subacuteSelected, setSubacuteSelected] = useState(false);
  const [mltcSelected, setMltcSelected] = useState(false);
  useEffect(() => {
    if (!autocomplete && multiple && Array.isArray(choices)) {
      const values = [];
      const obj = choices.reduce((acc, cur) => {
        acc[cur.id] = cur.name;
        values.push(cur.id);
        return acc;
      }, {});
      setInputObj(obj);
      setAllValues(values);
      if (selectMltc) {
        const subacuteValues = choices
          ?.filter(row => row.active_case_types !== 'mltc')
          ?.map(row => row.id);
        setSubacuteValues(subacuteValues);

        const mltcValues = choices
          ?.filter(row => row.active_case_types !== 'subacute')
          ?.map(row => row.id);
        setMltcValues(mltcValues);
      }
    }
  }, [autocomplete, multiple, choices, selectMltc]);

  const selectAll = useCallback(
    selectall => {
      setFormData(f => {
        set(f, name, selectall ? allValues : []);
        return { ...f };
      });
      setAllSelected(selectall);
    },
    [allValues, setFormData, name],
  );
  const selectAllSubacute = useCallback(
    selected => {
      setFormData(f => {
        set(
          f,
          name,
          selected && mltcSelected
            ? allValues
            : selected
            ? subacuteValues
            : mltcSelected
            ? mltcValues
            : [],
        );
        return { ...f };
      });
      setSubacuteSelected(selected);
    },
    [subacuteValues, setFormData, name, allValues, mltcValues, mltcSelected],
  );
  const selectAllMltc = useCallback(
    selected => {
      setFormData(f => {
        set(
          f,
          name,
          selected && subacuteSelected
            ? allValues
            : subacuteSelected
            ? subacuteValues
            : selected
            ? mltcValues
            : [],
        );
        return { ...f };
      });
      setMltcSelected(selected);
    },
    [
      mltcValues,
      setFormData,
      name,
      subacuteValues,
      subacuteSelected,
      allValues,
    ],
  );
  return (
    <Field name={name} validate={validate} options={choices}>
      {({ input, meta }) => {
        const { onChange, ...rest } = input;
        const errorMsg =
          meta.error && meta.error.startsWith('ra.validation')
            ? translate(meta.error)
            : meta.error;
        const submitErrorMsg =
          meta.submitError && meta.submitError.startsWith('ra.validation')
            ? translate(meta.submitError)
            : meta.submitError;
        const showError =
          !!(
            (!!meta.submitError && !!!meta.dirtySinceLastSubmit) ||
            !!meta.error
          ) && meta.touched;

        const handleChange = e => {
          const { target: { value } = {} } = e;
          // remove null value if 'view more' is clicked
          if (Array.isArray(value) && value.indexOf(null) > -1) return;
          allSelected && setAllSelected(false);
          customOnChange && customOnChange(e, name);
          onChange && onChange(e);
        };

        const onClose = e => {
          if (e.target.textContent !== 'View more') {
            setOpen(false);
          }
        };

        const onOpen = () => {
          setOpen(true);
        };

        return autocomplete ? (
          <Autocomplete
            modalRef={modalRef}
            onChange={onFilterChange}
            suggestions={choices}
            initialInputValue={initialInputText}
            parentOnClick={parentOnClick || handleChange}
            getName={getName}
            multiSelect={multiSelect}
            shouldFetchMore={shouldFetchMore}
            hasMore={hasMore}
            clearInputOnClick={clearInputOnClick}
            setPage={setPage}
            highlightItems={highlightItems}
            {...autocompleteProps}
            otherInputProps={{
              error: showError,
              label,
              helperText: showError
                ? meta.error
                  ? errorMsg
                  : submitErrorMsg
                : undefined,
              FormHelperTextProps: { error: true },
              disabled,
              required,
              style,
              fullWidth,
              margin: 'dense',
              className: clsx(
                styleClasses.input,
                styleClasses.textField,
                {
                  [styleClasses.smallInput]: !!!fullWidth,
                },
                className,
              ),
            }}
          />
        ) : (
          <TextField
            variant='outlined'
            select
            {...rest}
            margin='dense'
            className={clsx(
              styleClasses.input,
              styleClasses.textField,
              {
                [styleClasses.smallInput]: !!!fullWidth,
              },

              className,
            )}
            fullWidth={fullWidth}
            onChange={handleChange}
            error={showError}
            label={label}
            helperText={
              showError ? (meta.error ? errorMsg : submitErrorMsg) : undefined
            }
            FormHelperTextProps={{ error: true }}
            disabled={disabled}
            required={required}
            style={style}
            SelectProps={{
              MenuProps: { disableScrollLock: true },
              open,
              onOpen,
              onClose,
              multiple: !!multiple,
              renderValue: multiple
                ? renderWith === 'chip'
                  ? selected => (
                      <div className={styleClasses.chips}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={inputObj[value]}
                            className={styleClasses.chip}
                          />
                        ))}
                      </div>
                    )
                  : selected => selected.map(s => inputObj[s]).join(', ')
                : undefined,
            }}
            InputProps={{
              classes: {
                notchedOutline: classes?.notchedOutline || undefined,
              },
            }}
          >
            {multiple && setFormData && !selectMltc && (
              <MenuItem value={null}>
                <Typography
                  onClick={() => {
                    selectAll(!!!allSelected);
                  }}
                >
                  <Checkbox checked={allSelected} />
                  Select all
                </Typography>
              </MenuItem>
            )}
            {selectMltc && setFormData && (
              <MenuItem value={null}>
                <Typography
                  onClick={() => {
                    selectAllSubacute(!!!subacuteSelected);
                  }}
                >
                  <Checkbox checked={subacuteSelected} />
                  Select all subacute
                </Typography>
                <Typography
                  onClick={() => {
                    selectAllMltc(!!!mltcSelected);
                  }}
                >
                  <Checkbox checked={mltcSelected} />
                  Select all MLTC
                </Typography>
              </MenuItem>
            )}
            {Array.isArray(choices) &&
              choices.map(option => {
                return (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    disabled={option.inactive}
                  >
                    {renderWith === 'checkbox' && (
                      <Checkbox checked={formField.indexOf(option.id) > -1} />
                    )}
                    {option.name}
                  </MenuItem>
                );
              })}
            {shouldFetchMore && (
              <MenuItem value={null} disabled={!hasMore}>
                <Typography
                  color='primary'
                  onClick={() => {
                    setPage(p => p + 1);
                  }}
                >
                  View more
                </Typography>
              </MenuItem>
            )}
          </TextField>
        );
      }}
    </Field>
  );
};

export const FormTextField = ({
  name,
  label,
  format,
  customOnChange,
  valueModifiedOnChange,
  customOnBlur,
  fullWidth,
  validate,
  disabled,
  type,
  step = 1,
  min,
  max,
  multiline,
  required,
  style,
  labelStyle,
  className,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const inputEl = useRef(null);

  useEffect(() => {
    // Need to disable wheel event to prevent user from
    // inadvertently changing the value they just have set.
    // https://github.com/mui-org/material-ui/issues/7960
    const handleWheel = e => e.preventDefault();
    type === 'number' && inputEl.current.addEventListener('wheel', handleWheel);
  }, [type]);

  return (
    <Field
      name={name}
      validate={validate}
      type={type}
      parse={
        type === 'number'
          ? handleNumbers
          : type === 'date'
          ? getDateInputValue
          : v => v
      }
    >
      {({ input, meta }) => {
        const { onChange, onBlur, value: defaultValue, ...rest } = input;
        const errorMsg =
          meta.error && meta.error.startsWith('ra.validation')
            ? translate(meta.error)
            : meta.error;
        const submitErrorMsg =
          meta.submitError && meta.submitError.startsWith('ra.validation')
            ? translate(meta.submitError)
            : meta.submitError;
        const value =
          format === 'phone'
            ? normalizePhone(defaultValue)
            : format === 'ss'
            ? normalizeSSNumber(defaultValue)
            : defaultValue;
        const showError =
          ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
          meta.touched;
        const handleChange = (e, name) => {
          customOnChange && customOnChange(e, name, type);
          if (!valueModifiedOnChange) {
            onChange && onChange(e);
          }
        };
        const handleBlur = (e, name) => {
          customOnBlur && customOnBlur(e, name, type);
          onBlur && onBlur(e);
        };
        return (
          <TextField
            {...rest}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
              min: min ? min : type === 'number' ? 0 : undefined,
              max,
              step: type === 'number' ? step : undefined,
            }}
            className={clsx(
              classes.input,
              classes.textField,
              {
                [classes.smallInput]: !!!fullWidth,
              },
              className,
            )}
            label={label}
            error={showError}
            helperText={
              showError ? (meta.error ? errorMsg : submitErrorMsg) : undefined
            }
            FormHelperTextProps={{ error: true }}
            InputLabelProps={{
              shrink: input.type === 'date' ? true : undefined,
              style: labelStyle,
            }}
            disabled={disabled}
            fullWidth={fullWidth}
            multiline={multiline}
            rows='3'
            margin='dense'
            required={required}
            style={style}
            ref={inputEl}
            variant='outlined'
          />
        );
      }}
    </Field>
  );
};

export const CheckboxInput = ({
  name,
  label,
  customOnChange,
  fullWidth,
  validate,
  disabled,
  required,
  style,
  className,
  labelClasses,
  checked,
  size,
}) => {
  return (
    <Field name={name} validate={validate} type='checkbox'>
      {({ input, meta }) => {
        const { onChange, ...rest } = input;
        const handleChange = (e, name) => {
          customOnChange && customOnChange(e, name);
          onChange && onChange(e);
        };
        return (
          <FormControlLabel
            style={style}
            classes={labelClasses}
            control={
              <Checkbox
                disabled={disabled}
                checked={checked}
                {...rest}
                onChange={handleChange}
                required={required}
                className={clsx(className)}
                size={size}
              />
            }
            label={label}
          />
        );
      }}
    </Field>
  );
};

export const RadioInput = ({
  name,
  label,
  customOnChange,
  fullWidth,
  validate,
  disabled,
  required,
  style,
  row,
  className,
  value,
  radios = [],
}) => {
  return (
    <Field name={name} validate={validate} type='radio'>
      {({ input, meta }) => {
        const { onChange, value: _value, ...rest } = input;
        const handleChange = (e, name) => {
          customOnChange && customOnChange(e, name);
          onChange && onChange(e);
        };
        const showError =
          ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
          meta.touched;
        return (
          <FormControl
            component='fieldset'
            required={required}
            className={className}
          >
            <FormLabel component='legend' error={showError}>
              {label}
            </FormLabel>
            <RadioGroup
              aria-label={name}
              name={name}
              value={value}
              onChange={handleChange}
              row={row}
              {...rest}
            >
              {radios.map(r => {
                return (
                  <FormControlLabel
                    key={r.value}
                    value={r.value}
                    control={<Radio />}
                    label={r.label}
                    disabled={disabled}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
      }}
    </Field>
  );
};

export const SwitchInput = ({
  name,
  label,
  customOnChange,
  fullWidth,
  validate,
  disabled,
  required,
  style,
  className,
  checked,
  color = 'primary',
  size,
}) => {
  return (
    <Field name={name} validate={validate} type='checkbox'>
      {({ input, meta }) => {
        const { onChange, ...rest } = input;
        const handleChange = (e, name) => {
          customOnChange && customOnChange(e, name);
          onChange && onChange(e);
        };
        return (
          <FormControlLabel
            control={
              <Switch
                disabled={disabled}
                checked={checked}
                {...rest}
                onChange={handleChange}
                required={required}
                style={style}
                className={clsx(className)}
                color={color}
                size={size}
              />
            }
            label={label}
          />
        );
      }}
    </Field>
  );
};

export const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export const minLength = (min, msg) => value => {
  if (!value || (typeof value !== 'number' && typeof value !== 'string'))
    return undefined;
  const onlyNums =
    typeof value === 'number' ? value + '' : value.replace(/[^\d]/g, '');
  return onlyNums.length >= min
    ? undefined
    : msg
    ? msg
    : `Should be greater than ${min}`;
};

export const maxValue = min => value => {
  if (!value || value === null) return undefined;
  return value > min ? undefined : 'Max must be greater than min';
};

export const maxTextLength = (max, msg) => value => {
  if (!value || (typeof value !== 'number' && typeof value !== 'string'))
    return undefined;
  return value.length <= max
    ? undefined
    : msg
    ? msg
    : `Should be less than ${max}`;
};

export const validateDate = msg => value => {
  if (!value) return undefined;
  const valueDate = new Date(`${value} 00:00`);
  return !isValid(valueDate) ? (msg ? msg : `Invalid Date`) : undefined;
};

export const minDate = (min, msg) => value => {
  const minDate = new Date(`${min} 00:00`);
  const valueDate = new Date(`${value} 00:00`);
  if (!isValid(minDate) || !isValid(valueDate)) return undefined;
  return isBefore(valueDate, minDate)
    ? msg
      ? msg
      : `Date can't be before ${min}`
    : undefined;
};

export const maxDate = (max, msg) => value => {
  const maxDate = new Date(`${max} 00:00`);
  const valueDate = new Date(`${value} 00:00`);
  if (!isValid(maxDate) || !isValid(valueDate)) return undefined;
  return isFuture(valueDate, maxDate)
    ? msg
      ? msg
      : `Date can't be after ${max}`
    : undefined;
};

export const composeValidators = validators => (value, values) => {
  return validators
    .map(validator => {
      const errorMsg = validator(value, values);
      return typeof errorMsg === 'object' ? errorMsg.message : errorMsg;
    })
    .filter(err => err)[0];
};

export const normalizePhone = value => {
  if (!value) return value;
  const onlyNums =
    typeof value === 'number' ? value + '' : value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
  }
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10,
  )}`;
};

export const validateEmailOnSubmit = value => {
  if (value === '') return null;
  else return value;
};

export const validatePhoneOnSubmit = phone => {
  if (!phone || typeof phone !== 'string') return phone;
  return phone.replace(/[^\d]/g, '').slice(0, 10);
};

export const validateSSNOnSubmit = ssn => {
  if (!ssn || typeof ssn !== 'string') return ssn;
  return ssn.replace(/[^\d]/g, '').slice(0, 9);
};

const normalizeSSNumber = value => {
  if (!value) return value;
  const onlyNums =
    typeof value === 'number' ? value + '' : value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
    5,
    9,
  )}`;
};

export const validatePresetName = () => value => {
  if (!value) return undefined;
  const regexChars = /[^a-zA-Z0-9 ]/;
  return regexChars.test(value)
    ? 'No special characters allowed in preset name'
    : undefined;
};

export const handleNumbers = value => {
  if (!value || isNaN(value)) {
    return null;
  }
  return parseFloat(value);
};

export const getDateInputValue = value => {
  if (!value || typeof value !== 'string') {
    return null;
  }
  return value;
};

export const FileInput = ({
  label,
  accept,
  title = 'title',
  maxSize,
  minSize,
  placeholder,
  name,
  multiple = true,
  setFormData,
  formData,
  classes,
  className,
  isViewAdmin,
}) => {
  return (
    <Field name={name} type='file'>
      {({ input, meta }) => {
        const { onChange, ...rest } = input;
        // Need this because the form is not updating correctly
        // and react-admin is not passing the correct data to customOnChange
        if (input.value && formData[name] !== input.value) {
          setFormData({ ...formData, [name]: input.value });
        }
        return (
          <AdminFileInput
            source='files'
            label=''
            accept={accept}
            maxSize={maxSize}
            minSize={minSize}
            multiple={multiple}
            placeholder={
              <div>
                <div style={{ alignItems: 'center', color: '#C5DAF1' }}>
                  {' '}
                  <UploadIcon />
                </div>
                <span style={{ color: '#9FB4CB', fontWeight: 300 }}>
                  Drag & drop to upload{' '}
                  <span
                    style={{
                      fontWeight: 300,
                      color: '#1061A0',
                      textDecoration: 'underline',
                      textUnderlinePosition: 'under',
                    }}
                  >
                    or browse
                  </span>
                </span>
              </div>
            }
            className={className}
            classes={classes}
            {...rest}
            {...meta}
            disabled={isViewAdmin}
          >
            <FileField source='src' title='title' target='_blank' />
          </AdminFileInput>
        );
      }}
    </Field>
  );
};

const formatTypes = {
  phone: {
    format: '(###) ###-####',
    // mask: '_',
    // allowEmptyFormatting: true,
  },
  ssn: {
    format: '###-##-####',
    // mask: '_',
    // allowEmptyFormatting: true,
  },
  currency: {
    prefix: '$',
    thousandSeparator: true,
  },
};

//TODO use this for all inputs that need formatting!!!!
export function NumberFormatter(props) {
  const { inputRef, onChange, format, ...other } = props;
  const [formatInput, setFormatInput] = useState({});

  useEffect(() => {
    const formatType = formatTypes[format];
    if (formatType) {
      setFormatInput(formatType);
    }
  }, [format]);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      {...formatInput}
    />
  );
}
