import React, { useState, useEffect, useCallback, Fragment } from 'react';
import qs from 'query-string';
import {
  addDays,
  isBefore,
  differenceInCalendarDays,
  format,
  isValid,
  isFuture,
} from 'date-fns';
import clsx from 'clsx';
import { authGet } from '../../../../server';
import { Form } from 'react-final-form';
import {
  required,
  useDataProvider,
  useNotify,
  cacheDataProviderProxy,
  useRedirect,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormGroup,
  Typography,
} from '@material-ui/core';
import {
  ReferenceInput,
  AutocompleteInput,
  FormTextField,
  SelectComponent,
  composeValidators,
  FileInput,
  handleNumbers,
  getDateInputValue,
  minLength,
  validateEmailOnSubmit,
  validatePhoneOnSubmit,
  validateSSNOnSubmit,
  CheckboxInput,
} from '../modalForms';

import { AdmissionEditForm } from './admissionEdit';
import { AddIcon, RemoveIcon, SaveButton } from '../../../../design';
import { useGlobalStyles } from '../../../../design/globalStyles';
import { validateDate } from './inputs';
import { useStyles } from '../modal.styles';
import { ConfirmedActionModal } from '../../../common/ConfirmedActionModal';
import { constants } from '../../../../utils/constants';
import {
  getFieldName,
  PART_B_AUTH_ID,
  DENIAL_RECEIVED,
  DENIED,
  fetchResource,
  fetchRateRevCode,
  setScheduleType,
} from './admission/helpers';
import { PrimaryInsuranceInputs } from './admission/primaryInsuranceInputs';
import { SecondaryInsuranceInputs } from './admission/secondaryInsuranceInputs';
import { ExternalInsuranceInputs } from './admission/InsuranceInputs';
import { Loading } from '../../Loading';

const { resident_status } = constants;
const MISSING_OUTPATIENT_SERVICES = 'Missing: outpatient services';

export const AdmissionForm = ({
  handleClose,
  isEdit,
  record,
  refresh,
  onSuccess,
  closeAside,
  residentId,
  eventName,
  meta,
}) => {
  const importsExternalData = ['importAdmission', 'importReadmission'].includes(
    eventName,
  );
  const classes = { ...useStyles(), ...useGlobalStyles() };
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [secondaryInsurance, setSecondaryInsurance] = useState(false);
  const [residentFields, setResidentFields] = useState({});
  const [importedFields, setImportedFields] = useState({
    chosen_resident_full_name: '', // represents name of synced or selected resident
    synced_resident_id: 0,
    integration_type: 0,
    integration_id: 0,
    integration_isactive: false,
  });
  const [loading, setLoading] = useState(importsExternalData);
  const [payerRepFields, setpayerRepFields] = useState({
    primary: {},
    secondary: {},
  });
  const [formData, setFormData] = useState({
    resident_status_id: 1,
  });
  const [insurances, setInsurances] = useState([]);
  const [originalResidentRecord, setOriginalResidentRecord] = useState({});
  const [lastCase, setLastCase] = useState({ id: '', name: '' });
  const [duplicateResidentId, setDuplicateResidentId] = useState();
  const [isInNetworkPrimary, setIsInNetworkPrimary] = useState(true);
  const [isInNetworkSecondary, setIsInNetworkSecondary] = useState(true);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [checkPrimaryAuth, setCheckPrimaryAuth] = useState(false);
  const [checkSecondaryAuth, setCheckSecondaryAuth] = useState(false);
  const [validatePrimaryAuth, setValidatePrimaryAuth] = useState(false);
  const [validateSecondaryAuth, setValidateSecondaryAuth] = useState(false);
  const [checkAdmit, setCheckAdmit] = useState(false);

  const redirect = useRedirect();
  const openSecondary = useCallback(() => {
    const admitDate = formData.admit_date;
    const days_remaining = formData.days_remaining;
    const admit_date = new Date(`${admitDate} 00:00`);
    if (isValid(admit_date) && days_remaining) {
      setFormData(f => ({
        ...f,
        s_copay_end_date: format(
          addDays(admit_date, +days_remaining - 1),
          'yyyy-MM-dd',
        ),
      }));
    }
    setSecondaryInsurance(true);
  }, [formData.admit_date, formData.days_remaining]);

  const fetchResident = useCallback(
    async value => {
      const response = await fetchAndValidateResource(
        'admission/residents',
        value,
      );
      if (response.error) {
        setOriginalResidentRecord({});
        notify(
          typeof response.error === 'string'
            ? response.error
            : response.error.message || 'ra.notification.http_error',
          'warning',
        );
        if (importsExternalData) {
          setResidentFields(f => ({ ...f, select_resident: 0 }));
        } else {
          setResidentFields({ select_resident: 0 });
        }
      } else if (importsExternalData) {
        const data = response.data;
        setOriginalResidentRecord(data);
        const chosen_resident_full_name = [
          data.first_name,
          data.middle_name,
          data.last_name,
        ]
          .filter(x => !!x)
          .join(' ');

        if (
          data.resident_integration_isactive &&
          data.resident_integration_id
        ) {
          notify(
            `Conflict. ${chosen_resident_full_name} already has external integration set!`,
            'warning',
          );
          setResidentFields(f => ({ ...f, select_resident: 0 }));
          return;
        }
        setImportedFields(fields => ({
          ...fields,
          chosen_resident_full_name,
        }));
        setResidentFields(fields => ({
          ...fields,
          select_resident: value,
          original_admit_date: data.original_admit_date,
        }));
        setLastCase({
          id: data.case_id,
          name: data.case_name,
        });
      } else {
        const {
          resident,
          primary,
          primaryRep,
          secondary,
          secondaryRep,
        } = sanitizedResidentFields(response.data);
        setOriginalResidentRecord(response.data);
        const {
          s_insurance,
          s_in_network,
          in_network,
          resident_status_id,
        } = response.data;
        const { case_id, case_name } = resident;
        setResidentFields({ ...resident, select_resident: value });
        setFormData(f => ({ ...f, ...primary, ...secondary }));
        setIsInNetworkPrimary(!!in_network);
        setpayerRepFields({
          primary: { ...primaryRep },
          secondary: { ...secondaryRep },
        });
        setLastCase({ id: case_id, name: case_name });
        if (s_insurance) {
          openSecondary();
          setIsInNetworkSecondary(!!s_in_network);
        }
        if (resident_status_id === resident_status.DISCHARGED_HOSPITAL)
          setFormData(f => ({ ...f, is_readmission: true }));
      }
    },
    [notify, openSecondary, importsExternalData],
  );

  async function checkDuplicateResident(values) {
    const { first_name, last_name, dob, ssn: _ssn } = values;
    const ssn = validateSSNOnSubmit(_ssn);
    if ((first_name && last_name && dob) || (ssn && ssn.length === 9)) {
      const data = await fetchResource('/residents-validate', {
        ...values,
        ssn,
      });
      if (data !== 'error') {
        const { resident_id } = data;
        setDuplicateResidentId(resident_id ?? null);
      }
    }
  }

  useEffect(() => {
    const element = document.getElementById('duplicate-msg');
    if (!element) return;
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }, [duplicateResidentId]);

  useEffect(() => {
    if (residentId && eventName === 'readmission') {
      fetchResident(residentId);
      setFormData(f => ({ ...f, is_readmission: true }));
    }
  }, [residentId, fetchResident, eventName]);

  useEffect(() => {
    setCheckPrimaryAuth(formData.start_date > formData.tracking_date);
    setValidatePrimaryAuth(formData.start_date < formData.tracking_date);
    setCheckSecondaryAuth(formData.s_start_date > formData.tracking_date);
    setValidateSecondaryAuth(formData.s_start_date < formData.tracking_date);
    setCheckAdmit(formData.admit_date !== formData.tracking_date);
  }, [
    formData.admit_date,
    formData.start_date,
    formData.s_start_date,
    formData.tracking_date,
  ]);

  useEffect(() => {
    async function fetchImportedData() {
      if (importsExternalData && meta.id) {
        setLoading(true);

        const response = await fetchAndValidateResource(
          'census',
          meta.id,
        ).catch(e => {
          handleClose();
          throw e;
        });

        if (response.error) {
          notify(
            typeof response.error === 'string'
              ? response.error
              : response.error.message || 'ra.notification.http_error',
            'warning',
          );
          handleClose();
          return;
        }

        setImportedFields({
          ...Object.fromEntries(
            Object.entries(response.data).filter(([key, value]) =>
              [
                'chosen_resident_full_name',
                'synced_resident_id',
                'integration_type',
                'integration_id',
              ].includes(key),
            ),
          ),
          chosen_resident_full_name: response.data.synced_resident_full_name,
          integration_isactive: true,
        });

        const {
          resident,
          primary,
          secondary,
          insurances,
        } = sanitizedResidentFields(response.data);
        const { case_id, case_name } = resident;

        delete resident.admit_date;
        setResidentFields({
          ...resident,
          select_resident: response.data.synced_resident_id || 0,
        });
        setFormData(f => ({
          ...f,
          ...primary,
          ...secondary,
          admit_date: response.data.admit_date,
          tracking_date: response.data.admit_date, //test
          is_readmission:
            response.data.synced_resident_id &&
            eventName === 'importReadmission',
        }));
        setInsurances(
          insurances.map(ins => {
            ins.is_unignored =
              typeof ins.is_unignored === 'undefined' ? true : ins.is_unignored;
            return ins;
          }) || [],
        );
        setLastCase({ id: case_id, name: case_name });
        checkDuplicateResident({
          first_name: resident.first_name,
          last_name: resident.last_name,
          dob: resident.dob,
          ssn: resident.ssn,
        });
      }
      setLoading(false);
    }
    fetchImportedData(meta.id);
    /**
     * openSecondary is dependent on `form_data.admit_date` and
     *  `form_data.days_remaining`. At this point `days_remaining`
     *  should still be the default so it's safe to ignore.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, importsExternalData, meta.id, notify]);

  const removeSecondaryInsurance = useCallback(() => {
    setpayerRepFields({
      ...payerRepFields,
      secondary: {},
    });
    // set all secondary fields to undefind
    const keysToRemove = Object.keys(formData).reduce((acc, cur) => {
      if (cur.startsWith('s_')) {
        acc[cur] = undefined;
      }
      return acc;
    }, {});
    setSecondaryInsurance(false);
    setFormData({ ...formData, ...keysToRemove });
  }, [formData, payerRepFields]);

  const removeAuthFields = type => {
    const fieldsToRemove = getAuthFieldsToRemove(type);
    setFormData(f => ({ ...f, ...fieldsToRemove }));
  };

  const onSubmit = value => {
    const {
      rep_phone,
      s_rep_phone,
      rep_fax,
      s_rep_fax,
      rep_email,
      s_rep_email,
      ssn,
      is_readmission,
      case_id,
      case_name,
      resident_override,
    } = value;

    const outpatientServices = getOutpatientServicesValues(value, notify);
    const insurances = cleanInsurancesValues(value.insurances, notify);
    if ([outpatientServices, insurances].includes('error'))
      return Promise.resolve();
    let data = {
      ...value,
      rep_phone: validatePhoneOnSubmit(rep_phone),
      s_rep_phone: validatePhoneOnSubmit(s_rep_phone),
      rep_fax: validatePhoneOnSubmit(rep_fax),
      s_rep_fax: validatePhoneOnSubmit(s_rep_fax),
      rep_email: validateEmailOnSubmit(rep_email),
      s_rep_email: validateEmailOnSubmit(s_rep_email),
      ssn: validateSSNOnSubmit(ssn),
      case_id: is_readmission ? case_id : undefined,
      case_name: is_readmission ? case_name : undefined,
      resident_override: !!duplicateResidentId ? resident_override : undefined,
      insurances,
    };
    if (outpatientServices) {
      data = { ...data, ...outpatientServices };
    }
    if (importsExternalData) {
      data = { ...data, ...importedFields, census_id: meta.id };
      // External Payer mapping must be rethunk and perhaps redesigned.
      // insurances.forEach((ins, idx) => {
      //   if (!ins.synced_payer_id)
      //     authPost('/payers-facility/integration-details', {
      //       payer_id: ins.payer_id,
      //       line_of_business_id: ins.line_of_business_id,
      //       integration_payer_id: ins.pcc_payer_id,
      //       integration_payer_description: ins.pcc_payer_name,
      //     }).then(res => {
      //       const responseBody = res.data;
      //       if (res.data.id)
      //         setInsurances(i => {
      //           i[idx].synced_payer_id = responseBody.data.id;
      //           return i;
      //         });
      //     });
      // });
    }
    let response;
    return dataProvider
      .create('admissions', {
        data,
      })
      .then(res => {
        response = res?.data;
        typeof onSuccess === 'function' && onSuccess();
      })
      .then(() => {
        notify(
          'form.created',
          'info',
          !importsExternalData &&
            redirect(`/residents-list?resident_id=${response.id}`),
        );
        handleClose();
        typeof refresh === 'function' && refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };

  const checkSubmit = value => {
    if (validatePrimaryAuth || validateSecondaryAuth)
      return notify(
        'Auth start date cannot be earlier than skilled pickup date.',
      );
    return checkPrimaryAuth || checkSecondaryAuth || checkAdmit
      ? setConfirmSubmit(true)
      : onSubmit(value);
  };

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    if (name === 'select_resident') {
      if (value !== 0) {
        fetchResident(value);
      } else if (importsExternalData) {
        setImportedFields(fields => ({
          ...fields,
          chosen_resident_full_name: '',
        }));
        setResidentFields(fields => ({ ...fields, select_resident: 0 }));
      } else {
        setResidentFields({ select_resident: value });
      }
      return;
    }
    if (name === 'insurance' || name === 's_insurance') {
      const type = name === 'insurance' ? 'primary' : 'secondary';
      const fields = {
        [name]: value,
        [getFieldName('line_of_business_id', type)]: undefined,
      };
      setFormData(f => ({
        ...f,
        ...fields,
      }));
      setpayerRepFields({
        ...payerRepFields,
        [type]: {},
      });
      return;
    }
    if (name === 'payer_rep' || name === 's_payer_rep') {
      const type = name === 'payer_rep' ? 'primary' : 'secondary';
      if (value !== 0) {
        const data = await fetchResource('payer-reps', value);
        if (data !== 'error') {
          const {
            name: rep_name,
            email: rep_email,
            phone: rep_phone,
            phone_ext: rep_phone_ext,
            fax: rep_fax,
            comments: rep_comments,
          } = data;
          setpayerRepFields({
            ...payerRepFields,
            [type]: {
              [getFieldName('payer_rep', type)]: value,
              [getFieldName('rep_name', type)]: rep_name,
              [getFieldName('rep_email', type)]: rep_email,
              [getFieldName('rep_phone', type)]: rep_phone,
              [getFieldName('rep_phone_ext', type)]: rep_phone_ext,
              [getFieldName('rep_fax', type)]: rep_fax,
              [getFieldName('rep_comments', type)]: rep_comments,
            },
          });
        }
      } else {
        setpayerRepFields({
          ...payerRepFields,
          [type]: { [getFieldName('payer_rep', type)]: value },
        });
      }
      return;
    }
    if (
      name === 'authorization_type_id' ||
      name === 's_authorization_type_id' ||
      name === 'line_of_business_id' ||
      name === 's_line_of_business_id'
    ) {
      const type =
        name === 'authorization_type_id' || name === 'line_of_business_id'
          ? 'primary'
          : 'secondary';
      const fetchName = type === 'primary' ? name : name.substr(2);
      const fetchValues = {
        insurance: formData[getFieldName('insurance', type)],
        authorization_type_id:
          formData[getFieldName('authorization_type_id', type)],
        line_of_business_id:
          formData[getFieldName('line_of_business_id', type)],
        [fetchName]: value,
      };

      const {
        insurance,
        authorization_type_id,
        line_of_business_id,
      } = fetchValues;
      if (insurance && authorization_type_id && line_of_business_id) {
        const data = await fetchRateRevCode(fetchValues);
        if (data !== 'error') {
          const { rate, rev_code } = data;
          setFormData(f => ({
            ...f,
            [name]: value,
            [getFieldName('rate', type)]: rate,
            [getFieldName('rev_code', type)]: rev_code,
          }));
        }
      } else {
        setFormData(f => ({ ...f, [name]: value }));
      }
      return;
    }

    if (name === 'days_of_coverage') {
      const { admit_date: _admit_date, days_used = 0 } = formData;
      const admit_date = new Date(`${_admit_date} 00:00`);
      const days_remaining = +value - +days_used > 0 ? +value - +days_used : 0;
      const fields = {
        days_remaining,
      };
      if (isValid(admit_date) && secondaryInsurance) {
        fields.s_copay_end_date = format(
          addDays(admit_date, +days_remaining - 1),
          'yyyy-MM-dd',
        );
      }
      setFormData({
        ...formData,
        [name]: value,
        ...fields,
      });
      return;
    }

    if (name === 'days_used') {
      const {
        admit_date: _admit_date,
        s_copay_start_day,
        days_of_coverage,
      } = formData;
      const daysRemaining = days_of_coverage ? +days_of_coverage - +value : 0;
      const days_remaining = daysRemaining > 0 ? daysRemaining : 0;
      const admit_date = new Date(`${_admit_date} 00:00`);
      if (isValid(admit_date)) {
        const fields = {};
        if (s_copay_start_day) {
          fields.s_copay_start_date = format(
            addDays(admit_date, +s_copay_start_day - +value - 1), // need to include the admit date
            'yyyy-MM-dd',
          );
        }
        if (days_of_coverage && secondaryInsurance) {
          fields.s_copay_end_date = format(
            addDays(admit_date, +days_remaining - 1),
            'yyyy-MM-dd',
          );
        }
        setFormData({
          ...formData,
          [name]: value,
          days_remaining,
          ...fields,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
          days_remaining: daysRemaining > 0 ? daysRemaining : 0,
        });
      }
      return;
    }

    if (
      name === 'authorization_status_id' ||
      name === 's_authorization_status_id'
    ) {
      const type = name === 'authorization_status_id' ? 'primary' : 'secondary';
      const startDate = formData[getFieldName('start_date', type)];
      const start_date = new Date(`${startDate} 00:00`);
      const endDate = formData[getFieldName('end_date', type)];
      const end_date = new Date(`${endDate} 00:00`);

      setFormData({
        ...formData,
        [name]: value,
        [getFieldName('days_approved', type)]:
          value !== DENIED && value !== DENIAL_RECEIVED
            ? isValid(start_date) && isValid(end_date)
              ? isBefore(start_date, end_date)
                ? differenceInCalendarDays(end_date, start_date)
                : 0
              : 0
            : 0,
      });
      return;
    }

    if (name === 'start_date' || name === 's_start_date') {
      const type = name === 'start_date' ? 'primary' : 'secondary';
      const days_approved = formData[getFieldName('days_approved', type)];
      const start_date = new Date(`${value} 00:00`);
      if (isValid(start_date) && days_approved) {
        const end_date = new Date(
          addDays(new Date(`${value} 00:00`), +days_approved - 1),
        );
        const now = new Date();
        const availableFromDate = isFuture(start_date) ? start_date : now;
        setFormData({
          ...formData,
          [name]: value,
          [getFieldName('end_date', type)]: format(end_date, 'yyyy-MM-dd'),
          [getFieldName('days_available', type)]: isBefore(
            availableFromDate,
            end_date,
          )
            ? differenceInCalendarDays(end_date, availableFromDate)
            : 0,
        });
      } else {
        setFormData({ ...formData, [name]: value });
      }
      return;
    }
    if (name === 'days_approved' || name === 's_days_approved') {
      const type = name === 'days_approved' ? 'primary' : 'secondary';
      const startDate = formData[getFieldName('start_date', type)];
      const start_date = new Date(`${startDate} 00:00`);
      const authorization_status_id =
        formData[getFieldName('authorization_status_id', type)];
      if (value && isValid(start_date)) {
        const end_date = new Date(addDays(start_date, value - 1));
        const now = new Date();
        const availableFromDate = isFuture(start_date) ? start_date : now;
        setFormData({
          ...formData,
          [name]:
            authorization_status_id !== DENIED &&
            authorization_status_id !== DENIAL_RECEIVED
              ? value
              : 0,
          [getFieldName('end_date', type)]: format(end_date, 'yyyy-MM-dd'),
          [getFieldName('days_available', type)]: isBefore(
            availableFromDate,
            end_date,
          )
            ? differenceInCalendarDays(end_date, availableFromDate)
            : 0,
        });
      } else {
        setFormData({
          ...formData,
          [name]:
            authorization_status_id !== DENIED &&
            authorization_status_id !== DENIAL_RECEIVED
              ? value
              : 0,
        });
      }
      return;
    }
    if (name === 'end_date' || name === 's_end_date') {
      const type = name === 'end_date' ? 'primary' : 'secondary';
      const startDate = formData[getFieldName('start_date', type)];
      const start_date = new Date(`${startDate} 00:00`);
      const end_date = new Date(`${value} 00:00`);
      const authorization_status_id =
        formData[getFieldName('authorization_status_id', type)];
      if (isValid(end_date) && isValid(start_date)) {
        const now = new Date();
        const availableFromDate = isFuture(start_date) ? start_date : now;
        setFormData({
          ...formData,
          [getFieldName('days_approved', type)]:
            authorization_status_id !== DENIED &&
            authorization_status_id !== DENIAL_RECEIVED
              ? differenceInCalendarDays(end_date, start_date) + 1
              : 0,
          [name]: value,
          [getFieldName('days_available', type)]: isBefore(
            availableFromDate,
            end_date,
          )
            ? differenceInCalendarDays(end_date, availableFromDate)
            : 0,
        });
      } else {
        setFormData({ ...formData, [name]: value });
      }
      return;
    }

    if (name === 'admit_date' || name === 's_copay_start_day') {
      const usesInsurancesArray = insurances?.length >= 2;

      const s_copay_start_day =
        name === 's_copay_start_day'
          ? value
          : usesInsurancesArray
          ? insurances[1].copay_start_day
          : formData.s_copay_start_day;

      const {
        days_used = 0,
        days_remaining,
        days_of_coverage,
      } = usesInsurancesArray ? insurances[0] : formData;

      const _admit_date = name === 'admit_date' ? value : formData.admit_date;
      const admit_date = new Date(`${_admit_date} 00:00`);

      if (isValid(admit_date)) {
        const fields = {};
        fields.tracking_date = _admit_date;
        if (s_copay_start_day) {
          fields.s_copay_start_date = format(
            addDays(admit_date, +s_copay_start_day - +days_used - 1),
            'yyyy-MM-dd',
          );
        }
        if (
          secondaryInsurance &&
          name === 'admit_date' &&
          !isNaN(days_remaining) &&
          days_of_coverage
        ) {
          fields.s_copay_end_date = format(
            addDays(admit_date, +days_remaining - 1),
            'yyyy-MM-dd',
          );
        }
        if (usesInsurancesArray) {
          const iFields = {};
          if (usesInsurancesArray && fields.s_copay_start_date) {
            iFields.copay_start_date = fields.s_copay_start_date;
            delete fields.s_copay_start_date;
          }
          if (usesInsurancesArray && fields.s_copay_end_date) {
            iFields.copay_end_date = fields.s_copay_end_date;
            delete fields.s_copay_end_date;
          }
          setInsurances(ins => {
            ins[1] = { ...ins[1], ...iFields };
            return [...ins];
          });
        }
        setFormData(f => ({
          ...f,
          [name]: value,
          ...fields,
        }));
      } else {
        setFormData(f => ({ ...f, [name]: value }));
      }
      return;
    }

    if (name === 'authorization_id' || name === 's_authorization_id') {
      const type = name === 'authorization_id' ? 'primary' : 'secondary';
      if (+value !== 0) {
        const data = await fetchResource('/readmission/auth-details', {
          id: value,
        });
        if (data !== 'error') {
          const fields = getPrimaryOrSecondaryKeys(data, type);
          const sd = formData[getFieldName('start_date', type)];
          const ed = fields[getFieldName('end_date', type)];
          const start_date = new Date(`${sd} 00:00`);
          const end_date = new Date(`${ed} 00:00`);
          const update_weekday = fields[getFieldName('update_weekday', type)];
          const update_type = fields[getFieldName('update_type', type)];
          const update_frequency_days =
            fields[getFieldName('update_frequency_days', type)];
          const update_frequency =
            fields[getFieldName('update_frequency', type)];
          const initial_update_date =
            fields[getFieldName('initial_update_date', type)];
          const no_update_reason =
            fields[getFieldName('no_update_reason', type)];
          const update_notes = fields[getFieldName('update_notes', type)];
          const authorization_status_id =
            fields[getFieldName('authorization_status_id', type)];
          if (isValid(start_date) && isValid(end_date)) {
            fields[getFieldName('days_approved', type)] =
              authorization_status_id !== DENIED &&
              authorization_status_id !== DENIAL_RECEIVED
                ? isBefore(start_date, end_date)
                  ? differenceInCalendarDays(end_date, start_date)
                  : 0
                : 0;
          }

          setFormData(f => ({
            ...f,
            [name]: value,
            ...fields,
            [getFieldName('update_weekday', type)]: Array.isArray(
              update_weekday,
            )
              ? update_weekday
              : [],
            [getFieldName('update_type', type)]: Array.isArray(update_type)
              ? update_type
              : [],
            [getFieldName('schedule_type')]: setScheduleType({
              initial_update_date,
              update_frequency_days,
              update_frequency,
              update_weekday,
              no_update_reason,
              update_notes,
            }),
            [getFieldName('update_frequency', type)]: update_frequency
              ? update_frequency
              : update_frequency_days
              ? 'freq_days'
              : undefined,
          }));
        } else {
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setFormData({ ...formData, [name]: value });
      }
      return;
    }

    if (name === 'schedule_type' || name === 's_schedule_type') {
      const type = name === 'schedule_type' ? 'primary' : 'secondary';
      const newState = { [name]: value };
      if (value === 'one_time' || value === 'recurring_day') {
        newState[getFieldName('update_frequency', type)] = undefined;
        newState[getFieldName('update_frequency_days', type)] = undefined;
        newState[getFieldName('no_update_reason', type)] = undefined;
      }
      if (value === 'one_time' || value === 'recurring_date') {
        newState[getFieldName('update_weekday', type)] = [];
        newState[getFieldName('no_update_reason', type)] = undefined;
      }
      if (value === 'recurring_day') {
        newState[getFieldName('initial_update_date', type)] = undefined;
        newState[getFieldName('no_update_reason', type)] = undefined;
      }
      if (value === 'no_update') {
        newState[getFieldName('initial_update_date', type)] = undefined;
        newState[getFieldName('update_frequency', type)] = undefined;
        newState[getFieldName('update_frequency_days', type)] = undefined;
        newState[getFieldName('update_weekday', type)] = [];
        newState[getFieldName('update_type', type)] = [];
        newState[getFieldName('update_notes', type)] = undefined;
      }
      setFormData(f => ({ ...f, ...newState }));
      return;
    }

    if (name === 'update_weekday' || name === 's_update_weekday') {
      const type = name === 'update_weekday' ? 'primary' : 'secondary';
      if (Array.isArray(value) && value.length > 1) {
        setFormData(f => ({
          ...f,
          [name]: value,
          [getFieldName('update_frequency', type)]: 'weekly',
        }));
        return;
      }
    }

    if (
      name === 'ot_minutes' ||
      name === 'pt_minutes' ||
      name === 'speech_minutes' ||
      name === 's_ot_minutes' ||
      name === 's_pt_minutes' ||
      name === 's_speech_minutes'
    ) {
      const newValues = { ...formData, [name]: value };
      const split = name.split('_');
      const property =
        split.length === 2 ? split[0] : `${split[0]}_${split[1]}`;
      const unitValue = value ? parseInt(value) / 15 : null;
      newValues[`${property}_units`] = unitValue;
      setFormData({ ...newValues });
      return;
    }

    if (
      name === 'ot_units' ||
      name === 'pt_units' ||
      name === 'speech_units' ||
      name === 's_ot_units' ||
      name === 's_pt_units' ||
      name === 's_speech_units'
    ) {
      const newValues = { ...formData, [name]: value };
      const split = name.split('_');
      const property =
        split.length === 2 ? split[0] : `${split[0]}_${split[1]}`;
      const minuteValue = value ? parseInt(value) * 15 : null;
      newValues[`${property}_minutes`] = minuteValue;
      setFormData({ ...newValues });
      return;
    }

    setFormData(f => ({ ...f, [name]: value }));
  };
  const customOnBlur = async event => {
    const { name, value } = event.target;
    if (name === 'first_name' || name === 'last_name' || name === 'dob') {
      const values = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        dob: formData.dob,
        [name]: value,
      };
      await checkDuplicateResident(values);
    }
    if (name === 'ssn') {
      await checkDuplicateResident({
        ssn: value,
      });
    }
  };

  useEffect(() => {
    if (formData.is_readmission) {
      /* If readmission, force payer value back to original payer.
       Client doesn't want payer changes to be able to persist
       this way */
      const { primary = {}, secondary = {} } = sanitizedResidentFields(
        originalResidentRecord,
      );
      const { s_in_network, in_network } = originalResidentRecord;
      setFormData(f => ({ ...f, ...primary, ...secondary }));
      setIsInNetworkPrimary(!!in_network);
      typeof s_in_network !== 'undefined' &&
        setIsInNetworkSecondary(!!s_in_network);
    }
  }, [formData.is_readmission, originalResidentRecord]);

  if (isEdit) {
    return (
      <AdmissionEditForm
        handleClose={handleClose}
        record={record}
        refresh={refresh}
        closeAside={closeAside}
      />
    );
  }
  return loading ? (
    <Loading
      /* keep modal size steady as square spinner rotates */
      style={{ height: 60 }}
    />
  ) : (
    <Fragment>
      <Form
        onSubmit={checkSubmit}
        initialValues={{
          ...residentFields,
          ...formData,
          ...payerRepFields.primary,
          ...payerRepFields.secondary,
          insurances,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          const residentAlreadySynced =
            importsExternalData && !!importedFields.synced_resident_id;

          const preexistingResidentData =
            importsExternalData || !!values.select_resident;

          const showsImmunizations = !importsExternalData;

          const residentsDetailsComment =
            (residentAlreadySynced &&
              importedFields.chosen_resident_full_name &&
              ' - ' + importedFields.chosen_resident_full_name) ||
            (importsExternalData && (
              <Typography variant='caption' align='left' display='inline'>
                {' '}
                A new resident will be created or search for existing resident
                below
              </Typography>
            ));

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {(!residentId || eventName !== 'readmission') &&
                  !importsExternalData && (
                    <AutocompleteInput
                      reference='residents/list'
                      customOnChange={customOnChange}
                      name='select_resident'
                      label='Select a resident'
                      validate={required()}
                      required
                      fullWidth
                      openOnFocus
                      autocompleteProps={{ openOnFocus: true }}
                      resetOption={{ id: 0, name: 'Create New Resident' }}
                      options={{ sort: { field: 'last_name', order: 'ASC' } }}
                    />
                  )}
                {values.select_resident > 0 && !!lastCase.name && (
                  <div style={{ paddingTop: 10, marginLeft: 5 }}>
                    <CheckboxInput
                      name='is_readmission'
                      customOnChange={customOnChange}
                      label={`Readmission for case ${lastCase.name}`}
                      checked={values.is_readmission}
                      disabled={residentId && eventName === 'readmission'}
                    />
                  </div>
                )}
                {(importsExternalData || values.select_resident >= 0) && (
                  <Fragment>
                    <DialogContentText
                      align='left'
                      variant='h6'
                      className={classes.header}
                    >
                      Resident’s details
                      {residentsDetailsComment}
                    </DialogContentText>
                    <Divider className={classes.divider} />
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='first_name'
                        validate={required()}
                        required
                        customOnChange={customOnChange}
                        customOnBlur={customOnBlur}
                        label='First name'
                        disabled={preexistingResidentData}
                        style={{ marginRight: 10 }}
                      />
                      <FormTextField
                        name='middle_name'
                        label='Middle name'
                        customOnChange={customOnChange}
                        disabled={preexistingResidentData}
                        style={{ marginRight: 10 }}
                      />
                      <FormTextField
                        name='last_name'
                        validate={required()}
                        required
                        customOnChange={customOnChange}
                        customOnBlur={customOnBlur}
                        label='Last name'
                        disabled={preexistingResidentData}
                        style={{ marginRight: 0 }}
                      />
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='dob'
                        customOnChange={customOnChange}
                        customOnBlur={customOnBlur}
                        type='date'
                        label='DOB'
                        disabled={preexistingResidentData}
                        validate={composeValidators([validateDate()])}
                      />
                      <FormTextField
                        name='ssn'
                        validate={composeValidators([
                          minLength(9, 'Please enter a 9 digit SSN'),
                        ])}
                        customOnChange={customOnChange}
                        customOnBlur={customOnBlur}
                        label='SSN #'
                        disabled={preexistingResidentData}
                        format='ss'
                      />
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <ReferenceInput
                        reference='gender/list'
                        customOnChange={customOnChange}
                        name='gender_id'
                        label='Gender'
                        validate={required()}
                        required
                        disabled={preexistingResidentData}
                      />
                      <FormTextField
                        name='medicare_id'
                        customOnChange={customOnChange}
                        label='Medicare ID #'
                        disabled={preexistingResidentData}
                      />
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='medicaid_id'
                        customOnChange={customOnChange}
                        label='Medicaid ID #'
                        disabled={preexistingResidentData}
                      />
                      <FormTextField
                        customOnChange={customOnChange}
                        name='facility_resident_id'
                        label='Resident ID'
                        disabled={preexistingResidentData}
                      />
                    </div>
                    {!!values.select_resident && (
                      <div className={clsx(classes.inputContainerWrap)}>
                        <FormTextField
                          customOnChange={customOnChange}
                          disabled
                          name='original_admit_date'
                          type='date'
                          label='Original admit date'
                          validate={composeValidators([validateDate()])}
                        />
                        {/* Needed for spacing */}
                        <div style={{ flex: '1 0 160px' }} />
                      </div>
                    )}
                    {showsImmunizations && (
                      <Fragment>
                        <div className={clsx(classes.inputContainerWrap)}>
                          <FormTextField
                            name='last_pne_shot'
                            customOnChange={customOnChange}
                            type='date'
                            label='PNA shot'
                            validate={composeValidators([validateDate()])}
                            disabled={!!values.select_resident}
                            className={classes.mediumInput}
                          />
                          <SelectComponent
                            customOnChange={customOnChange}
                            label='PNA shot status'
                            name='pne_shot_status'
                            choices={flu_status_types}
                            disabled={!!values.select_resident}
                            className={classes.mediumInput}
                          />
                        </div>
                        <div className={clsx(classes.inputContainerWrap)}>
                          <FormTextField
                            name='last_flu_shot'
                            customOnChange={customOnChange}
                            type='date'
                            validate={composeValidators([validateDate()])}
                            label='Flu shot'
                            disabled={!!values.select_resident}
                            className={classes.mediumInput}
                          />
                          <SelectComponent
                            customOnChange={customOnChange}
                            label='Flu shot status'
                            name='flu_shot_status'
                            choices={flu_status_types}
                            disabled={!!values.select_resident}
                            className={classes.mediumInput}
                          />
                        </div>
                      </Fragment>
                    )}
                    {!!duplicateResidentId && !values.resident_override && (
                      <Fragment>
                        <div id='duplicate-msg' />
                        <Typography color='error'>
                          This resident already exists. Click{' '}
                          <Typography color='primary' component='span'>
                            <a
                              style={{ textDecoration: 'none' }}
                              href={`${
                                window.location.origin
                              }/#/residents-list?${qs.stringify({
                                resident_id: duplicateResidentId,
                              })}`}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              here
                            </a>
                          </Typography>{' '}
                          to view the existing resident.
                        </Typography>
                      </Fragment>
                    )}
                    <FormGroup>
                      {!!duplicateResidentId && (
                        <CheckboxInput
                          name='resident_override'
                          customOnChange={customOnChange}
                          label='Add this resident anyway?'
                          checked={values.resident_override}
                          required
                          validate={required()}
                        />
                      )}
                    </FormGroup>
                  </Fragment>
                )}
                {importsExternalData && !residentAlreadySynced && (
                  <AutocompleteInput
                    reference='residents/list'
                    customOnChange={customOnChange}
                    name='select_resident'
                    label='or select an existing resident'
                    fullWidth
                    autocompleteProps={{ openOnFocus: true }}
                    options={{
                      filter: { is_synced: false },
                      sort: { field: 'last_name', order: 'ASC' },
                    }}
                  />
                )}
                <DialogContentText
                  align='left'
                  variant='h6'
                  className={classes.header}
                >
                  Admission details
                </DialogContentText>
                <Divider className={classes.divider} />

                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='admit_date'
                    required
                    customOnChange={customOnChange}
                    type='date'
                    validate={composeValidators([required(), validateDate()])}
                    label='Admit date'
                    disabled={importsExternalData}
                  />
                  <FormTextField
                    name='room_number'
                    customOnChange={customOnChange}
                    label='Room #'
                    disabled={importsExternalData}
                    style={{ marginRight: 0 }}
                  />
                  <ReferenceInput
                    reference='resident-statuses/list'
                    customOnChange={customOnChange}
                    name='resident_status_id'
                    label='Case status'
                    validate={required()}
                    required
                    fullWidth
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <CheckboxInput
                    name='edit_tracking_date'
                    customOnChange={customOnChange}
                    label={`Change pickup of skilled date`}
                    checked={values.edit_tracking_date}
                  />
                  <FormTextField
                    name='tracking_date'
                    required
                    customOnChange={customOnChange}
                    type='date'
                    validate={composeValidators([required(), validateDate()])}
                    label='Pickup of skilled date'
                    disabled={!formData.edit_tracking_date}
                  />
                </div>
                {values.resident_status_id !== 8 &&
                  (!values.insurances?.length ? (
                    <Fragment>
                      <DialogContentText
                        align='left'
                        variant='h6'
                        className={classes.header}
                      >
                        Primary payer
                      </DialogContentText>
                      <Divider className={classes.divider} />
                      <PrimaryInsuranceInputs
                        type='primary'
                        customOnChange={customOnChange}
                        values={values}
                        removeAuthFields={removeAuthFields}
                        isEdit={isEdit}
                        lastCase={lastCase}
                        setFormData={setFormData}
                        formData={formData}
                        isInNetwork={isInNetworkPrimary}
                        setIsInNetwork={setIsInNetworkPrimary}
                        notify={notify}
                      />
                      {!values.is_readmission && (
                        <div className={classes.secondaryActionContainer}>
                          {!secondaryInsurance && (
                            <DialogContentText
                              color='primary'
                              onClick={() => openSecondary(true)}
                              className={classes.secondaryAction}
                            >
                              <AddIcon /> Add secondary payer
                            </DialogContentText>
                          )}
                          {secondaryInsurance && (
                            <DialogContentText
                              color='primary'
                              onClick={() => removeSecondaryInsurance()}
                              className={classes.secondaryAction}
                            >
                              <RemoveIcon style={{ marginRight: 5 }} /> Remove
                              secondary payer
                            </DialogContentText>
                          )}
                        </div>
                      )}
                      {secondaryInsurance && (
                        <Fragment>
                          <DialogContentText
                            align='left'
                            variant='h6'
                            className={classes.header}
                          >
                            Secondary payer
                          </DialogContentText>
                          <Divider className={classes.divider} />
                          <SecondaryInsuranceInputs
                            type='secondary'
                            customOnChange={customOnChange}
                            values={values}
                            removeAuthFields={removeAuthFields}
                            isEdit={isEdit}
                            lastCase={lastCase}
                            setFormData={setFormData}
                            formData={formData}
                            isInNetwork={isInNetworkSecondary}
                            setIsInNetwork={setIsInNetworkSecondary}
                            notify={notify}
                          />
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <DialogContentText
                        align='left'
                        variant='h6'
                        className={classes.header}
                      >
                        Payers in PointClickCare
                      </DialogContentText>
                      {values.insurances.map((ins, index) => (
                        <ExternalInsuranceInputs
                          key={`insurance_inputs_${ins.pcc_payer_id}`}
                          indexRank={index}
                          values={values}
                          isEdit={isEdit}
                          lastCase={lastCase}
                          setFormData={setFormData}
                          setInsurances={setInsurances}
                          showAddNewOption={false}
                        />
                      ))}
                    </Fragment>
                  ))}
                <FormTextField
                  name='notes'
                  label='Notes'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                />
                <div className={classes.insuranceTitle}>
                  Attachments
                  <FileInput
                    title='title'
                    name='attachments'
                    setFormData={setFormData}
                    formData={formData}
                    classes={{ dropZone: classes.dropZone }}
                  />
                </div>
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={
                    (!!duplicateResidentId && !values.resident_override) ||
                    submitting
                  }
                  type='submit'
                />
              </DialogActions>
              <ConfirmedActionModal
                text={
                  <Fragment>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <div className={clsx(classes.inputContainerWrap)}>
                        {checkAdmit
                          ? 'Admit date is different than pickup of skilled date.'
                          : null}
                      </div>
                      {checkPrimaryAuth && checkSecondaryAuth
                        ? 'Primary and secondary auth start dates are later than the skilled pickup date.'
                        : checkPrimaryAuth
                        ? 'Primary auth start date is later than the skilled pickup date.'
                        : checkSecondaryAuth
                        ? 'Secondary auth start date is later than the skilled pickup date.'
                        : null}
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      Proceed?
                    </div>
                  </Fragment>
                }
                confirmButtonText='Create admission'
                open={confirmSubmit}
                onClose={() => setConfirmSubmit(false)}
                onConfirm={() => onSubmit(values)}
              />
            </form>
          );
        }}
      />
    </Fragment>
  );
};

const flu_status_types = [
  { id: 'current', name: 'Current' },
  { id: 'unknown', name: 'Unknown' },
  { id: 'refused', name: 'Refused' },
  { id: 'N/A', name: 'N/A' },
];

async function fetchAndValidateResource(resource, value) {
  const response = await authGet(
    typeof value === 'object'
      ? [resource, { ...value }]
      : `/${resource}/${value}`,
  );
  return response;
}

/** @param {string} type */
function getAuthFieldsToRemove(type) {
  const fieldsToRemove = {
    insurance_auth_number: undefined,
    authorization_status_id: undefined,
    authorization_type_id: undefined,
    rev_code: undefined,
    start_date: undefined,
    days_approved: undefined,
    end_date: undefined,
    days_available: undefined,
    rate: undefined,
    letter_received: undefined,
    auth_letter_status_id: undefined,
    auth_notes: undefined,
    authorization_id: undefined,
    initial_update_date: undefined,
    update_frequency: undefined,
    update_weekday: undefined,
    update_type: undefined,
    no_update_reason: undefined,
    update_notes: undefined,
    outpatient_services: undefined,
  };
  return getPrimaryOrSecondaryKeys(fieldsToRemove, type);
}

function getPrimaryOrSecondaryKeys(data, type) {
  if (type === 'primary') return data;
  const prefix = type === 'secondary' ? 's' : type;
  return Object.keys(data).reduce((acc, cur) => {
    acc[`${prefix}_${cur}`] = data[cur];
    return acc;
  }, {});
}

function sanitizedResidentFields(data) {
  const {
    insurance,
    line_of_business_id,
    residents_insurance_id,
    payer_rep,
    rep_name,
    rep_email,
    rep_phone,
    rep_phone_ext,
    rep_fax,
    rep_comments,
    s_insurance,
    s_line_of_business_id,
    s_residents_insurance_id,
    s_payer_rep,
    s_rep_name,
    s_rep_email,
    s_rep_phone,
    s_rep_phone_ext,
    s_rep_fax,
    s_rep_comments,
    insurances = [],
    ...rest
  } = data;
  const primary = {
    insurance,
    line_of_business_id,
    residents_insurance_id,
  };
  const primaryRep = {
    payer_rep,
    rep_name,
    rep_email,
    rep_phone,
    rep_phone_ext,
    rep_fax,
    rep_comments,
  };

  const secondary = {
    s_insurance,
    s_line_of_business_id,
    s_residents_insurance_id,
  };

  const secondaryRep = {
    s_payer_rep,
    s_rep_name,
    s_rep_email,
    s_rep_phone,
    s_rep_phone_ext,
    s_rep_fax,
    s_rep_comments,
  };
  return {
    resident: rest,
    primary,
    primaryRep,
    secondary,
    secondaryRep,
    insurances,
  };
}

function getOutpatientServicesValues(values, notify) {
  const res = {};
  try {
    res.outpatient_services = getOutpatientServicesValue(values, 'primary');
    res.s_outpatient_services = getOutpatientServicesValue(values, 'secondary');
  } catch (e) {
    if (e.message === MISSING_OUTPATIENT_SERVICES)
      notify('You must enter outpatient services', 'warning');
    return 'error';
  }
  return res;
}

function getOutpatientServicesValue(values, rank) {
  function rankedField(fieldname) {
    const prefix = rank === 'secondary' ? 's_' : '';
    return prefix + fieldname;
  }
  if (values[rankedField('authorization_type_id')] !== PART_B_AUTH_ID) return;
  const results = [];
  if (values[rankedField('pt')]) {
    results.push({
      service: 'PT',
      frequency: values[rankedField('pt_frequency')],
      minutes: values[rankedField('pt_minutes')],
    });
  }
  if (values[rankedField('ot')]) {
    results.push({
      service: 'OT',
      frequency: values[rankedField('ot_frequency')],
      minutes: values[rankedField('ot_minutes')],
    });
  }
  if (values[rankedField('speech')]) {
    results.push({
      service: 'Speech',
      frequency: values[rankedField('speech_frequency')],
      minutes: values[rankedField('speech_minutes')],
    });
  }

  if (!results.length) {
    throw Error(MISSING_OUTPATIENT_SERVICES);
  }
  return results;
}

function cleanInsuranceValues(insurance, notify) {
  const services = [];

  const {
    pt,
    pt_frequency,
    pt_minutes,
    ot,
    ot_frequency,
    ot_minutes,
    speech,
    speech_frequency,
    speech_minutes,
    ...rest
  } = insurance;
  if (insurance.authorization_type_id !== PART_B_AUTH_ID) return rest;
  if (pt) {
    services.push({
      service: 'PT',
      frequency: pt_frequency,
      minutes: pt_minutes,
    });
  }
  if (ot) {
    services.push({
      service: 'OT',
      frequency: ot_frequency,
      minutes: ot_minutes,
    });
  }
  if (speech) {
    services.push({
      service: 'Speech',
      frequency: speech_frequency,
      minutes: speech_minutes,
    });
  }
  if (!services.length) {
    notify('You must enter outpatient services', 'warning');
    return 'error';
  }
  rest.outpatient_services = services;
  return rest;
}

function cleanInsurancesValues(insurances, notify) {
  return insurances
    .filter(ins => ins.is_unignored)
    .map(ins => cleanInsuranceValues(ins, notify));
}
