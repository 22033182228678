import React from 'react';
import { SvgIcon, Tooltip } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AccountIcon from '@material-ui/icons/AccountCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import EventIcon from '@material-ui/icons/Event';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import TimeIcon from '@material-ui/icons/AccessTime';
import AuthIcon from '@material-ui/icons/AssignmentTurnedIn';
import DenialIcon from '@material-ui/icons/AssignmentLate';
import UpdateIcon from '@material-ui/icons/Assignment';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import PharmIcon from '@material-ui/icons/Healing';
import PayerIcon from '@material-ui/icons/MonetizationOn';
import FilterListIcon from '@material-ui/icons/FilterList';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import ProgressIcon from '@material-ui/icons/TrendingUp';
import CensusIcon from '@material-ui/icons/Storage';
import FacilityIcon from '@material-ui/icons/AccountBalance';
import ReportIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import NotificationIcon from '@material-ui/icons/Notifications';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import AlertIcon from '@material-ui/icons/Notifications';
import CheckIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DownloadIcon from '@material-ui/icons/GetApp';
import ErrorIcon from '@material-ui/icons/Error';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LinkIcon from '@material-ui/icons/Link';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FolderIcon from '@material-ui/icons/Folder';
import EmailIcon from '@material-ui/icons/Email';
import MinimizeIcon from '@material-ui/icons/Minimize';
import SendIcon from '@material-ui/icons/Send';
import PersonIcon from '@material-ui/icons/Person';
import ShareIcon from '@material-ui/icons/Share';
import DeactivateFacilityIcon from '@material-ui/icons/RemoveCircle';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import LaunchIcon from '@material-ui/icons/Launch';
import InboxIcon from '@material-ui/icons/InboxOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import NewUpdateIcon from '@material-ui/icons/PlayArrow';
import UploadIcon from '@material-ui/icons/CloudUpload';
import ViewIcon from '@material-ui/icons/OpenInNew';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DatePickerIcon from '@material-ui/icons/InsertInvitation';
const DocxIcon = ({ className }) => (
  <SvgIcon className={className}>
    {
      <path d='M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 20 7 L 20 9 L 14 9 L 14 11 L 20 11 L 20 13 L 14 13 L 14 15 L 20 15 L 20 17 L 14 17 L 14 19 L 22 19 L 22 5 L 14 5 z M 3.2949219 8.2949219 L 4.8300781 8.2949219 L 5.3222656 11.773438 C 5.3572656 11.982437 5.3845781 12.267141 5.3925781 12.619141 L 5.4179688 12.619141 C 5.4259688 12.362141 5.4624375 12.068906 5.5234375 11.753906 L 6.1542969 8.2949219 L 8.0234375 8.2949219 L 8.6074219 11.742188 C 8.6424219 11.923187 8.6673594 12.189203 8.6933594 12.533203 L 8.7128906 12.533203 C 8.7208906 12.266203 8.74725 11.989125 8.78125 11.703125 L 9.2597656 8.2949219 L 10.703125 8.2949219 L 9.3203125 15.705078 L 8.0058594 15.705078 L 7.1269531 11.744141 C 7.0829531 11.497141 7.0588281 11.219062 7.0488281 10.914062 L 7.03125 10.914062 C 7.01325 11.247063 6.9785469 11.524141 6.9355469 11.744141 L 6.0390625 15.705078 L 4.671875 15.705078 L 3.2949219 8.2949219 z' />
    }
  </SvgIcon>
);

const XlsxIcon = ({ className }) => (
  <SvgIcon className={className}>
    {
      <path d='M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 22 19 L 22 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 4.1757812 8.296875 L 5.953125 8.296875 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.3222656 8.296875 L 9.9433594 8.296875 L 8.0078125 11.966797 L 10 15.703125 L 8.2714844 15.703125 L 7.1582031 13.289062 C 7.1162031 13.204062 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.7363281 15.703125 L 4 15.703125 L 6.0605469 11.996094 L 4.1757812 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z' />
    }
  </SvgIcon>
);

const CheckOutlinedIcon = ({ className, tooltip, placement = 'left' }) => (
  <Tooltip title={tooltip || ''} placement={placement}>
    <SvgIcon className={className}>
      {
        <path d='M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' />
      }
    </SvgIcon>
  </Tooltip>
);

const FacilityGroupIcon = ({ className }) => (
  <SvgIcon className={className}>
    {
      <svg>
        <g fill='none' fill-rule='evenodd'>
          <rect
            width='12'
            height='16'
            x='10'
            y='4'
            fill='#ffffff'
            class='colorFFF svgShape colorfff'
          ></rect>
          <rect
            width='12'
            height='20'
            fill='#ffffff'
            class='colorFFF svgShape colorfff'
          ></rect>
          <path
            fill='#757575'
            fill-rule='nonzero'
            d='M11,5 L11,1 L1,1 L1,19 L21,19 L21,5 M5,17 L3,17 L3,15 L5,15 M5,13 L3,13 L3,11 L5,11 M5,9 L3,9 L3,7 L5,7 M5,5 L3,5 L3,3 L5,3 M9,17 L7,17 L7,15 L9,15 M9,13 L7,13 L7,11 L9,11 M9,9 L7,9 L7,7 L9,7 M9,5 L7,5 L7,3 L9,3 M19,17 L11,17 L11,15 L13,15 L13,13 L11,13 L11,11 L13,11 L13,9 L11,9 L11,7 L19,7 M17,9 L15,9 L15,11 L17,11 M17,13 L15,13 L15,15 L17,15'
            class='color000 svgShape color919191'
          ></path>
          <g
            fill='#757575'
            class='color000 svgShape color919191'
            transform='translate(3 3)'
          >
            <rect
              width='12'
              height='16'
              x='10'
              y='4'
              fill='#ffffff'
              class='colorFFF svgShape colorfff'
            ></rect>
            <rect
              width='12'
              height='20'
              fill='#ffffff'
              class='colorFFF svgShape colorfff'
            ></rect>
            <path
              fill-rule='nonzero'
              d='M11,5 L11,1 L1,1 L1,19 L21,19 L21,5 M5,17 L3,17 L3,15 L5,15 M5,13 L3,13 L3,11 L5,11 M5,9 L3,9 L3,7 L5,7 M5,5 L3,5 L3,3 L5,3 M9,17 L7,17 L7,15 L9,15 M9,13 L7,13 L7,11 L9,11 M9,9 L7,9 L7,7 L9,7 M9,5 L7,5 L7,3 L9,3 M19,17 L11,17 L11,15 L13,15 L13,13 L11,13 L11,11 L13,11 L13,9 L11,9 L11,7 L19,7 M17,9 L15,9 L15,11 L17,11 M17,13 L15,13 L15,15 L17,15'
              class='color000 svgShape'
              fill='#757575'
            ></path>
          </g>
        </g>
      </svg>
    }
  </SvgIcon>
);

const EligibilityIcon = ({ className }) => (
  <SvgIcon className={className}>
    {
      <svg height='24px' viewBox='0 0 24 24' width='24px' fill='currentColor'>
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M20,3H4C2.9,3,2,3.9,2,5v14c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V5 C22,3.9,21.1,3,20,3z M10,17H5v-2h5V17z M10,13H5v-2h5V13z M10,9H5V7h5V9z M14.82,15L12,12.16l1.41-1.41l1.41,1.42L17.99,9 l1.42,1.42L14.82,15z' />
          </g>
        </g>
      </svg>
    }
  </SvgIcon>
);

const ThumbtackIcon = ({ className }) => (
  <SvgIcon className={className}>
    {
      <svg viewBox='0 0 384 512' width='25' fill='currentColor'>
        <path d='M32 32C32 14.33 46.33 0 64 0H320C337.7 0 352 14.33 352 32C352 49.67 337.7 64 320 64H290.5L301.9 212.2C338.6 232.1 367.5 265.4 381.4 306.9L382.4 309.9C385.6 319.6 383.1 330.4 377.1 338.7C371.9 347.1 362.3 352 352 352H32C21.71 352 12.05 347.1 6.04 338.7C.0259 330.4-1.611 319.6 1.642 309.9L2.644 306.9C16.47 265.4 45.42 232.1 82.14 212.2L93.54 64H64C46.33 64 32 49.67 32 32zM224 384V480C224 497.7 209.7 512 192 512C174.3 512 160 497.7 160 480V384H224z' />
      </svg>
    }
  </SvgIcon>
);
const EmptyIcon = ({ className }) => (
  <SvgIcon className={className}>
    {<svg height='24px' viewBox='0 0 24 24' width='24px'></svg>}{' '}
  </SvgIcon>
);

export {
  DashboardIcon,
  SearchIcon,
  PeopleIcon,
  AccountIcon,
  FiberManualRecordIcon,
  AddIcon,
  DeleteIcon,
  InsertCommentIcon,
  EventIcon,
  CloseIcon,
  SaveIcon,
  EditIcon,
  ErrorIcon,
  MoreHorizIcon,
  ArrowDropDownIcon,
  KeyboardBackspaceIcon,
  TimeIcon,
  AuthIcon,
  DenialIcon,
  UpdateIcon,
  AttachmentIcon,
  PharmIcon,
  PayerIcon,
  FilterListIcon,
  LockOutlinedIcon,
  RemoveIcon,
  PdfIcon,
  FileIcon,
  DocxIcon,
  XlsxIcon,
  CheckOutlinedIcon,
  ViewIcon,
  ProgressIcon,
  CensusIcon,
  FacilityIcon,
  ReportIcon,
  SettingsIcon,
  ExitIcon,
  NotificationIcon,
  AddAlertIcon,
  AlertIcon,
  CheckIcon,
  InfoIcon,
  DownloadIcon,
  LinkIcon,
  HelpOutlineIcon,
  FolderIcon,
  EmailIcon,
  MinimizeIcon,
  SendIcon,
  PersonIcon,
  ShareIcon,
  DeactivateFacilityIcon,
  SyncIcon,
  SyncDisabledIcon,
  LaunchIcon,
  FacilityGroupIcon,
  InboxIcon,
  EligibilityIcon,
  Visibility,
  VisibilityOff,
  NewUpdateIcon,
  UploadIcon,
  AccessTimeIcon,
  DatePickerIcon,
  ThumbtackIcon,
  EmptyIcon,
};
