import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  TextField,
  TopToolbar,
  ReferenceField,
  // Filter,
  // SelectInput,
  FunctionField,
  // NumberField,
  // DateField,
  useRefresh,
  Pagination,
} from 'react-admin';
import { Datagrid, Loading } from '../../components/common';
import { NewEvent, NotesModal } from '../../components/common/modals';
import { currencyFormat } from '../../utils';
import { useStyles } from './payers.styles';
import { AddIcon, EditIcon, CustomButton } from '../../design';
import { IconButton } from '@material-ui/core';

const ListActions = ({ record, payer_facility_id, permissions }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span />
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Rate'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
        />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='rates'
          meta={{
            line_of_businesses: record.line_of_businesses,
            part_b_excluded: record?.part_b_excluded,
          }}
          refresh={refresh}
          payerFacilityId={parseInt(payer_facility_id)}
        />
      )}
    </Fragment>
  );
};

// const FiltersView = ({ classes, ...props }) => {
//   return (
//     <Filter {...props} className={classes.filterContainer}>
//       <SelectInput
//         label='Rate Schedule'
//         source='start_date'
//         alwaysOn
//         options={{ variant: 'standard' }}
//       />
//     </Filter>
//   );
// };

export const Rates = ({
  showContract,
  record,
  permissions = [],
  id,
  hideBulkActionButtons,
  isViewAdmin,
  ...props
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [rateRecord, setRateRecord] = useState();
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState('');

  useEffect(() => {
    // see comment on case_communication
    if (rateRecord) {
      setEventModal(true);
    }
  }, [rateRecord]);

  const handleModalClose = () => {
    setEventModal(false);
    setRateRecord(undefined);
  };

  useEffect(() => {
    if (selectedNote) {
      setShowNotesModal(true);
    }
  }, [selectedNote]);
  const handleNotesModalClose = () => {
    setShowNotesModal(false);
    setSelectedNote('');
  };

  const controllerProps = useListController({
    ...props,
    resource: 'payers-facility/rates',
    filter: { id, contract: showContract },
  });
  const { loading } = controllerProps;
  return loading ? (
    <Loading />
  ) : (
    <div className='show-list'>
      <ListView
        empty={false}
        {...controllerProps}
        exporter={false}
        actions={
          Array.isArray(permissions) &&
          (permissions.indexOf('admin') > -1 ||
            permissions.indexOf('supervisor') > -1 ||
            permissions.indexOf('contract_user') > -1) ? (
            <ListActions record={record} payer_facility_id={id} />
          ) : null
        }
        bulkActionButtons={
          hideBulkActionButtons || isViewAdmin ? false : undefined
        }
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
        // filters={<FiltersView classes={classes} />}
      >
        <Datagrid>
          <ReferenceField
            source='level'
            label='Level'
            headerClassName={classes.listItemLabel}
            reference='auth-types/list'
            link={false}
            sortable={false}
          >
            <TextField source='name' />
          </ReferenceField>
          <TextField source='lines_of_business' label='Lines of business' />
          <FunctionField
            source='rev_codes'
            label='Rev codes'
            headerClassName={classes.listItemLabel}
            render={record => {
              const { rev_codes: _rev_codes } = record;
              // could be null...
              const rev_codes = _rev_codes ? _rev_codes : null;
              return Array.isArray(rev_codes) ? rev_codes.join(', ') : null;
            }}
          />
          <FunctionField
            source='rate'
            label='Reimbursement'
            headerClassName={classes.listItemLabel}
            render={record => {
              const { level, percent, rate } = record;
              return level === 10 || level === 19
                ? `${percent}%`
                : currencyFormat(rate);
            }}
          />
          {showContract && <TextField source='category' label='Category' />}
          {showContract && <TextField source='services' label='Services' />}_
          <FunctionField
            source=''
            label=''
            sortable={false}
            render={record => {
              return Array.isArray(permissions) &&
                (permissions.indexOf('admin') > -1 ||
                  permissions.indexOf('supervisor') > -1 ||
                  permissions.indexOf('contract_user') > -1) ? (
                <IconButton
                  style={{ color: '#5C738E' }}
                  onClick={() => setRateRecord(record)}
                  size='small'
                >
                  <EditIcon />
                </IconButton>
              ) : null;
            }}
          />
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={rateRecord}
          meta={{
            line_of_businesses: record.line_of_businesses,
            part_b_excluded: record.part_b_excluded,
          }}
          form='rates'
          isEdit
          refresh={refresh}
          payerFacilityId={id}
        />
      )}
      {showNotesModal && (
        <NotesModal
          open={showNotesModal}
          handleClose={handleNotesModalClose}
          note={selectedNote}
        />
      )}
    </div>
  );
};
