import { makeStyles } from '@material-ui/core';
import { styles } from '../design';
import { style } from './residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  addContainer: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: 335,
  },
  actionHeader: {
    paddingTop: 8,
  },
  actionContainer: {
    width: '100%',
    ...styles.flexRow,
    ...styles.justifyBetween,
    flexWrap: 'wrap',
    marginTop: 20,
    contentAlign: 'left',
  },
  actionButton: {
    marginTop: 5,
  },
  residentDivider: {
    color: '#829CB5',
    height: 2,
  },
  funcShowItem: {
    fontSize: 11,
  },
  commentField: {
    minWidth: '100%',
    marginBottom: 20,
  },
  attchListIcon: {
    color: '#5C738E',
  },
  showFullWidth: {
    minWidth: '100%',
    marginBottom: 20,
  },
  showMarginTop: {
    marginTop: 10,
  },
  badge: {
    width: 20,
    minWidth: 20,
    height: 20,
    fontSize: 9,
    right: 5,
    top: 3,
    backgroundColor: '#001543',
    fontWeight: 600,
    zIndex: 0,
  },
  infoContainer: {
    ...styles.flexRow,
  },
}));
