import React from 'react';
import { makeStyles, CircularProgress, Dialog } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export const Loading = ({ style }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={style}>
      <CircularProgress />
    </div>
  );
};

export const Loader = ({ open }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      PaperComponent='span'
      PaperProps={{ style: { overflow: 'hidden' } }}
      disableBackdropClick
      disableScrollLock
    >
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </Dialog>
  );
};
