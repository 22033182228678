import axios from 'axios';
import qs from 'query-string';
import { format } from 'date-fns';
import { vsprintf } from 'sprintf-js';
import { getFacilityId } from './ra-data-json-server';

const {
  /** The base URL for the API. */
  REACT_APP_API_URL,
  NODE_ENV,
} = process.env;

const IS_DEV = NODE_ENV === 'development';

/** Token to apply to each request. */
let authToken;
let authExpirationDate;

/** Id of the interceptor used to apply auth headers. */
let authInterceptorId;

/** Axios instance to use for authenticated requests. */
export const AuthRequest = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

/** Default response handler.
 * @param {AxiosAuthResponse} response */
function defaultResponseCallback(response) {
  return response;
}
/** Performs a `DELETE` with authorization.
 * @param {string | [string, any]} url
 * @param {(response:AxiosAuthResponse)=>AxiosAuthResponse} [callback]
 * @param {any} [defaultResponseData]
 * @returns {Promise<AxiosAuthResponse>} */
export async function authDelete(
  url,
  callback = defaultResponseCallback,
  defaultResponseData = [],
) {
  const nurl = normalizeURL(url);
  return AuthRequest.delete(nurl)
    .catch(normalizeResponseError('DELETE', nurl, defaultResponseData))
    .then(callback);
}
/** Performs a GET with authorization.
 * @param {string | [string, any]} url
 * @param {(response:AxiosAuthResponse)=>AxiosAuthResponse} [callback]
 * @param {any} [defaultResponseData]
 * @returns {Promise<AxiosAuthResponse>} */
export async function authGet(
  url,
  callback = defaultResponseCallback,
  defaultResponseData = [],
) {
  const nurl = normalizeURL(url);
  return AuthRequest.get(nurl)
    .catch(normalizeResponseError('GET', nurl, defaultResponseData))
    .then(callback);
}
/** Performs a GET with authorization.
 * @param {string | [string, any]} url
 * @param {(response:AxiosAuthResponse)=>AxiosAuthResponse} [callback]
 * @param {any} [defaultResponseData]
 * @returns {Promise<AxiosAuthResponse>} */
export async function authGetObject(url, callback = defaultResponseCallback) {
  return authGet(url, callback, {});
}
/** Performs a POST with authorization.
 * @param {string | [string, any]} url
 * @param {(response:AxiosAuthResponse)=>AxiosAuthResponse} [callback]
 * @param {any} [defaultResponseData]
 * @returns {Promise<AxiosAuthResponse>} */
export async function authPost(
  url,
  data,
  callback = defaultResponseCallback,
  defaultResponseData = {},
) {
  const nurl = normalizeURL(url);
  return AuthRequest.post(nurl, data)
    .catch(normalizeResponseError('POST', nurl, defaultResponseData))
    .then(callback);
}
/** Performs a PUT with authorization.
 * @param {string | [string, any]} url
 * @param {(response:AxiosAuthResponse)=>AxiosAuthResponse} [callback]
 * @param {any} [defaultResponseData]
 * @returns {Promise<AxiosAuthResponse>} */
export async function authPut(
  url,
  data,
  callback = defaultResponseCallback,
  defaultResponseData = {},
) {
  const nurl = normalizeURL(url);
  return AuthRequest.put(nurl, data)
    .catch(normalizeResponseError('PUT', nurl, defaultResponseData))
    .then(callback);
}
/**
 * @param {"GET" | "POST" | "PUT"} operation
 * @param {string} nurl
 */
export function normalizeResponseError(operation, nurl, defaultResponseData) {
  return (/** @type {import("axios").AxiosError} */ err) => {
    const response = err.response || {
      config: {},
      data: defaultResponseData,
      error: err,
      headers: {},
      status: 0,
      statusText: '',
    };
    response.error = {
      ...response.data,
    };
    response.data = defaultResponseData;
    if (IS_DEV) {
      console.warn(
        `DEFAULT DATA returned for ${operation} "${nurl}"`,
        defaultResponseData,
      );
    }
    if (response.error.replacements) {
      const normalizedReplacements = response.error.replacements.map(repl => {
        const [value, type] = Array.isArray(repl) ? repl : [repl, null];

        if (type === 'date' && new Date(value).toISOString() === value)
          return format(new Date(value), 'M/d/yyyy');
        if (type === 'datetime' && new Date(value).toISOString() === value)
          return format(new Date(value), 'Pp');
        return value;
      });

      response.error.message = vsprintf(
        response.error.message,
        normalizedReplacements,
      );
    }
    return response;
  };
}
/** @param {string | [string, object]} url */
export function normalizeURL(url) {
  const facilityId = getFacilityId();
  const facilityQuery = { _facilityId: facilityId };
  if (!Array.isArray(url)) {
    return `${url}?${qs.stringify(facilityQuery)}`;
  }
  const len = url.length;
  if (len < 2) {
    return `${url[0]}?${qs.stringify(facilityQuery)}`;
  }

  if (!url[1] || typeof url[1] !== 'object' || !Object.keys(url[1]).length) {
    return `${url[0]}?${qs.stringify(facilityQuery)}`;
  }
  return `${url[0]}?${qs.stringify({ ...url[1], ...facilityQuery })}`;
}

/** Returns true if an auth token has been set and is not expired.
 * @returns {boolean}
 */
export function hasAuthRequestToken() {
  return !!authToken && authExpirationDate > new Date();
}

/** Assigns the token to be sent with each auth request.
 * @param {string} token Server token.
 * @param {string} expiration Date and Time in ISO 8601 format.
 */
export function setAuthRequestToken(token, expiration) {
  if (arguments.length < 2) {
    throw new Error('Token and expiration required.');
  }
  removeAuthRequestToken();
  if (token) {
    authToken = token;
    authExpirationDate = new Date(expiration);
    authInterceptorId = AuthRequest.interceptors.request.use(
      applyAuthHeaders,
      // CONSIDER: An error handler can be passed. (Useful for refresh token
      // logic, to retry requests after refreshing the access token.)
      // (err) => Promise.reject(err),
    );
  }
}
/** Removes the token to be sent with each auth request. */
export function removeAuthRequestToken() {
  authToken = undefined;
  authExpirationDate = undefined;
  if (authInterceptorId !== undefined) {
    AuthRequest.interceptors.request.eject(authInterceptorId);
    authInterceptorId = undefined;
  }
}
/** @param {AxiosRequestConfig} config */
function applyAuthHeaders(config) {
  config.headers.Authorization = `Bearer ${authToken}`;
  return config;
}

// #region Typedefs

/** @typedef {import('axios').AxiosResponse} AxiosResponse */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */
/** @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig */
/** @typedef {object} AuthResponseError
 * @property {number} code
 * @property {string} message
 */
/** @typedef {AxiosResponse & {error?:AuthResponseError}} AxiosAuthResponse */
/** @typedef {object} CompatAPIResult
 * @property {boolean} success True if successful.
 * @property {object} data Data returned from server (or default data).
 * @property {boolean} loading Always `false`.
 * @property {string} [message] Error message from server.
 * @property {number} [code] Error code from server.
 */
// #endregion
