// import { authGet } from '../../server';
// import { convertToObj } from '../../utils';
export const uiTypes = {
  UPDATE_FILE_MANAGEMENT_DATA: 'UPDATE_FILE_MANAGEMENT_DATA',
  RESET_FILE_MANAGEMENT_DATA: 'RESET_FILE_MANAGEMENT_DATA',
  ADD_FILE_IDS: 'ADD_FILE_IDS',
  REMOVE_FILE_ID: 'REMOVE_FILE_ID',
};

export const fileManagementActions = {
  updateFileManagementData(data = {}) {
    return async dispatch => {
      dispatch({
        type: uiTypes.UPDATE_FILE_MANAGEMENT_DATA,
        payload: data,
      });
    };
  },
  addEmailFileIds(data = []) {
    return async dispatch => {
      dispatch({
        type: uiTypes.ADD_FILE_IDS,
        payload: data,
      });
    };
  },
  removeEmailFileId(id) {
    return async dispatch => {
      dispatch({
        type: uiTypes.REMOVE_FILE_ID,
        payload: id,
      });
    };
  },
  resetFileManagementData() {
    return async dispatch => {
      dispatch({ type: uiTypes.RESET_FILE_MANAGEMENT_DATA });
    };
  },
};
