import { Chip, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import get from 'lodash/get';
import qs from 'query-string';
import React, { Fragment, useState } from 'react';
import { TopToolbar, useRefresh } from 'react-admin';
import { Link } from 'react-router-dom';
import {
  Attachments,
  NewCommunication,
  NewEvent,
  TaskForm,
} from '../../components/common/modals';
import { AddAttachmentModal } from '../../components/common/modals/modalForms';
import {
  AlertIcon,
  AttachmentIcon,
  CloseIcon,
  EditIcon,
  AddAlertIcon,
  InsertCommentIcon,
} from '../../design';
import { CustomButton } from '../../design/material-ui';
import { getChip } from '../../utils';
import { useStyles } from '../case_timeline.styles';

export const TimelineShowActions = ({
  basePath,
  data = {},
  title,
  resource,
  closeAside,
  resourceRecord,
  eventForm,
  eventType,
  eventId,
  eventName,
  caseId,
  setRefreshTimeline,
  related_to_data = {},
  isViewAdmin,
}) => {
  const classes = useStyles();
  const {
    status: _status,
    denial_status,
    discharge_status,
    authorization_status: { status: authStatus } = {},
  } = data || {};
  const status = _status || denial_status || discharge_status || authStatus;
  return (
    <div>
      <TopToolbar className={classes.header}>
        <span className={classes.title} style={{ display: 'flex' }}>
          <span style={{ paddingRight: 20 }}>{title}</span>
          {status ? (
            <span className={classes.statusChipWrapper}>
              <Chip
                size='small'
                label={status}
                className={clsx(classes.chip, classes[getChip(status)])}
              />
            </span>
          ) : (
            <span style={{ width: 250 }}></span>
          )}
          <IconButton onClick={closeAside} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </span>
        <ActionButtons
          resourceRecord={resourceRecord}
          eventForm={eventForm}
          title={title}
          eventType={eventType}
          eventId={eventId}
          eventName={eventName}
          caseId={caseId}
          closeAside={closeAside}
          related_to_data={related_to_data}
          setRefreshTimeline={setRefreshTimeline}
          isViewAdmin={isViewAdmin}
        />
      </TopToolbar>
    </div>
  );
};
const ActionButtons = ({
  resourceRecord = {},
  eventForm,
  title,
  eventType,
  eventId,
  eventName,
  caseId,
  closeAside,
  setRefreshTimeline,
  related_to_data = {},
  isViewAdmin,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [attchOpen, setAttchModal] = useState(false);
  const [addTaskOpen, setAddTaskModal] = useState(false);
  const [communicationOpen, setCommunicationModal] = useState(false);
  const taskCount = get(resourceRecord, 'v_event_task.task_count', 0);
  const handleModalClose = () => {
    setEventModal(false);
    setRefreshTimeline(true);
  };
  const hasAttachments =
    !!resourceRecord &&
    Array.isArray(resourceRecord.document_ids) &&
    !!resourceRecord.document_ids.length;
  const documentCount = resourceRecord?.document_ids?.length;
  const canAddTasks =
    eventType === 'Auth' || eventType === 'Denial' || eventType === 'Discharge';

  return (
    <Fragment>
      <div className={classes.actionContainer}>
        <span style={{ contentAlign: 'left' }}>
          <IconButton
            className={classes.showBtn}
            onClick={() => setEventModal(true)}
            size='small'
            disabled={isViewAdmin}
          >
            <EditIcon />
          </IconButton>
          {'         '}
          {canAddTasks && (
            <IconButton
              className={classes.showBtn}
              onClick={() => setAddTaskModal(true)}
              size='small'
              disabled={isViewAdmin}
            >
              <AddAlertIcon />
            </IconButton>
          )}
          {'         '}

          {eventType !== 'mltcPayer' && (
            <CustomButton
              Icon={AttachmentIcon}
              className={classes.showBtn}
              variant='text'
              badgeContent={documentCount}
              onClick={() => setAttchModal(true)}
              size='small'
              disabled={!eventName}
              notRed
              fullSize
            />
          )}

          {'         '}
          {!canAddTasks && eventType !== 'mltcPayer' && (
            <IconButton
              onClick={() => setCommunicationModal(true)}
              size='small'
              disabled={!eventName}
              className={classes.showBtn}
            >
              <InsertCommentIcon />
            </IconButton>
          )}
        </span>
        {canAddTasks && (
          <CustomButton
            Icon={AlertIcon}
            color='#829CB5'
            label='TASKS'
            type='button'
            variant='text'
            size='small'
            className={classes.showBtn}
            badgeContent={taskCount}
            component={Link}
            disabled={!taskCount}
            to={`/cases/${resourceRecord.case_id ||
              caseId}/tasks/not-completed?${qs.stringify({
              event_type: eventType,
              event_id: eventId,
            })}`}
          />
        )}
      </div>
      {communicationOpen && (
        <NewCommunication
          open={communicationOpen}
          handleClose={() => setCommunicationModal(false)}
        />
      )}
      {addTaskOpen && (
        <TaskForm
          open={addTaskOpen}
          handleClose={() => setAddTaskModal(false)}
          caseId={resourceRecord.case_id || caseId}
          related_to_data={{ ...related_to_data }}
          refresh={refresh}
        />
      )}
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={resourceRecord}
          form={eventForm}
          isEdit
          refresh={refresh}
          closeAside={closeAside}
          caseId={caseId}
        />
      )}
      {attchOpen && (
        <Fragment>
          {hasAttachments ? (
            <Attachments
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              document_ids={resourceRecord.document_ids}
              title={title}
              eventId={
                eventName === 'CasePayer'
                  ? resourceRecord.cases_payer_id
                  : resourceRecord.id
              }
              eventName={eventName}
              caseId={parseInt(caseId)}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <AddAttachmentModal
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              title={title}
              eventId={
                eventName === 'CasePayer'
                  ? resourceRecord.cases_payer_id
                  : resourceRecord.id
              }
              eventName={eventName}
              caseId={parseInt(caseId)}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
