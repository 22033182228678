import React, {
  Fragment,
  cloneElement,
  useState,
  useEffect,
  useRef,
} from 'react';
import clsx from 'clsx';
import {
  useRefresh,
  List,
  Show,
  ShowView,
  SimpleShowLayout,
  BulkDeleteButton,
  TextField,
  FunctionField,
  EmailField,
  // NumberField,
  // ChipField,
  TopToolbar,
  // ShowGuesser,
} from 'react-admin';
import { useShowController } from '../hooks';
import {
  Drawer,
  IconButton,
  Chip,
  Divider,
  Tab,
  Tabs,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import { CustomButton } from '../design/material-ui';
import {
  getChip,
  constants,
  calculateDaysUsed,
  limitStringSize,
} from '../utils';
import { ResidentShowFields, ResidentShowActions } from '../resources';
import { useStyles } from './case_payers.styles';
import { DateField, TrackUser, Datagrid } from '../components/common';
import { SearchIcon } from '../design';
import {
  NewCommunication,
  NewEvent,
  Attachments,
  NotesModal,
  CheckEligibilityDialog,
} from '../components/common/modals';
import {
  AddAttachmentModal,
  normalizePhone,
} from '../components/common/modals/modalForms';
import { useNotify } from 'react-admin';
import { authGet } from '../server';
import {
  CloseIcon,
  AttachmentIcon,
  AddIcon,
  EditIcon,
  InsertCommentIcon,
} from '../design';
const { eventTypes } = constants;

const PayersListActions = ({ setPayerStatus, isViewAdmin }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);

  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Payers</span>
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Add'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
          disabled={isViewAdmin}
        />
        <PayerTabs setPayerStatus={setPayerStatus} classes={classes} />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='casePayer'
          refresh={refresh}
          meta={{ origin: 'add' }}
        />
      )}
    </Fragment>
  );
};

const PayerTabs = ({ setPayerStatus, classes }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPayerStatus(newValue);
  };
  return (
    <Paper className={classes.tabContainer} square>
      <Tabs
        value={value}
        indicatorColor='primary'
        textColor='inherit'
        onChange={handleChange}
        aria-label='disabled tabs example'
      >
        <Tab
          classes={{ root: classes.tab, selected: classes.selectedTab }}
          label='Active'
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.selectedTab }}
          label='Inactive'
        />
      </Tabs>
    </Paper>
  );
};

const PayersShowActions = ({
  basePath,
  data = {},
  title,
  resource,
  closeAside,
  resourceRecord,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const { is_active } = data || {};
  return (
    <div>
      <TopToolbar className={classes.header}>
        <div className={classes.headerRow}>
          <span className={classes.title}>{title}</span>
          <div className={classes.statusChipWrapper}>
            <Chip
              size='small'
              label={is_active ? 'Active' : 'Inactive'}
              className={clsx(
                classes.chip,
                classes[getChip(is_active ? 'active' : 'inactive')],
              )}
            />
          </div>
          <IconButton onClick={closeAside} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <ActionButtons
          resourceRecord={resourceRecord}
          isViewAdmin={isViewAdmin}
        />
      </TopToolbar>
    </div>
  );
};

const ActionButtons = ({ resourceRecord, isViewAdmin }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [communicationOpen, setCommunicationModal] = useState(false);
  const [eventOpen, setEventModal] = useState(false);
  const [attchOpen, setAttchModal] = useState(false);
  const hasAttachments =
    !!resourceRecord &&
    Array.isArray(resourceRecord.document_ids) &&
    !!resourceRecord.document_ids.length;
  const documentCount = resourceRecord?.document_ids?.length;

  return (
    <Fragment>
      <div>
        <IconButton
          className={classes.showBtn}
          onClick={() => setEventModal(true)}
          size='small'
          disabled={isViewAdmin}
        >
          <EditIcon />
        </IconButton>
        {'         '}
        <IconButton
          className={classes.showBtn}
          onClick={() => setCommunicationModal(true)}
          size='small'
        >
          <InsertCommentIcon />
        </IconButton>
        {'         '}

        <CustomButton
          Icon={AttachmentIcon}
          className={classes.showBtn}
          variant='text'
          badgeContent={documentCount}
          onClick={() => setAttchModal(true)}
          notRed
          fullSize
        />
      </div>
      {communicationOpen && (
        <NewCommunication
          open={communicationOpen}
          handleClose={() => setCommunicationModal(false)}
        />
      )}
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          record={resourceRecord}
          form='casePayer'
          isEdit
          refresh={refresh}
          meta={{ origin: 'edit' }}
        />
      )}
      {attchOpen && (
        <Fragment>
          {hasAttachments ? (
            <Attachments
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              document_ids={resourceRecord.document_ids}
              title='Case Payers'
              eventId={resourceRecord.id}
              eventName={eventTypes.CASE_PAYER_NAME}
              caseId={resourceRecord.case_id}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <AddAttachmentModal
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              title='Case Payers'
              eventId={resourceRecord.id}
              eventName={eventTypes.CASE_PAYER_NAME}
              caseId={resourceRecord.case_id}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const BulkActionButtons = ({ toggleAside, recordOpenId, ...props }) => {
  const { selectedIds = [] } = props;
  function onClick() {
    if (
      typeof toggleAside === 'function' &&
      selectedIds.indexOf(recordOpenId) > -1
    ) {
      toggleAside(false);
    }
  }
  return <BulkDeleteButton {...props} onClick={onClick} />;
};

export const CasePayersList = ({ staticContext, ..._props }) => {
  const refresh = useRefresh();
  const props = getResourceProps(_props);
  const classes = useStyles();
  const [asideOpen, toggleAside] = useState(false);
  const [rowRecord, setRowRecord] = useState({});
  const [payerStatus, setPayerStatus] = useState(0);
  const [eligibilityOpen, setCheckEligibility] = useState(false);
  const [html, setHtml] = useState('');
  const notify = useNotify();
  const firstRender = useRef(true);
  useEffect(() => {
    toggleAside(false);
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    refresh();
  }, [props.location.pathname, refresh]);

  const checkEligibility = async record => {
    setCheckEligibility(true);
    const response = await authGet([
      '/call-seek-eligibility',
      {
        insurance: record.payer_id,
        residents_insurance_id: record.residents_insurance_id,
        resident_id: record.resident_id,
      },
    ]);

    if (response.error) {
      setHtml(response.error.message, 'warning');
    } else {
      const { fields, html } = response.data;
      if (fields) {
        setHtml(html);
        notify('Seek eligibility information saved.');
      } else
        setHtml(
          `<p>Oops, something wrong with your request:</p><p> ${response.data.message}</p>`,
        );
    }
  };

  const setStatus = status => {
    toggleAside(false);
    setPayerStatus(status);
  };

  const rowClick = (id, basePath, record) => {
    setRowRecord({
      ...record,
    });
    toggleAside(true);
    return null;
  };

  const listRowStyle = (record, index, activeId) => {
    if (record && record.id === activeId && asideOpen) {
      return { backgroundColor: '#EFF4FB' };
    }
  };
  const { permissions = '' } = props;
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  const isAdminOrSupervisor =
    userPermissions?.indexOf('admin') > -1 ||
    userPermissions?.indexOf('supervisor') > -1;

  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        bulkActionButtons={
          Array.isArray(userPermissions) && isAdminOrSupervisor ? (
            <BulkActionButtons
              toggleAside={toggleAside}
              recordOpenId={rowRecord.id}
            />
          ) : (
            false
          )
        }
        className={clsx(classes.list, {
          [classes.listWithDrawer]: asideOpen,
        })}
        actions={
          <PayersListActions
            setPayerStatus={setStatus}
            isViewAdmin={isViewAdmin}
          />
        }
        sort={{ field: 'is_primary', order: 'DESC' }}
        filter={{ is_active: payerStatus === 0 }}
      >
        <Datagrid
          rowClick={rowClick}
          rowStyle={(record, index) =>
            listRowStyle(record, index, rowRecord.id)
          }
        >
          <TextField source='payers_name' label='Insurance' />
          <FunctionField
            label='Status'
            source='is_active'
            render={record => (
              <Chip
                size='small'
                label={record.is_active ? 'Active' : 'Inactive'}
                className={clsx(
                  classes.chip,
                  classes[getChip(record.is_active ? 'active' : 'inactive')],
                )}
              />
            )}
          />
          <FunctionField
            label='Priority'
            source='is_primary'
            render={record =>
              payerStatus === 0
                ? record.is_primary
                  ? 'Primary'
                  : `Secondary ${
                      record.no_auths_required
                        ? record.no_auths_required && '- auths stopped'
                        : ``
                    }`
                : ''
            }
          />
          <TextField source='payer_type' label='Type (LOB)' />
          <TextField source='residents_insurance_id' label='Member ID' />
          <FunctionField
            label=''
            source=''
            render={record => {
              const { seek_payer_code } = record;

              return (
                seek_payer_code && (
                  <Button
                    styles={{ itemAlign: 'center' }}
                    onClick={e => {
                      e.stopPropagation();
                      checkEligibility(record);
                    }}
                  >
                    <SearchIcon
                      className={clsx(classes.leftIcon, classes.icon)}
                    />
                    Check Eligibility
                  </Button>
                )
              );
            }}
          />

          {eligibilityOpen && (
            <CheckEligibilityDialog
              open={eligibilityOpen}
              handleClose={e => {
                e.stopPropagation();
                setCheckEligibility(false);
                setHtml('');
              }}
              html={html}
              refresh={refresh}
            />
          )}
        </Datagrid>
      </List>
      <Drawer
        variant='persistent'
        open={asideOpen}
        anchor='right'
        elevation={16}
        PaperProps={{ square: true }}
        classes={{
          paperAnchorDockedRight: clsx(classes.aside),
        }}
      >
        {asideOpen
          ? cloneElement(<CasePayersShow />, {
              ...props,
              id: rowRecord.id,
              closeAside: () => toggleAside(false),
              resource: 'case-payers',
              classes: classes,
              residentId: rowRecord.resident_id,
              isViewAdmin: isViewAdmin,
            })
          : null}
      </Drawer>
    </Fragment>
  );
};

export const CasePayersShow = ({
  closeAside,
  classes,
  residentId,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const maxNotesLength = 160;
  const showProps = useShowController({ ...props });
  const { record } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <PayersShowActions
            closeAside={closeAside}
            title='Payer'
            resourceRecord={record}
            data={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <TextField
            source='payers_name'
            label='Insurance'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='is_primary'
            label='Priority'
            className={classes.showItem}
            render={record => (record.is_primary ? 'Primary' : 'Secondary')}
          />
          <DateField
            source='active_date'
            className={classes.showItem}
            label='Active date'
            textAlign='right'
          />
          <DateField
            source='inactive_date'
            className={classes.showItem}
            label='Inactive date'
            textAlign='right'
          />
          <TextField
            source='payer_type'
            label='Type (LOB)'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='residents_insurance_id'
            label='Member ID'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='days_of_coverage'
            label='Total benefit'
            textAlign='right'
            className={classes.showItem}
            render={record =>
              record.days_of_coverage ? `${record.days_of_coverage} days` : null
            }
          />
          <FunctionField
            source='days_used'
            label='Days used'
            textAlign='right'
            className={classes.showItem}
            render={record => calculateDaysUsed(record)}
          />
          <FunctionField
            source='days_of_coverage'
            label='Days remaining'
            textAlign='right'
            className={classes.showItem}
            render={record => {
              const daysUsed = calculateDaysUsed(record);
              const daysRemaining =
                parseInt(record.days_of_coverage) - daysUsed;
              return daysRemaining > 0 ? daysRemaining : 0;
            }}
          />
          {record.is_primary && (
            <TextField
              source='copay_info'
              label='Copay info'
              textAlign='right'
              className={clsx(classes.showItem, classes.showFullWidth)}
            />
          )}
          {!record.is_primary && (
            <TextField
              source='copay_start_day'
              label='Copay starts on day'
              textAlign='right'
              className={classes.showItem}
            />
          )}
          {!record.is_primary && (
            <DateField
              source='copay_start_date'
              label='Copay start date'
              textAlign='right'
              className={classes.showItem}
            />
          )}
          {!record.is_primary && (
            <DateField
              source='copay_end_date'
              label='Copay end date'
              textAlign='right'
              className={classes.showItem}
            />
          )}
          <TextField
            source='rep_name'
            label='Insurance case manager name'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='phone_full'
            label='Insurance case manager phone'
            className={classes.showItem}
            textAlign='right'
          />
          <FunctionField
            source='rep_fax'
            label='Insurance case manager fax'
            className={classes.showItem}
            textAlign='right'
            render={record => normalizePhone(record.rep_fax)}
          />
          <EmailField
            source='rep_email'
            label='Insurance case manager email'
            textAlign='right'
            className={clsx(classes.showItem, classes.showFullWidth)}
          />
          <FunctionField
            source='comments'
            label='Insurance case manager notes'
            textAlign='right'
            className={clsx(classes.showItem, classes.showFullWidth)}
            style={{ marginTop: 10 }}
            render={record => {
              const { comments } = record;
              const truncated = limitStringSize(comments, maxNotesLength, true);
              return comments && comments.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setShowNotesModal(true)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          <FunctionField
            source='vCasesPayerCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { vCasesPayerCreatedBy, created_at } = record;
              return (
                <TrackUser
                  userField={vCasesPayerCreatedBy}
                  timestamp={created_at}
                />
              );
            }}
          />
          <FunctionField
            source='vCasesPayerUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { vCasesPayerUpdatedBy, updated_at } = record;
              return (
                <TrackUser
                  userField={vCasesPayerUpdatedBy}
                  timestamp={updated_at}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {showNotesModal && (
            <NotesModal
              open={showNotesModal}
              handleClose={() => setShowNotesModal(false)}
              note={record.comments}
            />
          )}
        </SimpleShowLayout>
      </ShowView>
      <Divider className={classes.residentDivider} />
      <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
        Resident info
      </p>
      <Show
        actions={
          <ResidentShowActions
            closeAside={closeAside}
            isResidentPage={false}
            isViewAdmin={isViewAdmin}
          />
        }
        {...rest}
        resource='residents'
        id={residentId}
      >
        <ResidentShowFields classes={classes} includeCases />
      </Show>
    </div>
  );
};

export const casePayersResource = {
  name: 'case-payers',
  list: CasePayersList,
  // show: CaseTimelineShow,
};

function getResourceProps(props) {
  const { location: { pathname } = {} } = props;
  const basePath = pathname || '/cases';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-payers',
        hasList: true,
        hasEdit: !!casePayersResource.edit,
        hasShow: !!casePayersResource.show,
        hasCreate: !!casePayersResource.create,
      };
  return { ...props, basePath, ...resource };
}
