import React, { useEffect, useState } from 'react';

import jwtDecode from 'jwt-decode';
import { Admin } from 'react-admin';
import { Provider, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createHashHistory } from 'history';
import { create } from 'jss';
import { StylesProvider, jssPreset, ThemeProvider } from '@material-ui/styles';
import { Snackbar, Slide, IconButton } from '@material-ui/core';
import { io } from 'socket.io-client';
import { theme, CloseIcon } from './design';
import { dataProvider, authProvider, setAuthRequestToken } from './server';
import createAdminStore from './createAdminStore';
import i18nProvider from './i18n';
import { userActions, listActions } from './state/actions';
import './assets/css/styles.scss';
import { Layout } from './components/layout';
import { resources } from './resources';
import { customRoutes, Login } from './pages';
import { ErrorSentry } from './components/common/ErrorSentry';
import { DashboardHome } from './pages/dashboard/dashboard-home';

import HandleTokenRefresh from './refresh-token';
let socketIo;
const history = createHashHistory();
const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  // see https://material-ui.com/styles/advanced/#css-injection-order
  insertionPoint: document.getElementById('jss-insertion-point'),
});

function App() {
  const [caseDash, setCaseDash] = useState(false);
  useEffect(() => {}, [caseDash]);
  return (
    <ErrorSentry>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <Provider
            store={createAdminStore({
              dataProvider,
              authProvider,
              history,
              i18nProvider,
            })}
          >
            <Admin
              dataProvider={dataProvider}
              history={history}
              authProvider={authProvider}
              layout={Layout}
              theme={theme}
              loginPage={Login}
              customRoutes={customRoutes}
              i18nProvider={i18nProvider}
              dashboard={caseDash ? DashboardHome : undefined}
            >
              {resources}
              <HandleTokenRefresh />
            </Admin>
            <GetUserProfile setCaseDash={setCaseDash} />
          </Provider>
        </ThemeProvider>
      </StylesProvider>
    </ErrorSentry>
  );
}

export default App;

const GetUserProfile = ({ setCaseDash }) => {
  const profile = useSelector(state => state.user.profile, shallowEqual);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    if (profile.id) {
      ErrorSentry.setUser({
        id: profile.id,
        username: profile.name,
        email: profile.email,
      });
      if (!socketIo && profile.id) {
        const userId = profile.id;
        socketIo = io('/', {
          transports: ['websocket'],
          pingTimeout: '25000',
          pingInterval: '25000',
          query: {
            userId: userId,
          },
        });
      }
      if (
        !!profile.roles.filter(row => row.role === 'admin').length ||
        !!profile.roles.filter(row => row.role === 'supervisor').length ||
        !!profile.roles.filter(row => row.role === 'view_admin').length ||
        !!profile.roles.filter(row => row.role === 'admin_assistant').length
      ) {
        setCaseDash(false);
      } else {
        setCaseDash(true);
      }
    }
  }, [profile.email, profile.id, profile.name, profile.roles, setCaseDash]);

  if (socketIo) {
    socketIo.on('fax-received', data => {
      setOpenSnackBar(true);
      setMessage(data);
    });
  }

  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  if (token && !profile.id) {
    const { exp } = jwtDecode(token);
    setAuthRequestToken(token, exp);
    localStorage.setItem('jwtExpiry', exp);
    dispatch(userActions.getUserProfile());
    dispatch(listActions.getStaticLists());
  }
  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  return openSnackBar ? (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={openSnackBar}
      autoHideDuration={15000}
      onClose={() => setOpenSnackBar(false)}
      message={message}
      TransitionComponent={props => <Slide {...props} direction='left' />}
      action={
        <IconButton
          size='small'
          aria-label='close'
          color='inherit'
          onClick={handleClose}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      }
    />
  ) : null;
};
