import React, { useState, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent, FormGroup } from '@material-ui/core';
import { authGet } from '../../../../server';
import {
  ReferenceInput,
  FormTextField,
  SelectComponent,
  CheckboxInput,
  FileInput,
  RadioInput,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  validateDate,
  SwitchInput,
} from '../modalForms';
import { AutocompleteInput } from '../modalForms/inputs';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { NpiToggle } from '../../';
import { constants, ensureUrlProtocol } from '../../../../utils';
const { states, linesOfBusiness } = constants;

export const FacilityPayerForm = ({ record = {}, isEdit, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    line_of_businesses: [],
    admit_notice_required: false,
    is_active: true,
    // by_navihealth: false,
  });
  const [payerFields, setPayerFields] = useState({});
  const [altNpiFields, setAltNpiFields] = useState({});
  const [isFacilityNpi, setIsFacilityNpi] = useState(true);
  useEffect(() => {
    if (isEdit && record) {
      const { Payers, altNpi, ...rest } = sanitizeRecord(record);
      setIsFacilityNpi(record.alt_npi === null);
      setPayerFields({ ...Payers });
      setAltNpiFields({ ...altNpi });
      setFormData(f => ({
        ...f,
        ...rest,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    const {
      payer_id: _payer_id,
      in_network: _in_network,
      alt_npi_id: _alt_npi_id,
      url: _url,
      part_b_excluded: _part_b_excluded,
      line_of_businesses: _line_of_businesses,
    } = value;
    if (
      !!_part_b_excluded &&
      _line_of_businesses.includes(linesOfBusiness.PART_B)
    ) {
      notify(
        `Cannot include Part B as a line of business when 'No Part B' is selected.`,
      );
      return;
    }
    const payer_id = _payer_id ? parseInt(_payer_id) : 0;
    const in_network =
      _in_network && !isNaN(_in_network) ? parseInt(_in_network) : _in_network;
    const alt_npi_id = isFacilityNpi ? null : parseInt(_alt_npi_id);
    if (isEdit) {
      return dataProvider
        .update('payers-facility', {
          id: record.id,
          data: {
            ...value,
            payer_id,
            in_network,
            alt_npi_id,
            url: ensureUrlProtocol(_url),
            part_b_excluded: !!value.part_b_excluded,
          },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('payers-facility', {
          data: {
            ...value,
            payer_id,
            in_network,
            alt_npi_id,
            url: ensureUrlProtocol(_url),
            part_b_excluded: !!_part_b_excluded,
          },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    if (name === 'payer_id') {
      if (value !== 0 && value !== null) {
        const data = await fetchResource('payers', value);
        if (data !== 'error') {
          setPayerFields({ [name]: value, ...data });
        }
      } else {
        setPayerFields({ [name]: value });
      }
      return;
    }
    if (name === 'alt_npi_id') {
      if (value !== 0) {
        const data = await fetchResource('alt-npi', value);
        if (data !== 'error') {
          const sanitizedFields = sanitizeAltNpi(data);
          setAltNpiFields({ [name]: value, ...sanitizedFields });
        }
      } else {
        setAltNpiFields({ [name]: value });
      }
      return;
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
          ...payerFields,
          ...altNpiFields,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {!isEdit && (
                  <AutocompleteInput
                    reference='payers/list'
                    customOnChange={customOnChange}
                    name='payer_id'
                    label='Select a payer'
                    fullWidth
                    viewAll
                    openOnFocus
                    autocompleteProps={{ openOnFocus: true }}
                  />
                )}
                {isEdit && (
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      name='name'
                      validate={required()}
                      required
                      label='Payer'
                      customOnChange={customOnChange}
                      disabled
                      fullWidth
                      style={{ marginRight: 0 }}
                    />
                  </div>
                )}
                <div className={clsx(classes.inputContainerWrap)}>
                  <CheckboxInput
                    name='part_b_excluded'
                    customOnChange={customOnChange}
                    label='Part B excluded'
                    checked={formData.part_b_excluded}
                  />
                </div>
                <ReferenceInput
                  reference='lob/list'
                  key={values.payer_id}
                  customOnChange={customOnChange}
                  name='line_of_businesses'
                  label='Lines of business'
                  validate={required()}
                  required
                  multiple
                  renderWith='chip'
                  fullWidth
                  options={{ filter: { global_payer_id: values.payer_id } }}
                />
                <SwitchInput
                  checked={formData.is_active}
                  name='is_active'
                  label={formData.is_active ? 'Active' : 'Inactive'}
                  customOnChange={customOnChange}
                />
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='effective_date'
                    validate={composeValidators([required(), validateDate()])}
                    required
                    customOnChange={customOnChange}
                    type='date'
                    label='Effective date'
                  />
                  <FormTextField
                    name='end_date'
                    customOnChange={customOnChange}
                    validate={composeValidators([validateDate()])}
                    type='date'
                    label='Recredential date'
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='pin'
                    label='Provider ID number (PIN)'
                    customOnChange={customOnChange}
                  />
                  <FormTextField
                    name='group_provider'
                    label='Group provider'
                    customOnChange={customOnChange}
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='provider_relations_contact'
                    label='Provider relations contact'
                    customOnChange={customOnChange}
                    style={{ marginRight: 0 }}
                  />
                </div>

                <div style={{ marginBottom: 10 }}>
                  <NpiToggle
                    isFacilityNpi={isFacilityNpi}
                    setIsFacilityNpi={setIsFacilityNpi}
                  />
                </div>
                {!isFacilityNpi && (
                  <Fragment>
                    <ReferenceInput
                      reference='alt-npi/list'
                      customOnChange={customOnChange}
                      name='alt_npi_id'
                      label='Select an alt NPI'
                      fullWidth
                      resetOption={{ id: 0, name: '+Add new' }}
                    />
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='alt_npi_name'
                        validate={required()}
                        required
                        label='Company name'
                        customOnChange={customOnChange}
                        disabled={values.alt_npi_id > 0}
                        style={{ marginRight: 10 }}
                      />
                      <FormTextField
                        name='alt_npi_npi'
                        validate={required()}
                        required
                        label='NPI #'
                        customOnChange={customOnChange}
                        disabled={values.alt_npi_id > 0}
                        style={{ marginRight: 10 }}
                      />
                      <FormTextField
                        name='alt_npi_tax_id'
                        validate={required()}
                        required
                        label='Tax #'
                        customOnChange={customOnChange}
                        disabled={values.alt_npi_id > 0}
                        style={{ marginRight: 0 }}
                      />
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='alt_npi_address'
                        validate={required()}
                        required
                        label='Address'
                        fullWidth
                        customOnChange={customOnChange}
                        disabled={values.alt_npi_id > 0}
                        style={{ marginRight: 0 }}
                      />
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='alt_npi_city'
                        validate={required()}
                        required
                        label='City'
                        customOnChange={customOnChange}
                        disabled={values.alt_npi_id > 0}
                        style={{ marginRight: 10 }}
                      />
                      <SelectComponent
                        customOnChange={customOnChange}
                        label='State'
                        validate={required()}
                        required
                        name='alt_npi_state'
                        choices={states}
                        disabled={values.alt_npi_id > 0}
                        style={{ marginRight: 10 }}
                      />
                      <FormTextField
                        name='alt_npi_zip'
                        validate={required()}
                        required
                        label='Zip'
                        customOnChange={customOnChange}
                        disabled={values.alt_npi_id > 0}
                        style={{ marginRight: 0 }}
                      />
                    </div>
                  </Fragment>
                )}
                <FormGroup row>
                  <CheckboxInput
                    name='admit_notice_required'
                    customOnChange={customOnChange}
                    label=' Admission notification required'
                    checked={values.admit_notice_required}
                  />
                </FormGroup>

                <RadioInput
                  name='in_network'
                  className={classes.radio}
                  customOnChange={customOnChange}
                  required
                  validate={required()}
                  value={parseInt(formData['in_network'])}
                  label='Network'
                  row
                  radios={[
                    { label: 'In network', value: 1 },
                    { label: 'Out of network', value: 0 },
                  ]}
                />
                {!isEdit && (
                  <div className={classes.insuranceTitle}>
                    Attachments
                    <FileInput
                      title='title'
                      name='attachments'
                      setFormData={setFormData}
                      formData={formData}
                      classes={{ dropZone: classes.dropZone }}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

function sanitizeRecord(record) {
  const {
    id,
    alt_npi,
    created_at,
    deleted_at,
    facility_id,
    updated_at,
    rates,
    line_of_businesses: _line_of_businesses,
    updated_by,
    created_by,
    payersFacilityCreatedBy,
    payersFacilityUpdatedBy,
    is_active,
    ...rest
  } = record;
  const rate = Array.isArray(rates) && rates.length ? rates[0].rate : null;
  const line_of_businesses = Array.isArray(_line_of_businesses)
    ? _line_of_businesses.map(lob => lob.id)
    : [];

  const altNpi = {};
  if (alt_npi) {
    altNpi.alt_npi_id = alt_npi.id;
    altNpi.alt_npi_address = alt_npi.address;
    altNpi.alt_npi_city = alt_npi.city;
    altNpi.alt_npi_name = alt_npi.name;
    altNpi.alt_npi_state = alt_npi.state;
    altNpi.alt_npi_tax_id = alt_npi.tax_id;
    altNpi.alt_npi_zip = alt_npi.zip;
    altNpi.alt_npi_npi = alt_npi.npi;
  }
  return {
    ...rest,
    is_active: is_active === null ? true : !!is_active,
    line_of_businesses,
    altNpi,
    rate,
  };
}

function sanitizeAltNpi(record) {
  return {
    alt_npi_address: record.address,
    alt_npi_city: record.city,
    alt_npi_name: record.name,
    alt_npi_state: record.state,
    alt_npi_tax_id: record.tax_id,
    alt_npi_zip: record.zip,
    alt_npi_npi: record.npi,
  };
}

async function fetchResource(resource, value) {
  const response = await authGet(`/${resource}/${value}`);
  if (response.error) return 'error';
  return response.data;
}
