import React, { useState, useEffect, cloneElement, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import {
  // useQueryWithStore,
  useDataProvider,
  useNotify,
  useUnselectAll,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  DialogActions,
  DialogContentText,
  Button,
} from '@material-ui/core';
import { AddAttachmentForm, AddAttachment } from './modalForms/AddAttachment';
import { authGet } from '../../../server';
import { getAttchIcon } from '../../../utils';
import { fileManagementActions } from '../../../state/actions';
import { Loading } from '../Loading';
import { useStyles } from './attachments.styles';
import * as design from '../../../design';
const {
  CloseIcon,
  ViewIcon,
  DeleteIcon,
  CustomButton,
  AttachmentIcon,
} = design;

export const Attachments = ({
  document_ids,
  handleClose,
  title,
  open,
  eventId,
  eventName,
  caseId,
  refresh,
  add = true,
  actionMessage,
  getAllCaseFiles,
  attach,
  isViewAdmin,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    attachments: [],
  });

  function fetchByEvent() {
    dataProvider
      .getList('documents', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'created_at', order: 'DESC' },
        filter: { document_ids },
      })
      .then(({ data }) => {
        setData(data);
      })
      .catch(error => {
        setError(true);
      });
    setLoading(false);
  }

  function fetchCaseDocuments() {
    authGet(`/cases/${caseId}/case-documents`).then(response => {
      const { error, data } = response;
      if (error) {
        setError(true);
      } else {
        setData(data);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    if (getAllCaseFiles) {
      fetchCaseDocuments();
    } else {
      fetchByEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;
  if (error) {
    handleClose();
    notify('An error occurred', 'warning');
    return null;
  }
  if (!data) return null;
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelectedIds = data.map(n => n.id);
      setSelected(newSelectedIds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleViewClick = async document_id => {
    const response = await authGet(`/documents/url/${document_id}`);
    if (response.error) {
      notify('ra.notification.http_error', 'warning');
    }
    const win = window.open(response.data.url, '_blank');
    win.focus();
  };

  const handleAttach = () => {
    dispatch(fileManagementActions.addEmailFileIds(selected));
    handleClose();
  };

  const isSelected = id => selected.indexOf(id) !== -1;
  const selectedLength = selected.length;
  const rowsLength = data.length;
  return (
    <Dialog open={open} onClose={handleClose} fullWidth disableScrollLock>
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id='form-dialog-title' align='left' disableTypography>
        {title}
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow classes={{ root: classes.tableRow }}>
              <TableCell padding='checkbox'>
                <Checkbox
                  indeterminate={
                    selectedLength > 0 && selectedLength < rowsLength
                  }
                  checked={selectedLength === rowsLength}
                  onChange={handleSelectAllClick}
                  disabled={isViewAdmin}
                />
              </TableCell>
              <TableCell padding='none'></TableCell>
              <TableCell padding='none'>Name</TableCell>
              <TableCell padding='none'>Upload Date</TableCell>
              <TableCell padding='none'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(att => {
              const {
                // url,
                id,
                document_name,
                document_type,
                // document_id,
                // file_name,
                created_at,
              } = att;
              const isItemSelected = isSelected(id);
              const IconComponent = design[getAttchIcon(document_type)];
              return (
                <TableRow
                  key={id}
                  classes={{ root: classes.tableRow }}
                  hover
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
                >
                  <TableCell
                    padding='checkbox'
                    onClick={event => handleClick(event, id)}
                    role='checkbox'
                  >
                    <Checkbox checked={isItemSelected} />
                  </TableCell>
                  <TableCell padding='none'>
                    {cloneElement(
                      <IconComponent className={classes.fileIcons} />,
                    )}
                  </TableCell>
                  <TableCell padding='none'>{document_name}</TableCell>
                  <TableCell padding='none'>{created_at}</TableCell>
                  <TableCell align='right' padding='none'>
                    <IconButton onClick={() => handleViewClick(id)}>
                      <ViewIcon className={classes.viewIcon} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {add && (
          <AddAttachmentForm
            formData={formData}
            setFormData={setFormData}
            isViewAdmin={isViewAdmin}
          />
        )}
        {!!selected.length && actionMessage && (
          <DialogContentText
            color={actionMessage.type === 'warning' ? 'error' : 'textPrimary'}
            variant='subtitle2'
          >
            {actionMessage.msg}
          </DialogContentText>
        )}
        <DialogActions>
          {!!selected.length && (
            <Fragment>
              {(!attach || !formData.attachments?.length) && (
                <Button
                  startIcon={<AttachmentIcon />}
                  className={classes.emailLogBtn}
                  size='small'
                  onClick={handleAttach}
                  disabled={isViewAdmin}
                >
                  Attach
                </Button>
              )}
              <DeleteAttachments
                selectedIds={selected}
                handleClose={handleClose}
                refresh={refresh}
                isViewAdmin={isViewAdmin}
              />
            </Fragment>
          )}
          {!!formData.attachments.length && (
            <AddAttachment
              data={formData}
              eventId={eventId}
              eventName={eventName}
              caseId={caseId && +caseId}
              handleClose={handleClose}
              refresh={refresh}
              saveAndAttach={attach}
              selected={selected || []}
              isViewAdmin={isViewAdmin}
            />
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const DeleteAttachments = ({
  selectedIds,
  onClick,
  handleClose,
  refresh,
  isViewAdmin,
}) => {
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  function deleteMany() {
    setLoading(true);
    dataProvider.deleteMany(
      'documents',
      {
        ids: selectedIds,
      },
      {
        onSuccess: () => {
          setLoading(false);
          notify(
            'ra.notification.deleted',
            'info',
            { smart_count: selectedIds.length },
            true,
          );
          unselectAll('documents');
          handleClose();
          typeof refresh === 'function' && refresh();
        },
        onFailure: error => {
          setLoading(false);
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          );
        },
        undoable: true,
      },
    );
  }

  const handleClick = () => {
    deleteMany();
    if (typeof onClick === 'function') {
      onClick();
    }
  };
  if (loading) return <Loading />;

  return (
    <CustomButton
      Icon={DeleteIcon}
      color='rgb(220, 0, 78)'
      label='DELETE'
      type='button'
      variant='text'
      size='small'
      onClick={handleClick}
      disabled={loading || isViewAdmin}
    />
  );
};
