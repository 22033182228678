import React, { useState, useEffect, useCallback, Fragment } from 'react';
import clsx from 'clsx';
import qs from 'query-string';
import {
  List,
  TopToolbar,
  // NumberField,
  ChipField,
  FunctionField,
  useRefresh,
} from 'react-admin';
import { useStyles } from './cases.styles';
import {
  ToggleButton,
  DateField,
  Datagrid,
  FilterMenu,
  FilterChips,
} from '../components/common';
import { NewEvent } from '../components/common/modals';
import { getChip } from '../utils';
import { useGetUserDivision } from '../hooks';
import { AccountIcon, AddIcon, CustomButton } from '../design';

const CasesListActions = ({
  initialDivision,
  setDivision,
  filterActions,
  activeStatusFilters = {},
  loading,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex', paddingBottom: 20 }}>
            <span className={classes.title}>Cases</span>
            {/* Needed for spacing */}
            <span style={{ marginRight: 25 }} />
            <ToggleButton
              setDivision={setDivision}
              className={classes.toggleWrapper}
            />
          </div>
          <CustomButton
            Icon={AddIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Add'
            type='button'
            variant='text'
            size='small'
            onClick={() => setEventModal(true)}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <FilterMenu filterActions={filterActions} filters={filters} />
          <FilterChips
            activeFilters={activeStatusFilters}
            filterActions={filterActions}
            disabled={loading}
          />
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='admission'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const rowClick = (id, basePath, record) => {
  return `/cases/${id}/timeline`;
};

export const CasesList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const { location: { search } = {} } = props;
  const { status } = qs.parse(search);
  const classes = useStyles();
  const userDivision = useGetUserDivision();
  const [activeStatusFilters, setActiveStatusFilters] = useState();
  const [division, setDivision] = useState(status ? 'all' : userDivision);
  const { permissions = '' } = props;
  const userPermissions = permissions.split(',');

  const isAdminOrSupervisor =
    userPermissions?.indexOf('admin') > -1 ||
    userPermissions?.indexOf('supervisor') > -1;

  useEffect(() => {
    if (status) {
      const filter = filters.filter(f => f.id === status);
      setActiveStatusFilters({ [status]: filter[0].name });
    } else {
      setActiveStatusFilters(currentFilters => {
        return typeof currentFilters === 'object'
          ? currentFilters
          : { active: 'Active' };
      });
    }
  }, [status]);

  const filterActions = useCallback(({ actionType, id, name }) => {
    if (actionType === 'removeFilter') {
      setActiveStatusFilters(currentFilters => {
        const { [id]: filterToRemove, ...otherFilters } = currentFilters;
        return otherFilters;
      });
    } else if (actionType === 'addFilter') {
      setActiveStatusFilters(currentFilters => ({
        ...currentFilters,
        [id]: name,
      }));
    }
  }, []);

  if (!activeStatusFilters) return null;

  return (
    <List
      empty={false}
      {...props}
      bulkActionButtons={
        Array.isArray(userPermissions) && isAdminOrSupervisor
          ? undefined // will show default delete
          : false // disable delete
      }
      sort={{ field: 'resident_status', order: 'ASC' }}
      actions={
        <CasesListActions
          initialDivision={status ? 'all' : userDivision}
          setDivision={setDivision}
          filterActions={filterActions}
          activeStatusFilters={activeStatusFilters}
        />
      }
      filter={{
        division,
        filtered: Object.keys(activeStatusFilters),
      }}
    >
      <Datagrid rowClick={rowClick}>
        <FunctionField
          label='Resident'
          source='full_name'
          sortBy='last_name'
          render={record => `${record.full_name}-${record.admit_date}`}
        />
        <FunctionField
          label='Case status'
          source='case_status'
          render={record => (
            <ChipField
              record={record}
              source='case_status'
              className={clsx(
                classes.chip,
                classes[getChip(record.case_status)],
              )}
            />
          )}
        />
        {/* <TextField
          source='length_of_stay'
          label='Length of stay'
          sortable={false}
        /> */}
        <DateField source='admit_date' label='Admitted' />
        <DateField source='discharge_date' label='Discharge date' />
      </Datagrid>
    </List>
  );
};

export const casesResource = {
  name: 'cases',
  list: CasesList,
  icon: AccountIcon,
  options: { label: 'Cases' },
};

function getResourceProps(props) {
  const basePath = props.basePath || '/cases';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'cases',
        options: casesResource.options,
        hasList: true,
        hasEdit: !!casesResource.edit,
        hasShow: !!casesResource.show,
        hasCreate: !!casesResource.create,
      };
  return { ...props, basePath, ...resource };
}

const filters = [
  { id: 'active', name: 'Active' },
  { id: 'approved', name: 'Approved' },
  { id: 'current', name: 'Current' },
];
