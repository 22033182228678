import { makeStyles } from '@material-ui/core';
import { styles, colors, chipStyles } from '../design';
export const useStyles = makeStyles(theme => ({
  chip: {
    // width: 80,
    height: 20,
    color: '#1061A0',
    backgroundColor: 'rgba(240,	244,	250)'
  },
  activeChip: {
    ...styles.active,
  },
  listActionWrapper: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: '100%',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  addContainer: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: 120,
  },
  title: {
    fontStyle: 'Nunito Sans',
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.black,
    paddingLeft: 12,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  ...chipStyles,

}));
