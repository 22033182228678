import { Divider } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import {
  FunctionField,
  ReferenceField,
  Show,
  ShowView,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { DateField, TrackUser } from '../../components/common';
import { useShowController } from '../../hooks';
import { ResidentShowActions, ResidentShowFields } from '../../resources';
import { constants } from '../../utils';
import { TimelineShowActions } from './TimelineShowActions';

const { eventTypes } = constants;

export const CaseAppealShow = ({
  closeAside,
  classes,
  residentId,
  caseId,
  setRefreshTimeline,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const showProps = useShowController({ ...props });
  const { record = {} } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <TimelineShowActions
            closeAside={closeAside}
            title='Appeal'
            resourceRecord={record}
            eventForm='appealEditForm'
            eventId={record.id}
            eventType='Appeal'
            eventName={eventTypes.APPEAL_NAME}
            caseId={caseId}
            setRefreshTimeline={setRefreshTimeline}
            data={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <TextField
            source='level'
            label='Level'
            textAlign='right'
            className={classes.showItem}
          />
          <ReferenceField
            label='Appeal type'
            source='appeal_type_id'
            reference='appeal-types/list'
            textAlign='right'
            className={classes.showItem}
            link={false}
          >
            <TextField
              source='name'
              textAlign='right'
              className={classes.showItem}
            />
          </ReferenceField>
          <DateField
            source='date_sent'
            className={classes.showItem}
            label='Date sent'
            textAlign='right'
          />
          <ReferenceField
            label='Appeal status'
            source='appeal_status_id'
            reference='appeal-statuses/list'
            textAlign='right'
            className={classes.showItem}
            link={false}
          >
            <TextField
              source='name'
              textAlign='right'
              className={classes.showItem}
            />
          </ReferenceField>
          {/* needed for spacing */}
          <div className={classes.showItem} />
          <div className={classes.showItem} />
          <FunctionField
            source='appealCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { appealCreatedBy, created_at } = record;
              return (
                <TrackUser userField={appealCreatedBy} timestamp={created_at} />
              );
            }}
          />
          <FunctionField
            source='appealUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { appealUpdatedBy, updated_at } = record;
              return (
                <TrackUser userField={appealUpdatedBy} timestamp={updated_at} />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {/* <div className={classes.showItem} /> */}
        </SimpleShowLayout>
      </ShowView>
      <Divider className={classes.residentDivider} />
      <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
        Resident info
      </p>
      <Show
        actions={
          <ResidentShowActions
            closeAside={closeAside}
            isResidentPage={false}
            setRefreshTimeline={setRefreshTimeline}
            isViewAdmin={isViewAdmin}
          />
        }
        {...rest}
        resource='residents'
        id={residentId}
      >
        <ResidentShowFields classes={classes} includeCases />
      </Show>
    </div>
  );
};
