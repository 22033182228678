import React, { useState, useCallback, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import {
  DialogActions,
  DialogContent,
  Chip,
  Typography,
} from '@material-ui/core';
import {
  FormTextField,
  AutocompleteInput,
  ReferenceInput,
  getDateInputValue,
  handleNumbers,
} from '.';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';

export const ConnectionForm = ({ record = {}, isEdit, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [formData, setFormData] = useState({});
  const [services, setServices] = useState([]);

  const handleDelete = useCallback(value => {
    setServices(cur => cur.filter(c => c !== value));
  }, []);
  const onClick = useCallback(
    value => {
      value[0].name = value[0].name.split(' - ')[0].split(' , ')[0];
      setServices(curServices => [...curServices, ...value]);
    },
    [setServices],
  );

  useEffect(() => {
    if (isEdit && record) {
      const { service_ids, ...rest } = record;
      const serviceArray = service_ids.split('!');
      const services = serviceArray.map(service => JSON.parse(service));
      setServices(services);
      setFormData(f => ({
        ...f,
        ...rest,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    const service_ids = Array.from(new Set(services.map(row => row.value)));
    if (isEdit) {
      return dataProvider
        .update('connections', {
          id: 1, //arbitrary ID because multiple IDs are being updated at a time
          data: {
            ids: record.id,
            service_ids,
            ...value,
          },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('connections', {
          data: {
            service_ids,
            ...value,
          },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <Typography className={classes.instructions}>
                    Enter a clinical data keyword and choose the services,
                    codes, or categories of treatment which it represents. Each
                    item will be saved as a separate connection.
                  </Typography>
                  <FormTextField
                    name='clinical_keyword'
                    label='Clinical keyword'
                    customOnChange={customOnChange}
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div style={{ marginLeft: 0, marginRight: 4 }}>
                  <ReferenceInput
                    fullWidth
                    reference='categories/list'
                    customOnChange={customOnChange}
                    name='category_id'
                    label='Category'
                    style={{ marginRight: 0 }}
                    resetOption={{ id: null, name: 'No category' }}
                  />
                </div>
                <div style={{ marginLeft: 0, marginRight: 4 }}>
                  <AutocompleteInput
                    key={values.category_id}
                    trimLength={1}
                    clearInputOnClick
                    fullWidth
                    reference='services/list'
                    customOnChange={customOnChange}
                    name='services'
                    label='Services'
                    openOnFocus
                    autocompleteProps={{ openOnFocus: true }}
                    style={{ marginRight: 0 }}
                    parentOnClick={onClick}
                    getName
                    multiSelect
                    viewAll
                    required={!services.length}
                    options={{
                      filter: { category_id: values.category_id },
                    }}
                    highlightItems={services?.map(value => value.value)}
                  />
                </div>
                <div>
                  {!!services.length && (
                    <div style={{ margin: 10 }}>
                      {services.map(row => {
                        return (
                          <Chip
                            key={row.value}
                            label={
                              <Typography
                                style={{
                                  whiteSpace: 'normal',
                                  fontSize: 13,
                                }}
                              >
                                {row.name}
                              </Typography>
                            }
                            onDelete={() => handleDelete(row)}
                            className={classes.chip}
                            style={{
                              height: '100%',
                              maxWidth: 350,
                            }}
                            size='small'
                            disabled={!services}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
