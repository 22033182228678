import React, {
  Fragment,
  cloneElement,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  useNotify,
  List,
  BulkDeleteButton,
  useUnselectAll,
  TextField,
  FunctionField,
  useRefresh,
  // EmailField,
  // NumberField,
  // ChipField,
  TopToolbar,
  // ShowGuesser,
  useRedirect,
  usePermissions,
} from 'react-admin';
import {
  IconButton,
  Button,
  TextField as MuiTextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { DateField, Datagrid, StatusFilter } from '../components/common';
import { Attachments } from '../components/common/modals';
import { authGet, authPut } from '../server';
import { getAttchIcon } from '../utils';
import { fileManagementActions } from '../state/actions';
import * as design from '../design';
import { useStyles } from './case_attachments.styles';
import { FaxEmailToggle } from '../components/common/faxEmailFileToggle';
const { ViewIcon, ShareIcon, EditIcon, SaveButton, AttachmentIcon } = design;

const FileManagementListActions = ({
  showAll,
  setShowAll,
  caseId,
  residentCaseCount,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const [attchOpen, setAttchModal] = useState(false);
  const [selectFaxEmail, selectFaxEmailToggle] = useState(true);
  const refresh = useRefresh();
  const redirect = useRedirect();

  useEffect(() => {
    if (!selectFaxEmail) {
      redirect(`/cases/${caseId}/emails`);
    }
  }, [selectFaxEmail, caseId, redirect]);

  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex' }}>
            <span className={classes.title}>File management</span>
            {/* Needed for spacing */}
            <span style={{ marginRight: 25 }} />
            <FaxEmailToggle
              selectFaxEmail={selectFaxEmail}
              selectFaxEmailToggle={selectFaxEmailToggle}
            />
          </div>
          <div>
            <Button
              style={{
                color: isViewAdmin ? 'rgba(168,168,168)' : '#1061A0',
                backgroundColor: isViewAdmin ? 'rgba(220,220,220)' : '#EFF4FB',
                textTransform: 'capitalize',
              }}
              startIcon={<AttachmentIcon />}
              onClick={() => setAttchModal(true)}
              disabled={isViewAdmin}
            >
              Add File
            </Button>
          </div>
        </div>
        <div style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 8 }}>
          {residentCaseCount > 1 && ( //check this one
            <StatusFilter setShowAll={setShowAll} showAll={showAll} />
          )}
        </div>
      </TopToolbar>
      {attchOpen && (
        <Attachments
          open={attchOpen}
          handleClose={() => setAttchModal(false)}
          title='Add File'
          caseId={caseId}
          refresh={refresh}
          getAllCaseFiles
        />
      )}
    </Fragment>
  );
};

const AttchBulkActionButtons = ({ resource: _resource, ...props }) => {
  const unselectAll = useUnselectAll();
  const dispatch = useDispatch();
  const classes = useStyles();
  const onClick = () => unselectAll(_resource);
  const resource = 'documents';
  const { selectedIds } = props;
  const handleEmail = () => {
    dispatch(fileManagementActions.addEmailFileIds(selectedIds));
    unselectAll(_resource);
  };
  return (
    <div>
      <Button
        startIcon={<ShareIcon />}
        className={classes.emailLogBtn}
        size='small'
        onClick={handleEmail}
      >
        Share
      </Button>
      <BulkDeleteButton {...props} resource={resource} onClick={onClick} />
    </div>
  );
};

export const FileManagementList = ({ staticContext, ..._props }) => {
  const { caseId, ...props } = getResourceProps(_props);
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [showAll, setShowAll] = useState(true);
  const [openPageCountModal, setOpenPageCountModal] = useState(false);
  const [pageCountData, setPageCountData] = useState(null);
  const residentCaseCount = useSelector(state =>
    get(state, 'ui.residentCaseCount', 0),
  );
  const isFirstRender = useRef(true);

  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  useEffect(() => {
    setOpenPageCountModal(!!pageCountData);
  }, [pageCountData]);

  const handleViewClick = async document_id => {
    const response = await authGet(`/documents/url/${document_id}`);
    if (response.error) {
      notify('ra.notification.http_error', 'warning');
    }
    const win = window.open(response.data.url, '_blank');
    win.focus();
  };

  const toggleShowAll = useCallback(value => {
    setShowAll(value);
  }, []);

  const listRowStyle = (record, index, activeId) => {
    if (caseId && parseInt(record?.docEvents?.case_id) !== parseInt(caseId)) {
      return { backgroundColor: '#EFF4FB' };
    }
  };

  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        className={clsx(classes.list)}
        actions={
          <FileManagementListActions
            residentCaseCount={residentCaseCount}
            showAll={showAll}
            setShowAll={toggleShowAll}
            caseId={caseId}
            isViewAdmin={isViewAdmin}
          />
        }
        bulkActionButtons={!isViewAdmin ? <AttchBulkActionButtons /> : false}
        sort={{ field: 'created_at', order: 'DESC' }}
        filter={{ all: showAll }}
      >
        <Datagrid rowStyle={(record, index) => listRowStyle(record, index)}>
          <FunctionField
            label=''
            source='is_active'
            render={record => {
              const IconComponent = design[getAttchIcon(record.document_type)];
              return cloneElement(
                <IconComponent className={classes.fileIcons} />,
              );
            }}
          />
          <FunctionField
            label='Name'
            source='document_name'
            render={record => `${record.document_name}${record.document_type}`}
          />
          <FunctionField
            label='Pages'
            source='pages'
            render={record => {
              const { id, pages, document_type, document_name } = record;
              const displayPages =
                document_type === '.pdf' || document_type === '.docx';
              return (
                <span className={classes.noWrap}>
                  {displayPages && pages}
                  <EditIcon
                    className={classes.editIcon}
                    onClick={() =>
                      !isViewAdmin
                        ? setPageCountData({ id, document_name, displayPages })
                        : undefined
                    }
                    disabled={isViewAdmin}
                  />
                </span>
              );
            }}
          />
          <TextField source='docEvents.event_type' label='Event' />
          <DateField source='created_at' label='Upload date' />
          <FunctionField
            label=''
            source='id'
            render={record => (
              <IconButton onClick={() => handleViewClick(record.id)}>
                <ViewIcon className={classes.viewIcon} />
              </IconButton>
            )}
          />
        </Datagrid>
      </List>
      {openPageCountModal && (
        <UpdatePageCount
          open={openPageCountModal}
          pageCountData={pageCountData}
          classes={classes}
          handleClose={() => setPageCountData(null)}
        />
      )}
    </Fragment>
  );
};

export const fileManagementResource = {
  name: 'case-documents',
  list: FileManagementList,
};

function getResourceProps(props) {
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const basePath = pathname || '/cases';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-documents',
        hasList: true,
        hasEdit: !!fileManagementResource.edit,
        hasShow: !!fileManagementResource.show,
        hasCreate: !!fileManagementResource.create,
      };
  return { ...props, basePath, caseId, ...resource };
}

function UpdatePageCount({ pageCountData, open, handleClose, classes }) {
  const refresh = useRefresh();
  const notify = useNotify();
  const { id, document_name, displayPages } = pageCountData || {};
  const [count, setCount] = useState(displayPages || '');
  const [documentName, setNewDocName] = useState(document_name || '');

  async function handleSave() {
    const response = await authPut(`/documents/${id}`, {
      pages: count === '' ? undefined : count,
      document_name: documentName,
    });
    const { error } = response;
    if (error) {
      return notify('Error updating page count', 'warning');
    }
    notify('Changes saved');
    refresh();
    handleClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
    >
      <DialogTitle>{document_name}</DialogTitle>
      <DialogContent>
        <MuiTextField
          variant='outlined'
          margin='dense'
          fullWidth
          label='Document name'
          name='documentName'
          value={documentName}
          onChange={e => setNewDocName(e.target.value)}
        />
        {!!displayPages && (
          <MuiTextField
            variant='outlined'
            margin='dense'
            label='Page count'
            name='count'
            value={count}
            onChange={e => setCount(e.target.value)}
            type='number'
          />
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <SaveButton
            onClick={handleSave}
            className={classes.saveButton}
            // disabled={submitting}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
