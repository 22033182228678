import React, { useState, Fragment } from 'react';
import { stringify } from 'query-string';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, required, useRefresh } from 'react-admin';
import { getBaseReportUrl } from '../../../../utils';
import { DialogActions, DialogContent } from '@material-ui/core';
import { useStyles } from '../modal.styles';
import clsx from 'clsx';
import {
  FormTextField,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  minDate,
} from '.';
import { validateDate } from './inputs';
// import { dataProvider } from '../../../../server';

export const FacilityTasksExportForm = ({ handleClose, record }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState();
  const refresh = useRefresh();
  const facilityId = useSelector(state => state.facility.id);
  const { filter } = record;
  const token = localStorage.getItem('token');
  const roles = localStorage.getItem('roles');
  const userId = useSelector(state => state.user.profile.id);
  const baseUrl = getBaseReportUrl();

  const onSubmit = values => {
    window.open(
      `${baseUrl}/download?${stringify({
        _facilityId: facilityId,
        token,
        report: 'facility-tasks',
        _start: 0,
        _end: 1000,
        start_date: values.start_date,
        end_date: values.end_date,
        roles: roles.split(','),
        userId,
        ...filter,
      })}`,
      '_blank',
    );
    handleClose();
    refresh();
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='start_date'
                    customOnChange={customOnChange}
                    type='date'
                    label='Start date'
                    required
                    validate={required()}
                  />
                  <FormTextField
                    name='end_date'
                    customOnChange={customOnChange}
                    type='date'
                    label='End date'
                    required
                    validate={(getEndDateValidators(values), required())}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.exportButton}
                  type='submit'
                  label='Export'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
function getEndDateValidators(values, type) {
  const validators = [
    validateDate(),
    minDate(values['start_date'], 'End date cannot be before the start date'),
  ];
  return composeValidators(validators);
}
