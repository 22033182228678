import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useStyles } from './toggleButton.styles';
import { useGetUserDivision } from '../../hooks';

export const ToggleButton = ({
  initialDivision,
  setDivision,
  showBoth = true,
  disabled,
}) => {
  const userDivision = useGetUserDivision(showBoth);
  useEffect(() => {
    if (!initialDivision) {
      setPosition(userDivision);
      setDivision(userDivision);
    } else {
      setPosition(initialDivision);
      setDivision(initialDivision);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDivision, initialDivision]);
  const classes = useStyles();
  const [activePosition, setPosition] = useState(initialDivision || 'all');
  const handleClick = position => {
    setPosition(position);
    setDivision(position);
  };
  return (
    <div className={classes.toggleContainer}>
      <div
        className={clsx(classes.button, classes.leftButton, {
          [classes.active]: activePosition === 'subacute',
          [classes.disabled]: activePosition === 'subacute' && disabled,
        })}
        onClick={() => !disabled && handleClick('subacute')}
      >
        Subacute
      </div>
      <div
        className={clsx(
          classes.button,
          !showBoth ? classes.rightButton : classes.button,
          {
            [classes.active]: activePosition === 'mltc',
            [classes.disabled]: activePosition === 'mltc' && disabled,
          },
        )}
        onClick={() => !disabled && handleClick('mltc')}
      >
        MLTC
      </div>
      {showBoth && (
        <div
          className={clsx(classes.button, classes.rightButton, {
            [classes.active]: activePosition === 'all',
            [classes.disabled]: activePosition === 'all' && disabled,
          })}
          onClick={() => !disabled && handleClick('all')}
        >
          All
        </div>
      )}
    </div>
  );
};
