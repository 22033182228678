import { usePermissions } from 'react-admin';

/** @typedef {('admin' | 'supervisor' | 'case_manager' | 'quality_reviewer' | 'backend_reviewer' | 'team_leader')} Role */

export function useGetUserRoles() {
  const { permissions } = usePermissions();
  if (!permissions) {
    return { profileLoaded: false };
  }

  /** @type {Role[]} */
  const userPermissions = permissions?.split(',');

  const has = (/** @type {Role} */ role) => userPermissions?.includes(role);
  const hasOneOf = (/** @type {Role[]} */ roles) =>
    userPermissions?.some(role => roles.includes(role));

  return {
    has,
    hasOneOf,
    allRoles: userPermissions,
    is_admin: has('admin'),
    is_supervisor: has('supervisor'),
    is_case_manager: has('case_manager'),
    is_quality_reviewer: has('quality_reviewer'),
    is_contract_user: has('contract_user'),
    is_view_admin: has('view_admin'),
    is_admin_assistant: has('admin_assistant'),
    profileLoaded: true,
  };
}
