import React, { memo } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Route } from 'react-router-dom';
import { usePermissions } from 'react-admin';
import { casesResource } from '../resources/cases';
import { caseTimelineResource } from './case_timeline';
import { casePayersResource } from './case_payers';
import { communicationsResource } from './case_communications';
import { eligibilityResource } from './case-eligibility';
import { historyResource } from './case-history';
import {
  billingReportResource,
  tripleCheckReportResource,
  updatesDueResource,
  noAuthsReportResource,
  noUpdatesReportResource,
  facilityPayerResource,
  dischargeReportResource,
  productivityReportResource,
  clientReviewReportResource,
  changedPlannedDischargeReportResource,
  filteredAuthReportResource,
  tasksDueReportResource,
  updatesDueReportResource,
  denialReportResource,
  retroAuthReportResource,
  therapyReportResource,
} from './reports';
import { fileManagementResource } from './file_management';
import { caseEmailsResource } from './case_emails';
import {
  authsResource,
  denialsResource,
  updatesResource,
  pharmLogResource,
  tasksResource,
} from '../resources';
import { ProgressForm } from './progressForm';
import { ForgotPassword } from './forgotPassword';
import { SetPassword } from './set-password';
import { facilityResource } from './facility/facility';
import { ChangePassword } from './change-password';
import { Users } from './users';
import { CommunicationTemplates } from './communicationTemplates';
import { ClinicalPage, progressResource } from './clinical';
import { FacilityGroups } from './facility-groups';
import { CensusPage } from './census/censusTabs';
import { Dashboard } from './dashboard/dashboard';
import { DashboardHome } from './dashboard/dashboard-home';
import { CustomReports } from './reports/reports';
import { AdminCustomReports } from './reports/admin-reports';
import { CustomReportBuilder } from './reports/admin-reports-builder';

const renderComponent = Component => ({ location, match, history }) => {
  return (
    <CheckResource
      location={location}
      match={match}
      history={history}
      Component={Component}
    />
  );
};

const CheckResourceView = ({
  resources,
  location,
  match,
  history,
  Component,
}) => {
  const { permissions } = usePermissions();
  // React-admin will display custom routes before they create the resources
  // which will result in an error if using <List> in the custom route.
  if (!Object.keys(resources).length) {
    return null;
  }
  return (
    <Component
      match={match}
      location={location}
      history={history}
      permissions={permissions}
    />
  );
};
const CheckResource = compose(
  connect(state => {
    const { admin: { resources } = {} } = state;
    return {
      resources,
    };
  }),
  memo,
)(CheckResourceView);

export const customRoutes = [
  <Route
    exact
    path='/cases/:id'
    render={renderComponent(casesResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/timeline'
    render={renderComponent(caseTimelineResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/auths'
    render={renderComponent(authsResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/denials'
    render={renderComponent(denialsResource.list)}
  />,
  <Route
    exact
    path='/tasks/not-completed'
    render={renderComponent(tasksResource.list)}
  />,
  <Route
    exact
    path='/tasks/completed'
    render={renderComponent(tasksResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/tasks/not-completed'
    render={renderComponent(tasksResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/tasks/completed'
    render={renderComponent(tasksResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/updates/list'
    render={renderComponent(updatesResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/updates/schedules'
    render={renderComponent(updatesResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/clinical/progress'
    render={renderComponent(ClinicalPage)}
  />,
  <Route
    exact
    path='/cases/:id/clinical/diagnosis'
    render={renderComponent(ClinicalPage)}
  />,
  <Route
    exact
    path='/cases/:id/clinical/therapy'
    render={renderComponent(ClinicalPage)}
  />,
  <Route
    exact
    path='/cases/:id/clinical/medication'
    render={renderComponent(ClinicalPage)}
  />,
  <Route
    exact
    path='/cases/:id/clinical/progress-notes'
    render={renderComponent(ClinicalPage)}
  />,
  <Route
    exact
    path='/cases/:id/progress/:progressId/show'
    render={renderComponent(progressResource.show)}
  />,
  <Route
    exact
    path='/cases/:id/progress/form'
    render={renderComponent(ProgressForm)}
  />,
  <Route exact path='/progress/form' render={renderComponent(ProgressForm)} />,
  <Route
    exact
    path='/cases/:id/pharm-log'
    render={renderComponent(pharmLogResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/file-management'
    render={renderComponent(fileManagementResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/emails'
    render={renderComponent(caseEmailsResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/communications'
    render={renderComponent(communicationsResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/communications/:communicationId/show'
    render={renderComponent(communicationsResource.show)}
  />,
  <Route
    exact
    path='/cases/:id/payers'
    render={renderComponent(casePayersResource.list)}
  />,
  <Route
    exact
    path='/billing-report'
    render={renderComponent(billingReportResource.list)}
  />,
  <Route
    exact
    path='/triple-check-report'
    render={renderComponent(tripleCheckReportResource.list)}
  />,
  <Route
    exact
    path='/updates-schedule-report'
    render={renderComponent(updatesDueResource.list)}
  />,
  <Route
    exact
    path='/no-auths-report'
    render={renderComponent(noAuthsReportResource.list)}
  />,
  <Route
    exact
    path='/no-updates-report'
    render={renderComponent(noUpdatesReportResource.list)}
  />,
  <Route exact path='/census/:page?' render={renderComponent(CensusPage)} />,
  <Route
    exact
    path='/payers-report'
    render={renderComponent(facilityPayerResource.list)}
  />,
  <Route
    exact
    path='/changed-planned-discharges-report'
    render={renderComponent(changedPlannedDischargeReportResource.list)}
  />,
  <Route
    exact
    path='/los-discharge-report'
    render={renderComponent(dischargeReportResource.list)}
  />,
  <Route
    exact
    path='/staff-productivity-report'
    render={renderComponent(productivityReportResource.list)}
  />,
  <Route
    exact
    path='/filtered-auth-report'
    render={renderComponent(filteredAuthReportResource.list)}
  />,
  <Route
    exact
    path='/tasks-due-report'
    render={renderComponent(tasksDueReportResource.list)}
  />,
  <Route
    exact
    path='/updates-due-report'
    render={renderComponent(updatesDueReportResource.list)}
  />,
  <Route
    exact
    path='/client-details-report/report'
    render={renderComponent(clientReviewReportResource.list)}
  />,
  <Route
    exact
    path='/client-details-report/summary'
    render={renderComponent(clientReviewReportResource.list)}
  />,
  <Route exact path='/users' render={renderComponent(Users)} />,
  <Route
    exact
    path='/denial-report'
    render={renderComponent(denialReportResource.list)}
  />,
  <Route
    exact
    path='/retro-auths-report'
    render={renderComponent(retroAuthReportResource.list)}
  />,
  <Route
    exact
    path='/therapy-report'
    render={renderComponent(therapyReportResource.list)}
  />,
  <Route
    exact
    path='/communication-templates'
    render={renderComponent(CommunicationTemplates)}
  />,
  <Route
    path='/facility-details'
    render={renderComponent(facilityResource.list)}
  />,
  <Route
    exact
    path='/forgot-password'
    render={renderComponent(ForgotPassword)}
    noLayout
  />,
  <Route
    exact
    path='/set-password/:token'
    render={renderComponent(SetPassword)}
    noLayout
  />,
  <Route
    exact
    path='/change-password'
    render={renderComponent(ChangePassword)}
  />,
  <Route
    exact
    path='/facility-groups'
    render={renderComponent(FacilityGroups)}
  />,
  <Route
    exact
    path='/cases/:id/eligibility'
    render={renderComponent(eligibilityResource.list)}
  />,
  <Route
    exact
    path='/cases/:id/eligibility/:eligibilityId/show'
    render={renderComponent(eligibilityResource.show)}
  />,
  <Route exact path='/dashboard' render={renderComponent(Dashboard)} />,
  <Route
    exact
    path='/cases/:id/history'
    render={renderComponent(historyResource.list)}
  />,
  <Route exact path='/reports' render={renderComponent(CustomReports)} />,
  <Route
    exact
    path='/admin-reports'
    render={renderComponent(AdminCustomReports)}
  />,
  <Route
    exact
    path='/admin-reports-builder'
    render={renderComponent(CustomReportBuilder)}
  />,
  <Route
    exact
    path='/dashboard-home'
    render={renderComponent(DashboardHome)}
  />,
];
