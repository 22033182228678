import React, { useState, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import {
  ReferenceInput,
  FormTextField,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  maxTextLength,
} from '.';
import { SelectComponent } from './inputs';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
export const RatesForm = ({
  record = {},
  isEdit,
  meta,
  payerFacilityId,
  handleClose,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    payer_facility_id: payerFacilityId,
    line_of_businesses: [],
    part_b_excluded: meta.part_b_excluded,
  });
  const lobPickList = (meta.line_of_businesses || []).map(lob => ({
    id: lob.id,
    name: lob.payer_type,
  }));
  useEffect(() => {
    if (isEdit && record) {
      const {
        id,
        rev_codes: _rev_codes,
        payer_facility_lobs,
        ...rest
      } = record;
      const line_of_businesses = Array.isArray(payer_facility_lobs)
        ? payer_facility_lobs.map(lob => lob.line_of_business_id)
        : [];
      const rev_codes =
        _rev_codes && Array.isArray(_rev_codes) ? _rev_codes.join(',') : '';
      setFormData(f => ({
        ...f,
        ...rest,
        line_of_businesses,
        rev_codes,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    if (isEdit) {
      return dataProvider
        .update('payers-facility/rates', {
          id: record.id,
          data: { ...value },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('payers-facility/rates', { data: { ...value } })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          const IS_MEDICAID_OR_MEDICARE =
            +values.level === 10 || +values.level === 19;
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    key={1}
                    reference='auth-types/list'
                    customOnChange={customOnChange}
                    name='level'
                    label='Rate type'
                    validate={required()}
                    required
                    options={{
                      filter: {
                        rate: 1,
                        part_b_excluded: !!formData.part_b_excluded,
                      },
                    }}
                  />
                  {IS_MEDICAID_OR_MEDICARE ? (
                    <FormTextField
                      name='percent'
                      customOnChange={customOnChange}
                      type='number'
                      label='Percent of rate'
                      step='any'
                      validate={required()}
                      required
                    />
                  ) : (
                    <FormTextField
                      name='rate'
                      customOnChange={customOnChange}
                      type='number'
                      label='Reimbursement per day'
                      step='any'
                    />
                  )}
                </div>
                <SelectComponent
                  customOnChange={customOnChange}
                  validate={required()}
                  required
                  name='line_of_businesses'
                  label='Lines of business'
                  choices={lobPickList}
                  style={{ marginRight: 0 }}
                  fullWidth
                  multiple
                  renderWith='chip'
                />
                <div className={clsx(classes.inputContainerWrap)}>
                  {IS_MEDICAID_OR_MEDICARE ? (
                    <FormTextField
                      name='note'
                      label='Note (max 20 characters)'
                      multiline
                      fullWidth
                      customOnChange={customOnChange}
                      disabled={!!values.s_payer_rep}
                      validate={composeValidators([
                        required(),
                        maxTextLength(
                          20,
                          'Please enter an amount less than 20 characters',
                        ),
                      ])}
                      required
                      style={{ marginRight: 0 }}
                    />
                  ) : (
                    <Fragment>
                      <FormTextField
                        name='rev_codes'
                        label='Rev codes'
                        customOnChange={customOnChange}
                        validate={required()}
                        required
                        fullWidth
                        style={{ marginRight: 0 }}
                      />
                    </Fragment>
                  )}
                </div>
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
