import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { authGet } from '../../server';
import { Loading } from '../../components/common';
import { PrivacyPolicy } from '../../components/common/modals';
import { DashboardForm } from '../../components/common/modals';
import { usePermissions } from 'react-admin';
import { Chart } from 'react-google-charts';
import { Grid, Paper, Typography, FormControlLabel } from '@material-ui/core';
import { CaseManagerDashboard } from './dashboard-case_manager';
import { SupervisorDashboard } from './dashboard-supervisor';
import { useStyles } from './dashboard.styles';
import { AddIcon, CustomButton } from '../../design';
const columnChartOptions = {
  hAxis: { title: 'Months', textStyle: { fontSize: 11 } },
  vAxis: {
    title: 'Days',
    viewWindow: { min: 0 },
    textStyle: { fontSize: 11 },
    format: '0',
  },
  bar: { groupWidth: '75%' },
  colors: [
    '#1061a0',
    '#673BB8',
    '#4363D8',
    '#66afdb',
    '#53E783',
    '#AFDD46',
    '#FDD834',
    '#FB7700',
    '#E92031',
    '#EA1E75',
  ],
  isStacked: true,
};

export const Dashboard = ({
  title,
  viewMultiple,
  facility_ids,
  group_id,
  user_id,
}) => {
  const classes = useStyles();
  const defaultFacilityId = useSelector(state => state.facility.id);
  const facilityIds = facility_ids ? facility_ids : defaultFacilityId;
  const groupId = group_id !== '' ? group_id : undefined;
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const showSupervisorDashboard =
    permissions !== ''
      ? userPermissions.indexOf('admin') > -1 ||
        userPermissions.indexOf('supervisor') > -1
      : null;

  const [trendsData, setTrendsData] = useState([['?', 0]]);
  const [subacuteData, setSubacuteData] = useState([]);
  const [mltcData, setMltcData] = useState([]);
  const [casesData, setCasesData] = useState({});
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showMultiple, setShowMultiple] = useState(false);
  const [facilityNames, setFacilityNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [trends, cases] = await Promise.all([
        authGet([
          `/reports/dashboard/trends`,
          {
            facility_ids: facilityIds,
            group_id: groupId,
          },
        ]),
        authGet([
          `/reports/dashboard/cases`,
          {
            facility_ids: facilityIds,
            group_id: groupId,
          },
        ]),
      ]);
      trends.status === 200 && setTrendsData(trends.data);
      cases.status === 200 && setCasesData(cases.data);
    };
    if (showSupervisorDashboard && facilityIds) fetchData();
  }, [facilityIds, groupId, user_id, showSupervisorDashboard]);

  useEffect(() => {
    const fetchMLTCData = async () => {
      const response = await authGet([
        '/reports/dashboard/mltc',
        {
          facility_ids: facilityIds,
          group_id: groupId,
        },
      ]);
      if (!response.data) return;
      const { data } = response;
      setMltcData(formatColumnChartData(data));
    };

    const fetchSubacuteData = async () => {
      const subAcute = await authGet([
        `/reports/dashboard/subacute`,
        {
          facility_ids: facilityIds,
          group_id: groupId,
        },
      ]);
      if (!subAcute.data) return;
      const { data } = subAcute;
      setSubacuteData(formatColumnChartData(data));
    };

    const fetchFacilityNames = async () => {
      const response = await authGet([
        `/reports/dashboard/facility-names`,
        {
          facility_ids: facilityIds,
          group_id: groupId,
        },
      ]);
      if (!response.data) return;
      const { data } = response;
      setFacilityNames(data?.mapped);
    };

    if (facilityIds && showSupervisorDashboard) {
      fetchMLTCData();
      fetchSubacuteData();
    }
    fetchFacilityNames();
  }, [facilityIds, groupId, showSupervisorDashboard]);

  if (showSupervisorDashboard === null) return null;
  return (
    <Fragment>
      {!viewMultiple && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <FormControlLabel
            control={
              <CustomButton
                Icon={AddIcon}
                color='#1061A0'
                backgroundColor='#EFF4FB'
                label='View more'
                variant='text'
                onClick={() => setShowMultiple(!showMultiple)}
              />
            }
          />
        </div>
      )}

      {showSupervisorDashboard && !user_id ? (
        <SupervisorDashboard
          title={
            title || `Dashboard ${facilityNames ? ` - ${facilityNames}` : ''}`
          }
          facilityIds={facilityIds}
          groupId={groupId}
          formatColumnChartData={formatColumnChartData}
          casesData={casesData}
          viewMultiple={viewMultiple}
          userPermissions={userPermissions}
        />
      ) : (
        <CaseManagerDashboard
          title={
            title || `Dashboard ${facilityNames ? ` - ${facilityNames}` : ''}`
          }
          facilityIds={facilityIds}
          groupId={groupId}
          userId={user_id}
          formatColumnChartData={formatColumnChartData}
          casesData={casesData}
          viewMultiple={viewMultiple}
        />
      )}
      {showSupervisorDashboard && !user_id && (
        <Fragment>
          <div className={classes.sectionTitle}>Facility Trends</div>
          <div className={classes.flexRow}>
            <Paper style={{ height: 260, width: '100%', padding: 8 }}>
              <Chart
                chartType='AreaChart'
                width='100%'
                height='100%'
                data={[['Date', 'Subacute', 'MLTC'], ...trendsData]}
                options={{
                  title: viewMultiple
                    ? 'Cases in Facilities'
                    : 'Cases in Facility',
                  hAxis: {
                    title: 'Date',
                    textStyle: {
                      fontSize: 12,
                    },
                  },
                  vAxis: { title: 'Cases', minValue: 0 },
                  colors: ['#AFDD45', '#66afdb'],
                  areaOpacity: 1.0,
                  isStacked: true,
                  legend: { position: 'right' },
                }}
              />
            </Paper>
          </div>
          <div className={classes.sectionTitle}>Auth By Type</div>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <Paper className={classes.section}>
                {!!subacuteData.length ? (
                  <Chart
                    chartType='ColumnChart'
                    width='100%'
                    fontSize={11}
                    data={subacuteData}
                    options={{
                      ...columnChartOptions,
                      title: 'Subacute Auth Breakdown',
                    }}
                  />
                ) : (
                  <Loading />
                )}
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.section}>
                {!!mltcData.length ? (
                  <Chart
                    chartType='ColumnChart'
                    width='100%'
                    fontSize={11}
                    data={mltcData}
                    options={{
                      ...columnChartOptions,
                      title: 'MLTC Auth Breakdown',
                    }}
                  />
                ) : (
                  <Loading />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Fragment>
      )}

      <div
        style={{
          paddingTop: 10,
        }}
        className={classes.footer}
      >
        <Typography
          component='span'
          style={{
            background: 'none',
            border: 'none',
            fontSize: 'small',
          }}
        >
          View our Privacy Policy{' '}
          <Typography
            color='primary'
            component='span'
            style={{
              background: 'none',
              border: 'none',
              color: '#1061a0',
              fontSize: 'small',
              cursor: 'pointer',
            }}
            onClick={() => setShowPrivacyPolicy(true)}
          >
            here.
          </Typography>
        </Typography>
      </div>
      {showPrivacyPolicy && (
        <PrivacyPolicy
          open={showPrivacyPolicy}
          handleClose={() => setShowPrivacyPolicy(false)}
        />
      )}
      {showMultiple && (
        <DashboardForm
          open={showMultiple}
          handleClose={() => setShowMultiple(false)}
          facilityIds={facilityIds}
        />
      )}
    </Fragment>
  );
};

function formatColumnChartData(data, caseManagers) {
  if (!Array.isArray(data) || !data.length) return [];
  let cloned = cloneDeep(data);
  data.forEach((d, index) => {
    if (index === 0) {
      cloned[index].unshift('Genre');
      cloned[index].push({ role: 'annotation' });
    } else {
      cloned[index].push('');
    }
  });

  if (caseManagers) {
    //Remove managers with no active cases from 2D array
    for (let column = 1; column < cloned[0].length; column++) {
      let removeColumn = true;
      cloned.forEach((row, index) => {
        if (index > 0) {
          if (row[column] > 0) removeColumn = false;
        }
      });
      if (removeColumn) {
        cloned.map(row => row.splice(column, 1));
        column--; //Removing a column sets back the array index by 1
      }
    }
  }
  return cloned;
}
