import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { format, formatISO9075, isValid } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Form } from 'react-final-form';
import {
  useDataProvider,
  useNotify,
  usePermissions,
  required,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  AutocompleteInput,
  ReferenceInput,
  FormTextField,
  handleNumbers,
  getDateInputValue,
  validateDate,
  composeValidators,
  FileInput,
} from './modalForms';
import { useStyles } from './modal.styles';
import { SaveButton } from '../../../design';
import { CloseIcon, AccessTimeIcon } from '../../../design';

export const NewCommunication = ({
  open,
  handleClose,
  caseId: _caseId,
  isEdit,
  record,
  refresh,
}) => {
  const classes = useStyles();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const location = useLocation();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const { pathname } = location;
  const routeArr = pathname.split('/');
  const routeId = routeArr[2];
  const caseId = _caseId
    ? _caseId
    : routeArr[1] === 'cases' && routeId && !isNaN(routeId)
    ? parseInt(routeId)
    : undefined;
  const [formData, setFormData] = useState({
    date: format(Date.now(), 'yyyy-MM-dd'),
    case_id: caseId,
  });
  const [selectedTime, handleTimeChange] = useState(null);
  const [showTimeError, setShowTimeError] = useState(false);

  useEffect(() => {
    if (isEdit && record) {
      const { time_of_call, date, date_time, ...props } = sanitizeProps(record);
      setFormData(f => ({
        ...f,
        ...props,
        date: format(new Date(date_time), 'yyyy-MM-dd'),
      }));
      if (time_of_call) {
        handleTimeChange(new Date(date_time));
      }
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    let time_of_call;
    if (selectedTime) {
      if (isValid(selectedTime)) {
        time_of_call = formatTime(selectedTime);
      } else {
        notify('Invalid time of call', 'warning');
        return;
      }
    }
    if (isEdit) {
      return dataProvider
        .update('communications', {
          id: record.id,
          data: { ...value, time_of_call },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          if (typeof refresh === 'function') refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('communications', {
          data: { ...value, time_of_call },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          if (typeof refresh === 'function') refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };

  const timeOnBlur = useCallback(() => {
    setShowTimeError(true);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={reason => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
    >
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <DialogTitle
        disableTypography
        id='form-dialog-title'
        align='left'
        className={classes.modalTitle}
      >
        {`${!isEdit ? 'Add new' : 'Edit'} communication`}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{ paddingTop: 0 }}>
                <div style={{ paddingBottom: 15, width: 590 }}>
                  {!isEdit && !caseId && (
                    <div className={clsx(classes.inputContainerWrap)}>
                      <AutocompleteInput
                        class={classes.input}
                        reference='cases/list'
                        customOnChange={customOnChange}
                        fullWidth
                        name='case_id'
                        label='Case'
                        openOnFocus
                        autocompleteProps={{ openOnFocus: true }}
                        options={{
                          filter: { division: 'all', active: 0 },
                        }}
                        style={{ marginRight: 0 }}
                      />
                    </div>
                  )}
                </div>

                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='date'
                    validate={composeValidators([required(), validateDate()])}
                    required
                    customOnChange={customOnChange}
                    type='date'
                    label='Date'
                    disabled={
                      isEdit &&
                      userPermissions.indexOf('admin') === -1 &&
                      userPermissions.indexOf('supervisor') === -1
                    }
                  />
                  <ReferenceInput
                    reference='communication-types/list'
                    customOnChange={customOnChange}
                    name='communication_type_id'
                    label='Communication type'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    reference='communication-outcomes/list'
                    customOnChange={customOnChange}
                    name='communication_outcome_id'
                    label='Communication outcome'
                  />
                  <ReferenceInput
                    reference='notes-references/list'
                    customOnChange={customOnChange}
                    name='notes_reference_id'
                    label='Notes category'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      value={selectedTime}
                      onChange={handleTimeChange}
                      onBlur={timeOnBlur}
                      invalidDateMessage={!showTimeError ? '' : undefined} // hide error message while typing
                      label='Time of call'
                      inputVariant='outlined'
                      margin='dense'
                      style={{
                        flex: '1 0 155px',
                        paddingBottom: 10,
                        marginLeft: 3,
                      }}
                      keyboardIcon={
                        <AccessTimeIcon style={{ color: '#5C738E' }} />
                      }
                    />
                  </MuiPickersUtilsProvider>
                  <div style={{ flex: '1 0 160px' }} />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='notes'
                    label='Notes'
                    multiline
                    fullWidth
                    validate={required()}
                    required
                    customOnChange={customOnChange}
                    style={{ marginLeft: -1, marginRight: 0 }}
                  />
                </div>
                {!isEdit && (
                  <div className={classes.insuranceTitle}>
                    Attachments
                    <FileInput
                      title='title'
                      name='attachments'
                      setFormData={setFormData}
                      formData={formData}
                      classes={{ dropZone: classes.dropZone }}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

function sanitizeProps(props) {
  const {
    communication_type,
    created_at,
    deleted_at,
    id,
    notes_reference,
    updated_at,
    user,
    ...rest
  } = props;
  return rest;
}

function formatTime(selectedTime) {
  return selectedTime
    ? formatISO9075(selectedTime, { representation: 'time' })
    : selectedTime;
}
