import React, { useState, useEffect, useCallback, Fragment } from 'react';
import startCase from 'lodash/startCase';
import {
  useListController,
  ListView,
  TextField,
  TopToolbar,
  // Filter,
  // SelectInput,
  FunctionField,
  BooleanField,
  EmailField,
  useRefresh,
  useRedirect,
  useNotify,
  Pagination,
} from 'react-admin';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField as MaterialTextField,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';
import { useSidebarOpen } from '../hooks';
import { authPost } from '../server';
import { NewEvent } from '../components/common/modals';
import { ToggleButton, Datagrid, StatusFilter } from '../components/common';
import { CustomButton } from '../design/material-ui';
import { useStyles } from './users.styles';
import { AddIcon, EditIcon, CloseIcon, PeopleIcon } from '../design';
import { ListSearch } from '../components/common/ListSearch';

const ListActions = ({
  setDivision,
  permissions,
  showAll,
  setShowAll,
  setSearchText,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [reassignOpen, setReassignUsers] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex', paddingBottom: 20 }}>
            <span className={classes.title}>Users</span>
            {/* Needed for spacing */}
            <span style={{ marginRight: 25 }} />
            <ToggleButton
              setDivision={setDivision}
              className={classes.toggleWrapper}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <ListSearch
              doSearch={setSearchText}
              placeholder='Search user name'
            />

            {Array.isArray(permissions) &&
              (permissions.indexOf('admin') > -1 ||
                permissions.indexOf('supervisor') > -1 ||
                permissions.indexOf('admin_assistant') > -1) && (
                <span style={{ display: 'flex' }}>
                  <div style={{ marginTop: 10 }}>
                    <CustomButton
                      Icon={AddIcon}
                      color='#1061A0'
                      backgroundColor='#EFF4FB'
                      label='Add'
                      type='button'
                      variant='text'
                      size='small'
                      onClick={() => setEventModal(true)}
                    />
                  </div>
                  <Tooltip title='Bulk Reassign'>
                    <IconButton onClick={() => setReassignUsers(true)}>
                      <PeopleIcon style={{ color: '#5C738E' }} />
                    </IconButton>
                  </Tooltip>
                </span>
              )}
          </div>
        </div>

        <div style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 3 }}>
          <StatusFilter
            setShowAll={setShowAll}
            showAll={showAll}
            label='Show inactive users'
          />
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='users'
          refresh={refresh}
        />
      )}
      {reassignOpen && (
        <NewEvent
          open={reassignOpen}
          handleClose={() => setReassignUsers(false)}
          form='bulkReassignUsers'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const View = ({ userPermissions, id, ...props }) => {
  const isSidebarOpen = useSidebarOpen();
  const refresh = useRefresh();
  const classes = useStyles({ isSidebarOpen });
  const [eventOpen, setEventModal] = useState(false);
  const [usersRecord, setUsersRecord] = useState();
  const [passwordModal, setPasswordModal] = useState(false);
  const [usersId, setUsersId] = useState();

  useEffect(() => {
    // see comment on case_communication
    if (usersRecord) {
      setEventModal(true);
    }
  }, [usersRecord]);

  const handleModalClose = () => {
    setEventModal(false);
    setUsersRecord(undefined);
  };

  useEffect(() => {
    if (usersId) {
      setPasswordModal(true);
    }
  }, [usersId]);

  const handlePasswordModalClose = () => {
    setPasswordModal(false);
    setUsersId(undefined);
  };

  return (
    <div className={classes.listContainer}>
      <Datagrid {...props}>
        <TextField
          source='name'
          label='Name'
          headerClassName={classes.listItemLabel}
          sortBy='last_name'
        />
        <TextField
          source='division'
          label='Division'
          headerClassName={classes.listItemLabel}
        />
        <TextField
          source='QualityReviewer.name'
          label='Quality reviewer'
          sortable={false}
          headerClassName={classes.listItemLabel}
        />
        <TextField
          source='BackendReviewer.name'
          label='Backend reviewer'
          sortable={false}
          headerClassName={classes.listItemLabel}
        />
        <TextField
          source='TeamLeader.name'
          label='Team leader'
          sortable={false}
          headerClassName={classes.listItemLabel}
        />
        <FunctionField
          source='is_active'
          label='Active user'
          headerClassName={classes.listItemLabel}
          render={record => {
            return (
              <BooleanField
                record={record}
                source='is_active'
                headerClassName={classes.listItemLabel}
                style={{
                  color: record.is_active ? '#44C87A' : '#5C738E',
                }}
              />
            );
          }}
        />
        <FunctionField
          source='facilities'
          label='Facilities'
          sortable={false}
          headerClassName={classes.listItemLabel}
          render={record => {
            const { facilities } = record;
            return facilities?.length;
          }}
        />
        <FunctionField
          source='roles'
          label='Roles'
          headerClassName={classes.listItemLabel}
          render={record => {
            const { roles } = record;
            return Array.isArray(roles)
              ? roles
                  .map(r =>
                    typeof r.name === 'string'
                      ? startCase(r.name.replace(/_/g, ' '))
                      : undefined,
                  )
                  .join(', ')
              : null;
          }}
        />
        <EmailField
          source='email'
          label='Email'
          headerClassName={classes.listItemLabel}
        />
        <FunctionField
          source='status'
          label=''
          sortable={false}
          render={record => {
            return Array.isArray(userPermissions) &&
              (userPermissions.indexOf('admin') > -1 ||
                userPermissions.indexOf('admin_assistant') > -1) ? (
              <CustomButton
                Icon={EditIcon}
                color='#1061A0'
                backgroundColor='#EFF4FB'
                label='Edit password'
                type='button'
                variant='text'
                size='small'
                onClick={() => setUsersId(record.id)}
                className={classes.noWrap}
                disabled={
                  !!record?.roles?.find(r => r.name === 'admin') &&
                  userPermissions.indexOf('admin_assistant') > -1
                }
              />
            ) : null;
          }}
        />
        <FunctionField
          source='status'
          label=''
          sortable={false}
          render={record => {
            return Array.isArray(userPermissions) &&
              (userPermissions.indexOf('admin') > -1 ||
                userPermissions.indexOf('supervisor') > -1 ||
                userPermissions.indexOf('admin_assistant') > -1) ? (
              <CustomButton
                Icon={EditIcon}
                color='#1061A0'
                backgroundColor='#EFF4FB'
                label='Edit'
                type='button'
                variant='text'
                size='small'
                onClick={() => setUsersRecord(record)}
                disabled={
                  !!record?.roles?.find(r => r.name === 'admin') &&
                  userPermissions.indexOf('admin_assistant') > -1
                }
              />
            ) : null;
          }}
        />
      </Datagrid>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={usersRecord}
          form='users'
          isEdit
          refresh={refresh}
          payerFacilityId={id}
        />
      )}
      {passwordModal && (
        <PasswordModal
          open={passwordModal}
          handleClose={handlePasswordModalClose}
          userId={usersId}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export const UsersView = ({ record, id, userPermissions, ...props }) => {
  const [division, setDivision] = useState();
  const [showAll, setShowAll] = useState(false);
  const [searchText, setSearchText] = useState('');

  const toggleShowAll = useCallback(value => {
    setShowAll(value);
  }, []);

  const controllerProps = useListController({
    ...props,
    resource: 'users',
    basePath: '/users',
    filter: { division, all: showAll, q: searchText },
    sort: { field: 'last_name', order: 'ASC' },
  });

  return (
    <ListView
      empty={false}
      {...controllerProps}
      bulkActionButtons={false}
      exporter={false}
      pagination={
        <Pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          style={{
            display: 'flex',
          }}
        />
      }
      actions={
        <ListActions
          setDivision={setDivision}
          permissions={userPermissions}
          showAll={showAll}
          setShowAll={toggleShowAll}
          setSearchText={setSearchText}
        />
      }
    >
      <View userPermissions={userPermissions} id={id} />
    </ListView>
  );
};

export const Users = props => {
  const redirect = useRedirect();
  const { permissions = '' } = props;
  if (!permissions) return null;
  const userPermissions = permissions.split(',');
  if (
    userPermissions.indexOf('admin') === -1 &&
    userPermissions.indexOf('supervisor') === -1 &&
    userPermissions.indexOf('view_admin') === -1 &&
    userPermissions.indexOf('admin_assistant') === -1
  ) {
    redirect('/');
    return null;
  }
  return <UsersView {...props} userPermissions={userPermissions} />;
};

function PasswordModal({ open, handleClose, userId, refresh }) {
  const classes = useStyles();
  const notify = useNotify();
  const [authenticationStatus, setAuthenticationStatus] = useState(
    'unauthenticated',
  );
  const [formState, setFormState] = useState({
    adminPassword: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = useCallback(e => {
    const { name, value } = e.target || {};
    setFormState(state => ({ ...state, [name]: value }));
  }, []);

  const handleConfirmPassword = useCallback(() => {
    if (!formState.adminPassword) return;
    async function confirmPassword() {
      const response = await authPost('/reauthenticate', {
        password: formState.adminPassword,
      });
      if (response.error) {
        return setAuthenticationStatus('failed');
      }
      setAuthenticationStatus('authenticated');
    }
    confirmPassword();
  }, [formState.adminPassword]);

  const handleSubmit = useCallback(() => {
    async function submit() {
      const response = await authPost('/user-password', {
        user_id: userId,
        password: formState.password,
        confirm_password: formState.confirmPassword,
      });
      if (response.error) {
        return notify(response.error.message, 'warning');
      }
      notify('Successfully updated the password');
      handleClose();
      refresh();
    }
    submit();
  }, [
    formState.confirmPassword,
    formState.password,
    handleClose,
    notify,
    refresh,
    userId,
  ]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
    >
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <h1 className={classes.title}>Change password</h1>
        {authenticationStatus !== 'authenticated' ? (
          <Fragment>
            <DialogContentText>
              Please verify your password to continue
            </DialogContentText>
            <MaterialTextField
              id='outlined-password-input'
              type='password'
              autoComplete='current-password'
              variant='outlined'
              fullWidth
              name='adminPassword'
              value={formState.adminPassword}
              onChange={handleChange}
            />
            {authenticationStatus === 'failed' && (
              <DialogContentText color='error'>
                Incorrect password
              </DialogContentText>
            )}
            <DialogActions>
              <CustomButton
                color='#1061A0'
                backgroundColor='#EFF4FB'
                label='Submit'
                type='button'
                variant='text'
                size='small'
                onClick={handleConfirmPassword}
                disabled={formState.adminPassword.length < 8}
              />
            </DialogActions>
          </Fragment>
        ) : (
          <Fragment>
            <MaterialTextField
              type='password'
              variant='outlined'
              fullWidth
              name='password'
              label='Password'
              value={formState.password}
              onChange={handleChange}
              style={{ marginBottom: 15 }}
            />
            <MaterialTextField
              type='password'
              variant='outlined'
              fullWidth
              name='confirmPassword'
              label='Confirm password'
              value={formState.confirmPassword}
              onChange={handleChange}
            />
            <DialogActions>
              <CustomButton
                color='#1061A0'
                backgroundColor='#EFF4FB'
                label='Submit'
                type='button'
                variant='text'
                size='small'
                onClick={handleSubmit}
                disabled={
                  !formState.password ||
                  !formState.confirmPassword ||
                  formState.password.length < 8 ||
                  formState.password !== formState.confirmPassword
                }
              />
            </DialogActions>
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
}
