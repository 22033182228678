import { makeStyles } from '@material-ui/core';
import { styles } from '../../design';
import { style } from '../residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),

  root: {
    fontStyle: 'Nunito Sans'
  },
  listWithDrawer: {
    maxWidth: 'calc(100% - 420px)',
  },
  addContainer: {
    ...style(theme).addContainer,
    width: 335,
  },
  cmAddContainer: {
    width: 125,
  },
  aside: {
    zIndex: 0,
    maxWidth: 422,
    width: 422,
    paddingTop: 50,
    // background: 'inherit',
    // border: 'none',
  },
  actionHeader: {
    paddingTop: 8,
  },
  actionContainer: {
    width: '100%',
    padding: 12,
    ...styles.flexRow,
    ...styles.justifyBetween,
  },
  funcShowItem: {
    fontSize: 11,
  },
  attchListIcon: {
    transform: 'rotate(90deg)',
    color: '#AEDD45',
  },
  showFullWidth: {
    minWidth: '100%',
    marginBottom: 0,
    marginTop: 5,
  },
  showMarginTop: {
    marginTop: 10,
  },
  toDoIcon: {
    color: '#66afdb',
  },
  inProgressIcon: {
    color: '#001543',
  },
  insuranceUpdateIcon: {
    color: '#829CB5',
  },
  completedIcon: {
    color: '#AEDD45',
  },
  listActionWrapper: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: '100%',
    flexWrap: 'wrap',
    paddingBottom: 0,
  },
  toggleWrapper: {
    contentAlign: 'left'
  },
  showTitle: {
    color: ' black',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'Nunito Sans'
  },
  showLayout: {
    ...style(theme).showLayout,
    marginTop: -30,
    marginLeft: -20,
    padding: '8px 24px',
    ...styles.justifyBetween,
    ...styles.flexRow,
    flexWrap: 'wrap',
  },
  showItem: {
    color: 'black',
    fontSize: 15,
    marginTop: 10,
    height: 65,
  },
  inputContainerWrap: {
    ...styles.flexRow,
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    '& div:nth-child(1n)': {
      marginRight: 30,
    },
    '& div:nth-child(2n)': {
      marginRight: 0,
    },
  },
  showInput: {
    flex: '1 0 140px',
  },
  smallInput: {
    flex: '1 0 155px',
    marginLeft: theme.spacing(1),
  },
  mediumInput: {
    flex: '1 0 200px',
    marginLeft: theme.spacing(1),
  },
  textField: {
    marginLeft: 3,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  saveButton: {
    backgroundColor: '#829CB5',
    '&:hover': {
      backgroundColor: '#829CB5',
    },
  },
  checkBoxes: {
    fontSize: 13,
  },
  sectionTitle: {
    color: 'rgba(18,18,18,0.94)',
    fontSize: 15,
    width: '100%',
    textAlign: 'left',
    marginTop: 20,
    marginBottom: 10,
    fontWeight: 600
  },
  timeUntil: {
    color: '#E91F31',
    fontSize: 13,
    marginLeft: 25,
  },
  overdue: {
    color: '#E91F31',

  },
  trackUserContainer: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    marginTop: 5,
    width: '84%',
  },
  notYetDue: {
    color: '#1061A0',
    backgroundColor: '#C5DAF1'
  },
  filterDropdown:
  {  textTransform: 'capitalize',
      color: '#61728C',
      fontSize: 16,
      marginTop: 2,
      marginRight: 2,
      fontStyle: 'Nunito Sans'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    fontWeight: 500,
    backgroundColor: '#EFF4FB',
    color: '#1061A0'
  },
 
}));
