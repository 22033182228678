import React, { useState, useCallback } from 'react';
import { Chip, Menu, MenuItem, Fade, Button } from '@material-ui/core';
import { useStyles } from './listFilter.styles';
import { ArrowDropDownIcon } from '../../design';

export function FilterMenu({ filterActions, filters = [], disabled }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const addFilter = (filterId, filterName) => {
    filterActions({ actionType: 'addFilter', id: filterId, name: filterName });
    setAnchorEl(null);
  };

  const handleClose = useCallback(() => setAnchorEl(null), []);
  const classes = useStyles();
  return (
    <div
      style={{
        minWidth: 200,
      }}
    >
      <Button
        className={classes.filterDropdown}
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        disabled={disabled}
      >
        <span style={{ fontWeight: 300, width: 100 }}> Filter</span>
      </Button>

      <Menu
        id='fade-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {filters.map(f => (
          <MenuItem key={f.id} onClick={_ => addFilter(f.id, f.name)}>
            {f.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export function FilterChips({ activeFilters = {}, filterActions, disabled }) {
  const classes = useStyles();
  // NOTE the `activeFiltersKeys` are the filter ids
  const activeFiltersKeys = Object.keys(activeFilters);
  if (!activeFiltersKeys.length) return null;
  return (
    <div className={classes.chipsContainer}>
      {activeFiltersKeys.map(id => {
        return (
          <div
            style={{
              paddingTop: 8,
            }}
          >
            <Chip
              key={id}
              className={classes.chips}
              size='small'
              label={activeFilters[id]}
              disabled={disabled}
              onDelete={() =>
                filterActions({
                  actionType: 'removeFilter',
                  id,
                  name: activeFilters[id],
                })
              }
            />
          </div>
        );
      })}
    </div>
  );
}
