import React, { Fragment, useState, useEffect } from 'react';
import {
  useRefresh,
  TextField,
  TopToolbar,
  Pagination,
  usePermissions,
  useRedirect,
  List,
  FunctionField,
  useListController,
} from 'react-admin';
import { Datagrid } from '../components/common';
import { NewEvent } from '../components/common/modals';
import { CustomButton } from '../design/material-ui';
import { useStyles } from '../resources/payers/payers.styles';
import { AddIcon, EditIcon } from '../design';
import { IconButton } from '@material-ui/core';
import { ListSearch } from '../components/common/ListSearch';

const ServicesActions = ({ basePath, data = {}, resource, setSearchText }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [eventOpen, setEventModal] = useState(false);

  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Services</span>

        {userPermissions.indexOf('admin') > -1 ||
        userPermissions.indexOf('contract_user') > -1 ? (
          <div style={{ display: 'flex' }}>
            <ListSearch
              doSearch={setSearchText}
              placeholder='Search by service'
            />
            <div style={{ marginTop: 10 }}>
              <CustomButton
                Icon={AddIcon}
                color='#1061A0'
                backgroundColor='#EFF4FB'
                label='Add'
                type='button'
                variant='text'
                size='small'
                onClick={() => setEventModal(true)}
              />
            </div>
          </div>
        ) : (
          //for spacing...
          <div />
        )}
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='serviceForm'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

export const ServicesList = props => {
  const classes = useStyles();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [serviceRecord, setServiceRecord] = useState();
  const [eventOpen, setEventModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    // see comment on case_communication
    if (serviceRecord) {
      setEventModal(true);
    }
  }, [serviceRecord]);
  const handleModalClose = () => {
    setEventModal(false);
    setServiceRecord(undefined);
  };
  const { permissions = '' } = props;
  const controllerProps = useListController({
    ...props,
    resource: 'services',
    basePath: '/services',
    filter: { q: searchText },
    sort: {
      field: 'service',
      order: 'ASC',
    },
  });

  if (!permissions) return null;
  const userPermissions = permissions.split(',');
  if (
    userPermissions.indexOf('admin') === -1 &&
    userPermissions.indexOf('supervisor') === -1 &&
    userPermissions.indexOf('contract_user') === -1
  ) {
    redirect('/');
    return null;
  }

  return (
    <Fragment>
      <List
        empty={false}
        {...controllerProps}
        exporter={false}
        bulkActionButtons={
          Array.isArray(userPermissions) &&
          userPermissions.indexOf('admin') === -1 &&
          userPermissions.indexOf('supervisor') === -1 &&
          userPermissions.indexOf('contract_user') === -1
            ? false // disable delete
            : undefined // will show default delete
        }
        className={classes.list}
        actions={<ServicesActions setSearchText={setSearchText} />}
        sort={{
          field: 'service',
          order: 'ASC',
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid rowClick='show'>
          <TextField
            source='service'
            label='Service'
            headerClassName={classes.listItemLabel}
          />
          <TextField
            source='category.category'
            label='Category'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          <TextField
            source='codes'
            label='Codes'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          <FunctionField
            source='status'
            label=''
            sortable={false}
            render={record => {
              return (
                <IconButton
                  style={{ color: '#5C738E' }}
                  onClick={() => setServiceRecord(record)}
                  size='small'
                >
                  <EditIcon />
                </IconButton>
              );
            }}
          />
        </Datagrid>
      </List>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={serviceRecord}
          form='serviceForm'
          isEdit
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

export const servicesResource = {
  name: 'services',
  list: ServicesList,
  icon: AddIcon,
  options: { label: 'Services' },
};
