import React, { Fragment } from 'react';
import clsx from 'clsx';
import {
  TextField,
  SimpleShowLayout,
  FunctionField,
  BooleanField,
} from 'react-admin';
import { Chip, Link, Divider } from '@material-ui/core';
import { DateField, NpiToggle } from '../../components/common';
import { useStyles } from './payers.styles';
import { getChip } from '../../utils';
import { EmptyIcon } from '../../design';
export const GeneralInfo = ({ ...props }) => {
  const { record = {} } = props;
  const classes = useStyles();
  const isAltNpi = !!record.alt_npi;
  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          paddingBottom: 20,
          paddingTop: 20,
        }}
      >
        <div>
          <SimpleShowLayout {...props}>
            <TextField
              source='Payers.name'
              label=''
              className={classes.title}
            />
            <FunctionField
              source='rev_codes'
              label=''
              className={clsx(classes.showItem, classes.showFullWidth)}
              render={record => {
                return <NpiToggle isFacilityNpi={!isAltNpi} />;
              }}
            />
            <FunctionField
              source='address'
              label='Claims address'
              render={record => {
                const { Payers } = record;
                const { remit_address, city, state, zip } = Payers
                  ? Payers
                  : {};
                return (
                  <div>
                    {remit_address}
                    <br />
                    {city ? city + ', ' + state + ' ' + zip : ''}
                  </div>
                );
              }}
            />{' '}
            <TextField
              source='group_provider'
              label='Group provider'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
            />
          </SimpleShowLayout>
        </div>

        <Divider orientation='vertical' variant='middle' flexItem />
        <div>
          <SimpleShowLayout className={classes.showLayout} {...props}>
            <div className={classes.showItem}>
              <FunctionField
                source='is_active'
                label=''
                render={record => {
                  const { is_active, in_network } = record;
                  const status =
                    !!is_active || is_active === null ? 'Active' : 'Inactive';
                  return (
                    <div>
                      <span>
                        <Chip
                          size='small'
                          label={status}
                          className={clsx(
                            classes.chip,
                            classes[getChip(status)],
                          )}
                        ></Chip>
                      </span>
                      <br />
                      <br />
                      {!!in_network ? 'In network' : 'Out of network'}
                    </div>
                  );
                }}
              />
            </div>

            <DateField
              source='effective_date'
              label='Effective date'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
            />
            <DateField
              source='end_date'
              label='Recredential date'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
            />
            <FunctionField
              source='part_b_excluded'
              label='Part B excluded'
              className={classes.showItem}
              headerClassName={classes.showItemLabel}
              render={record => {
                const { part_b_excluded } = record;
                const updatedRecord = {
                  ...record,
                  part_b_excluded: !!part_b_excluded,
                };
                return (
                  <BooleanField
                    FalseIcon={EmptyIcon}
                    record={updatedRecord}
                    source='part_b_excluded'
                    className={classes.showItem}
                    headerClassName={classes.showItemLabel}
                    style={{
                      color: record.part_b_excluded ? '#44C87A' : '#5C738E',
                    }}
                  />
                );
              }}
            />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <FunctionField
              source='rev_codes'
              label=''
              className={clsx(classes.showItem, classes.showFullWidth)}
              headerClassName={classes.showItemLabel}
              render={record => {
                const { line_of_businesses = [] } = record;
                return (
                  <div className={classes.chips}>
                    {line_of_businesses.map(lob => {
                      const { id, payer_type } = lob;
                      return (
                        <Fragment>
                          <Chip
                            color='primary'
                            key={id}
                            label={payer_type}
                            className={classes.chip}
                          />
                          <div
                            style={{
                              backgroundColor: 'white',
                              paddingRight: 2,
                              paddingLeft: 2,
                              paddingBottom: 30,
                              marginLeft: 0,
                              borderRadius: 0,
                            }}
                          />
                        </Fragment>
                      );
                    })}
                  </div>
                );
              }}
            />
          </SimpleShowLayout>
          <span style={{ display: 'flex' }}>
            <SimpleShowLayout className={classes.showLayout} {...props}>
              <FunctionField
                label='Website'
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
                render={record => {
                  const { Payers } = record;
                  const website = Payers ? Payers.website : null;
                  return website ? (
                    <Link
                      href={website}
                      target='_blank'
                      rel='noopener noreferrer'
                      underline='none'
                    >
                      {website}
                    </Link>
                  ) : null;
                }}
              />
              <FunctionField
                source='pre_auth_eligibility_contact'
                label='Pre-auth/eligibility contact'
                className={classes.showItem}
                render={record => {
                  const { Payers } = record;
                  const pre_auth_eligibility_contact = Payers
                    ? Payers.pre_auth_eligibility_contact
                    : null;
                  return <div>{pre_auth_eligibility_contact}</div>;
                }}
              />
              <TextField
                source='provider_relations_contact'
                label='Provider relations contact'
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
              />
              <FunctionField
                source='seek_id'
                label='Seek ID'
                className={classes.showItem}
                render={record => {
                  const { Payers } = record;
                  const seek_id = Payers ? Payers.seek_payer_code : null;
                  return <div>{seek_id}</div>;
                }}
              />
              <TextField
                source='pin'
                label='Provider ID number (PIN)'
                className={classes.showItem}
                headerClassName={classes.showItemLabel}
              />
              <FunctionField
                source='admit_notice_required'
                label='Admission notification required'
                render={record => {
                  const { admit_notice_required } = record;
                  return !!admit_notice_required ? 'Yes' : 'No';
                }}
              />
              <FunctionField
                source='submit_terms'
                label='Claim submission terms'
                className={classes.showItem}
                render={record => {
                  const { Payers } = record;
                  const submit_terms = Payers ? Payers.submit_terms : null;
                  return <div>{submit_terms}</div>;
                }}
              />
              <FunctionField
                source='payment_terms'
                label='Claim payment terms'
                className={classes.showItem}
                render={record => {
                  const { Payers } = record;
                  const payment_terms = Payers ? Payers.payment_terms : null;
                  return <div>{payment_terms}</div>;
                }}
              />
              {isAltNpi && (
                <TextField
                  record={record}
                  source='alt_npi.name'
                  label='Company name'
                  className={classes.showItem}
                  headerClassName={classes.showItemLabel}
                />
              )}
              {isAltNpi && (
                <TextField
                  record={record}
                  source='alt_npi.npi'
                  label='NPI #'
                  className={classes.showItem}
                  headerClassName={classes.showItemLabel}
                />
              )}
              {isAltNpi && (
                <TextField
                  record={record}
                  source='alt_npi.tax_id'
                  label='Tax #'
                  className={classes.showItem}
                  headerClassName={classes.showItemLabel}
                />
              )}
              {isAltNpi && (
                <FunctionField
                  record={record}
                  source='alt_npi.address'
                  label='Address'
                  className={clsx(classes.showItem)}
                  headerClassName={classes.showItemLabel}
                  render={record => {
                    const {
                      alt_npi: { address, city, state, zip } = {},
                    } = record;
                    return `${address} ${city} ${state} ${zip}`;
                  }}
                />
              )}
            </SimpleShowLayout>
          </span>
        </div>
      </div>
    </Fragment>
  );
};
