import { authPut } from '../../server';
import { reduxStore } from '../../createAdminStore';
import { showNotification } from 'react-admin';
import { userActions } from './user';

export const facilityTypes = {
  GET_FACILITY: 'GET_FACILITY',
};
export const facilityActions = {
  getFacility(facilityId) {
    return async dispatch => {
      const response = await authPut('/users/preferences', {
        last_facility: facilityId,
      });
      if (response.error) {
        const store = reduxStore();
        const {
          user: {
            profile: { user_preference: { last_facility } = {} } = {},
          } = {},
        } = store.getState();
        dispatch(
          showNotification(
            'An error occurred. Please notify support.',
            'warning',
          ),
        );
        // did this because users were getting into infinite loop
        if (
          last_facility &&
          response.status !== 401 &&
          response.status !== 403
        ) {
          dispatch(facilityActions.getFacility(last_facility));
        }
        return 'error';
      }
      localStorage.setItem('facility_id', response.data.id);
      dispatch({
        type: facilityTypes.GET_FACILITY,
        payload: response.data,
      });
      dispatch(userActions.getUserProfile());
    };
  },
};
