import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import { connect, useDispatch, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import {
  Sidebar as AdminSidebar,
  MenuItemLink,
  usePermissions,
} from 'react-admin';
import { SidebarCases } from './SidebarCases';
import { uiActions } from '../../state/actions';
import { Link, withRouter } from 'react-router-dom';
import { sidebarResources } from '../../resources';
import { List, Typography, Divider, Badge } from '@material-ui/core';
import {
  useStyles,
  useSidebarStyles,
  useTasksSidebarStyles,
  useUpdatesSidebarStyles,
  useSidebarFacilityStyles,
} from './sidebar.styles';

import {
  TimeIcon,
  KeyboardBackspaceIcon,
  AuthIcon,
  DenialIcon,
  UpdateIcon,
  AttachmentIcon,
  PharmIcon,
  InsertCommentIcon,
  PayerIcon,
  ProgressIcon,
  NotificationIcon,
  EligibilityIcon,
  CensusIcon,
} from '../../design';

const SidebarView = ({
  reduxActions,
  sidebarCase,
  adminCases,
  staticContext,
  sidebarOpen,
  getCaseTasksCount,
  ...props
}) => {
  const {
    location: { pathname },
  } = props;
  const routeArr = pathname.split('/');
  const caseId = routeArr[2];
  const showCaseSidebar = routeArr[1] === 'cases' && caseId && !isNaN(caseId);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sidebarClasses = useSidebarStyles();
  const facilityId = useSelector(state => state.facility.id);
  const tasksSidebarClasses = useTasksSidebarStyles({
    showActive: routeArr[1] === 'tasks' || routeArr[3] === 'tasks',
    isCaseLevel: showCaseSidebar,
  });
  const updatesSidebarClasses = useUpdatesSidebarStyles({
    showActive: routeArr[1] === 'updates' || routeArr[3] === 'updates',
    isCaseLevel: showCaseSidebar,
  });
  const filesSidebarClasses = useUpdatesSidebarStyles({
    showActive: routeArr[3] === 'file-management' || routeArr[3] === 'emails',
    isCaseLevel: showCaseSidebar,
  });
  const sidebarFacilityClasses = useSidebarFacilityStyles();

  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  useEffect(() => {
    if (showCaseSidebar) {
      dispatch(uiActions.getCaseTasksCount(caseId));
    }
  }, [caseId, showCaseSidebar, dispatch]);

  useEffect(() => {
    dispatch(uiActions.getFaxInboxCount());
  }, [facilityId, dispatch]);

  useEffect(() => {
    dispatch(
      uiActions.updateShowFab(!routeArr.some(arr => arr.includes('report'))),
    );
  }, [routeArr, dispatch]);

  return !showCaseSidebar ? (
    <AdminSidebar
      {...props}
      className={clsx({ [classes.marginTop]: sidebarOpen })}
    >
      <Fragment>
        <SidebarCases {...props} />
        <div
          className={clsx(classes.sidebar)}
          style={{
            backgroundColor: sidebarOpen ? '#FFFFFF' : 'inherit',
            borderRight: sidebarOpen ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
            marginTop: 0,
            width: 239,
          }}
        >
          <List className={classes.facilitySidebarList}>
            {sidebarResources.map(resource => {
              const { name, activeName, Icon, label, key } = resource;
              if (
                userPermissions.indexOf('contract_user') > -1 &&
                label !== 'Payer contracts'
              ) {
                return null;
              }
              if (name === 'divider') {
                return (
                  <Divider
                    key={key}
                    style={{ margin: 5, backgroundColor: '#C5DAF1' }}
                  />
                );
              }
              return (
                <MenuItemLink
                  key={key}
                  to={`/${name}`}
                  primaryText={
                    label === 'Fax/Email Log' ? label : startCase(label || name)
                  }
                  sidebarIsOpen={sidebarOpen}
                  leftIcon={
                    <Icon
                      className={clsx(classes.sidebarIconsFacility, {
                        [classes.activeFacilityIcon]:
                          routeArr[1] === activeName || routeArr[1] === name,
                      })}
                    />
                  }
                  classes={
                    activeName === 'tasks'
                      ? tasksSidebarClasses
                      : sidebarFacilityClasses
                  }
                />
              );
            })}
          </List>
        </div>
      </Fragment>
    </AdminSidebar>
  ) : (
    <AdminSidebar
      {...props}
      className={clsx(classes.casesSidebar, {
        [classes.marginTop]: sidebarOpen,
      })}
    >
      <Fragment>
        <SidebarCases {...{ ...props, sidebarCase: caseId }} />
        <div className={clsx(classes.sidebar, classes.rootCases)}>
          <List
            classes={{ root: classes.withCaseContainer }}
            style={{
              marginTop: sidebarOpen ? 70 : 0,
              width: sidebarOpen ? '100%' : 'inherit',
            }}
          >
            <MenuItemLink
              to={`/cases/${caseId}/timeline`}
              primaryText={'Timeline'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={
                <TimeIcon
                  className={clsx(classes.sidebarIcons, {
                    [classes.activeItem]: routeArr[3] === 'timeline',
                  })}
                />
              }
              classes={sidebarClasses}
            />
            <MenuItemLink
              to={`/cases/${caseId}/auths`}
              primaryText={'Authorizations'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={
                <AuthIcon
                  className={clsx(classes.sidebarIcons, {
                    [classes.activeItem]: routeArr[3] === 'auths',
                  })}
                />
              }
              classes={sidebarClasses}
            />
            <MenuItemLink
              to={`/cases/${caseId}/denials`}
              primaryText={'Denials'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={
                <DenialIcon
                  className={clsx(classes.sidebarIcons, {
                    [classes.activeItem]: routeArr[3] === 'denials',
                  })}
                />
              }
              classes={sidebarClasses}
            />
            <MenuItemLink
              to={`/cases/${caseId}/tasks/not-completed`}
              primaryText={'Tasks'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={<BadgeIcon classes={classes} routeArr={routeArr} />}
              classes={tasksSidebarClasses}
            />
            <MenuItemLink
              to={`/cases/${caseId}/updates/list`}
              primaryText={'Updates'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={
                <UpdateIcon
                  className={clsx(classes.sidebarIcons, {
                    [classes.activeItem]: routeArr[3] === 'updates',
                  })}
                />
              }
              classes={updatesSidebarClasses}
            />

            <MenuItemLink
              to={`/cases/${caseId}/clinical/progress`}
              primaryText={'Clinical'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={
                <ProgressIcon
                  className={clsx(classes.sidebarIcons, {
                    [classes.activeItem]: routeArr[3] === 'clinical',
                  })}
                />
              }
              classes={sidebarClasses}
            />
            <MenuItemLink
              to={`/cases/${caseId}/pharm-log`}
              primaryText={'Pharm log'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={
                <PharmIcon
                  className={clsx(classes.sidebarIcons, {
                    [classes.activeItem]: routeArr[3] === 'pharm-log',
                  })}
                />
              }
              classes={sidebarClasses}
            />
            <Divider style={{ margin: 5, backgroundColor: '#C5DAF1' }} />
            <MenuItemLink
              to={`/cases/${caseId}/file-management`}
              primaryText={'File management'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={
                <AttachmentIcon
                  className={clsx(classes.sidebarIcons, {
                    [classes.activeItem]:
                      routeArr[3] === 'file-management' ||
                      routeArr[3] === 'emails',
                  })}
                />
              }
              classes={filesSidebarClasses}
            />
            <MenuItemLink
              to={`/cases/${caseId}/communications`}
              primaryText={'Communications'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={
                <InsertCommentIcon
                  className={clsx(classes.sidebarIcons, {
                    [classes.activeItem]: routeArr[3] === 'communications',
                  })}
                />
              }
              classes={sidebarClasses}
            />
            <Divider style={{ margin: 5, backgroundColor: '#C5DAF1' }} />
            <MenuItemLink
              to={`/cases/${caseId}/payers`}
              primaryText={'Payers'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={
                <PayerIcon
                  className={clsx(classes.sidebarIcons, {
                    [classes.activeItem]: routeArr[3] === 'payers',
                  })}
                />
              }
              classes={sidebarClasses}
            />
            <MenuItemLink
              to={`/cases/${caseId}/eligibility`}
              primaryText={'Eligibility'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={
                <EligibilityIcon
                  className={clsx(classes.sidebarIcons, {
                    [classes.activeItem]: routeArr[3] === 'eligibility',
                  })}
                />
              }
              classes={sidebarClasses}
            />
            <Divider style={{ margin: 5, backgroundColor: '#C5DAF1' }} />
            <MenuItemLink
              to={`/cases/${caseId}/history`}
              primaryText={'History'}
              sidebarIsOpen={sidebarOpen}
              leftIcon={
                <CensusIcon
                  className={clsx(classes.sidebarIcons, {
                    [classes.activeItem]: routeArr[3] === 'history',
                  })}
                />
              }
              classes={sidebarClasses}
            />
          </List>
          <Link to='/residents-list' style={{ textDecoration: 'none' }}>
            <Typography
              color='primary'
              align='center'
              className={classes.navBack}
              style={{ width: sidebarOpen ? '100%' : 'inherit' }}
            >
              <KeyboardBackspaceIcon
                className={clsx({ [classes.backIcon]: sidebarOpen })}
              />
              {sidebarOpen && <span>Back to facility</span>}
            </Typography>
          </Link>
        </div>
      </Fragment>
    </AdminSidebar>
  );
};

const mapStateToProps = state => {
  const { admin: { ui: { sidebarOpen } = {} } = {} } = state;
  return {
    sidebarOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  reduxActions: {
    ...bindActionCreators(uiActions, dispatch),
  },
});

export const Sidebar = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(SidebarView);

const BadgeIcon = ({ classes, routeArr, ...props }) => {
  const tasksCount = useSelector(state => get(state, 'ui.caseTasksCount', 0));
  return (
    <Badge
      color='secondary'
      badgeContent={tasksCount || 0}
      classes={{ badge: classes.badge }}
    >
      <NotificationIcon
        {...props}
        className={clsx(classes.sidebarIcons, {
          [classes.activeItem]: routeArr[3] === 'tasks',
        })}
      />
    </Badge>
  );
};
