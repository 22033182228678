import { makeStyles } from '@material-ui/core';
import { styles } from '../../design';
import { style } from '../residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  actionHeader: {
    paddingTop: 8,
  },
  actionContainer: {
    width: '100%',
    padding: 12,
    ...styles.flexRow,
    ...styles.justifyBetween,
  },
  showLayout: {
    ...styles.flexRow,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: '100%',
    margin: '0',
    '& div:nth-child(n)': {
      maxWidth: 200,
    },
  },

  showGlobalLayout: {
    ...styles.flexRow,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: '100%',
    margin: '0',
    '& div:nth-child(n)': {
      maxWidth: 250,
    },
  },

  listItemLabel: {
    whiteSpace: 'nowrap',
  },
  showItemLabel: {
    fontWeight: 600,
    fontSize: 40,
    whiteSpace: 'nowrap',
  },
  showItem: {
    color: 'black',
    flex: '1 0 20vw',
    fontSize: '0.885rem',
    marginRight: 45,
    padding: '10px 0px 10px 0px',
    height: 63,
  },
  noBorder: {
    borderBottom: 'none !important',
  },
  showFullWidth: {
    minWidth: '93%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    fontWeight: 500,
    color: '#1061A0',
  },
  chip: {
    marginRight: 0,
    marginLeft: 0,
    borderRadius: 0,
    height: 22,
    color: '#1061A0',
    backgroundColor: '#EFF4FB',
  },
  height1: {
    height: 1,
  },
  radio: {
    marginTop: 15,
  },
  attchListIcon: {
    color: '#5C738E',
  },
  userTracking: {
    marginRight: '100px !important',
  },
}));
