import React, { useCallback, useEffect, useState, Fragment } from 'react';
import clsx from 'clsx';
import { useNotify, useRefresh, required } from 'react-admin';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import debounce from 'lodash/debounce';
import _sortby from 'lodash.sortby';
import { Form } from 'react-final-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Drawer,
  Toolbar,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Chip,
  TextField,
  MenuItem,
  Checkbox,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { fileManagementActions } from '../../state/actions';
import {
  CloseIcon,
  MinimizeIcon,
  SendIcon,
  PersonIcon,
  AttachmentIcon,
  CustomButton,
} from '../../design';
import { useGetCaseId } from '../../hooks';
import { authGet, authPost } from '../../server';
import { isValidEmail, convertToObj, limitStringSize } from '../../utils';
import { Autocomplete } from '../wrappers';
import { Attachments } from './modals';
import { Loading } from './Loading';
import { ConfirmedActionModal } from './ConfirmedActionModal';
import {
  normalizePhone,
  NumberFormatter,
  AutocompleteInput,
} from './modals/modalForms/inputs';
import { TextEditor } from './TextEditor';
import { CoverLetter } from './coverLetter';
import { useStyles } from './fileManagementDraw.styles';
const {
  updateFileManagementData,
  resetFileManagementData,
} = fileManagementActions;

export function FileManagementDraw() {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const _caseId = useGetCaseId();
  const [submitting, setSubmitting] = useState(false);
  const [caseId, setCaseId] = useState(_caseId);
  const [caseName, setCaseName] = useState('');
  const [files, setFiles] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [faxNumId, setFaxNumId] = useState('');
  const [faxNumObj, setFaxNumObj] = useState({});
  const [subject, setSubject] = useState('');
  const [editorState, setEditorState] = useState('');
  const [attachmentsRequired, setAttachmentsRequired] = useState(null);
  const [attchOpen, setAttchModal] = useState(false);
  const [coverLetterOpen, setCoverLetterModal] = useState(false);
  const [coverLetterData, setCoverLetterdata] = useState();
  const [coverLetterDocId, setCoverLetterDocId] = useState();
  const [confirmBeforeSending, setConfirmBeforeSending] = useState(null);
  const facilityId = useSelector(state => state.facility.id);
  const [createTask, setCreateTask] = useState(false);
  const [taskDueDate, setTaskDueDate] = useState(undefined);
  const [completeTask, setCompleteTask] = useState(false);
  const [completionType, setCompletionType] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [updateId, setUpdateId] = useState(null);

  const { drawStatus, fileIds = [] } =
    useSelector(state => state.fileManagement?.activeFile, shallowEqual) || {};
  const firstName = useSelector(state => state.user.profile.first_name);
  const lastName = useSelector(state => state.user.profile.last_name);

  async function fetchCase(caseID) {
    if (!facilityId || !caseID) return;
    const response = await authGet(`/case-data/${caseID}`);
    if (response.error) return;
    const { case_name } = response.data;
    setCaseName(case_name);
  }

  const fetchDocs = useCallback(
    async (ids, coverLetterId, cover_data_id) => {
      const response = await authGet(['documents', { document_ids: ids }]);
      const { error, data } = response;
      let newFiles = [];
      if (!error) {
        setFiles(curFiles => {
          const sorted = keepSorted(curFiles, data, coverLetterId);
          newFiles = sorted;
          return sorted;
        });
        // This is need for updating the cover letter on submit
        // to ensure the correct documents are being submitted
        return { newFiles, cover_data_id };
      }
      notify(
        error.message ? error.message : 'Failed to get documents.',
        'warning',
      );
      return newFiles;
    },
    [notify],
  );

  useEffect(() => {
    if (facilityId) {
      if (fileIds.length) {
        fetchDocs(fileIds);
      } else {
        // clear the obj when the user removes all files
        setFiles([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId, fileIds, notify]);

  useEffect(() => {
    if (coverLetterData) {
      setCoverLetterModal(true);
    }
  }, [coverLetterData]);

  useEffect(() => {
    if (_caseId) {
      fetchCase(_caseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const minimized = drawStatus === 'minimized';

  const minimizeDraw = useCallback(() => {
    if (!minimized) {
      dispatch(updateFileManagementData({ drawStatus: 'minimized' }));
    }
  }, [dispatch, minimized]);

  const maximizeDraw = useCallback(() => {
    if (minimized) {
      dispatch(updateFileManagementData({ drawStatus: 'open' }));
    }
  }, [dispatch, minimized]);

  const onClose = useCallback(() => {
    dispatch(resetFileManagementData());
  }, [dispatch]);

  const handleDelete = useCallback((type, value) => {
    setAddresses(cur => cur.filter(c => c !== value));
  }, []);

  const handleDeleteFaxNum = useCallback(
    (type, value) => {
      setFaxNumId('');
      if (coverLetterDocId) {
        dispatch(
          fileManagementActions.removeEmailFileId(parseInt(coverLetterDocId)),
        );
      }
    },
    [coverLetterDocId, dispatch],
  );

  const fetchCoverLetter = useCallback(async () => {
    const response = await authGet([
      `/cases/${caseId}/cover-letter`,
      { document_id: coverLetterDocId, documents: fileIds },
    ]);
    const { data, error } = response;
    if (error) {
      return notify(error.message, 'warning');
    }
    return data;
  }, [caseId, coverLetterDocId, fileIds, notify]);

  const handleCoverLetter = useCallback(async () => {
    const data = await fetchCoverLetter();
    setCoverLetterdata(data);
  }, [fetchCoverLetter]);

  const updatePageCount = useCallback(async () => {
    const data = await fetchCoverLetter();
    if (!data || !faxNumObj[faxNumId]) return files;
    const { phone: _phone, ...restFaxData } = faxNumObj[faxNumId];
    const { facility, resident, ...otherData } = data;
    const {
      cm_phone: _cm_phone,
      sender_fax: _sender_fax,
      ...restFacility
    } = facility;
    const payload = {
      facility: {
        ...restFacility,
        cm_phone: normalizePhone(_cm_phone),
        sender_fax: normalizePhone(_sender_fax),
      },
      resident,
      faxNumData: {
        ...restFaxData,
        id: normalizePhone(faxNumObj[faxNumId].fax),
        phone: normalizePhone(_phone),
      },
      ...otherData,
      userFirstName: firstName,
      userLastName: lastName,
      prev_document_id: coverLetterDocId,
    };
    const response = await authPost(`/cases/${caseId}/cover-letter`, payload);
    const { data: newCoverData, error } = response;
    if (error) {
      notify('Unable to update the cover letter page count', 'warning');
      return files;
    }
    const { document_id, cover_data_id } = newCoverData;
    const newDocsIds = fileIds.filter(
      id => parseInt(id) !== parseInt(coverLetterDocId),
    );
    document_id && newDocsIds.unshift(document_id);
    return fetchDocs(newDocsIds, document_id, cover_data_id);
  }, [
    caseId,
    coverLetterDocId,
    faxNumId,
    faxNumObj,
    fetchCoverLetter,
    fetchDocs,
    fileIds,
    files,
    firstName,
    lastName,
    notify,
  ]);

  const handleTemplateSelection = useCallback(template => {
    setEditorState(template.template);
    setAttachmentsRequired(template.attachment_required);
  }, []);

  const send = useCallback(async () => {
    setSubmitting(true);
    let documents = files;
    let values = {};
    if (faxNumId && coverLetterDocId) {
      values = await updatePageCount();
      documents = values.newFiles;
    }
    const payload = {
      documents: documents ?? [],
      cover_data_id: values.cover_data_id,
      email_addresses: addresses,
      subject,
      fax_number: faxNumObj[faxNumId],
      text: editorState,
      userFirstName: firstName,
      userLastName: lastName,
      create_task: createTask,
      task_due_date: taskDueDate,
      case_id: caseId,
      completed_task_id: taskId,
      completed_update_id: updateId,
    };
    const response = await authPost(
      `/cases/${caseId}/send-communication`,
      payload,
    );
    setSubmitting(false);
    const { error } = response;
    if (error) {
      return notify(error.message, 'warning');
    }
    notify('Message sent');
    const locationArr = window.location.href.split('/');
    const pathName = locationArr[locationArr.length - 1];
    if (pathName?.includes('emails') || pathName === 'faxes-list') {
      refresh();
    }
    onClose();
  }, [
    addresses,
    caseId,
    coverLetterDocId,
    editorState,
    faxNumId,
    faxNumObj,
    files,
    firstName,
    lastName,
    notify,
    onClose,
    refresh,
    subject,
    updatePageCount,
    createTask,
    taskDueDate,
    taskId,
    updateId,
  ]);

  const handleSend = useCallback(() => {
    if (!!faxNumId && !coverLetterDocId) {
      setConfirmBeforeSending({
        text: 'Send fax without generating a cover letter?',
        confirmButtonText: 'Send Fax',
      });
    } else if (addresses.length > 0 && attachmentsRequired && !files.length) {
      setConfirmBeforeSending({
        text: 'Send email without any attachments?',
        confirmButtonText: 'Send Email',
      });
    } else if (addresses.length > 0 && !subject) {
      notify('Please add a subject line to the email');
    } else {
      send();
    }
  }, [
    subject,
    faxNumId,
    coverLetterDocId,
    addresses.length,
    attachmentsRequired,
    files.length,
    send,
    notify,
  ]);

  return (
    <Drawer
      open={true}
      onClose={onClose}
      anchor='bottom'
      disableScrollLock
      hideBackdrop
      disablePortal
      // variant='persistent'
      PaperProps={{ elevation: 5, square: false }}
      onClick={maximizeDraw}
      className={clsx({
        [classes.drawerOpen]: !minimized,
        [classes.fullHeight]: !!addresses.length || !!faxNumId,
        [classes.drawerMinimized]: minimized,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: !minimized,
          [classes.fullHeight]: !!addresses.length || !!faxNumId,
          [classes.drawerMinimized]: minimized,
        }),
      }}
    >
      <Toolbar
        classes={{
          regular: clsx(classes.toolbar, {
            [classes.toolbarMinimized]: minimized,
          }),
        }}
      >
        <span>{`New message - ${limitStringSize(caseName, 49, true)}`}</span>
        <div>
          <IconButton
            className={classes.iconBtn}
            size='small'
            onClick={minimizeDraw}
          >
            <MinimizeIcon />
          </IconButton>
          <IconButton
            className={classes.iconBtn}
            size='small'
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Toolbar>
      {!caseId && <CaseIdField setCaseId={setCaseId} fetchCase={fetchCase} />}
      {!minimized && (
        <Fragment>
          <div className={classes.content}>
            <Recipients
              caseId={caseId}
              setAddresses={setAddresses}
              type='email'
              facilityId={facilityId}
              disabled={!caseId}
            />
            <div style={{ padding: '20px 0px 0px 0px' }}>
              <TextField
                variant='outlined'
                fullWidth
                name='subject'
                value={subject}
                onChange={e => setSubject(e.target.value)}
                label='Subject'
                size='small'
                disabled={!caseId}
                required={!!addresses.length}
              />
            </div>
            {!!addresses.length && (
              <div style={{ margin: 10 }}>
                {addresses.map((a, i) => {
                  return (
                    <Chip
                      key={i}
                      label={a}
                      onDelete={() => handleDelete('email', a)}
                      className={classes.chip}
                      size='small'
                      disabled={!caseId}
                    />
                  );
                })}
              </div>
            )}
            <Recipients
              caseId={caseId}
              faxNumId={faxNumId}
              setFaxNumId={setFaxNumId}
              setFaxNumObj={setFaxNumObj}
              faxNumObj={faxNumObj}
              type='fax'
              facilityId={facilityId}
              setCoverLetterDocId={setCoverLetterDocId}
              disabled={!caseId}
            />
            {!!faxNumId && (
              <div style={{ margin: 10 }}>
                <Chip
                  label={normalizePhone(faxNumObj[faxNumId].fax)}
                  onDelete={handleDeleteFaxNum}
                  className={classes.chip}
                  size='small'
                  disabled={!caseId}
                />
              </div>
            )}
            <EmailTemplatesSelect
              caseId={caseId}
              onSelection={handleTemplateSelection}
              disabled={!caseId}
            />
            <TextEditor
              editorState={editorState}
              setEditorState={setEditorState}
              height='30vh'
              disabled={!caseId}
            />
            {!!files.length && (
              <FilesList
                files={files}
                setFiles={setFiles}
                setCoverLetterDocId={setCoverLetterDocId}
                coverLetterDocId={coverLetterDocId}
              />
            )}
          </div>
          {!!faxNumId && (
            <div style={{ padding: '20px 0px 0px 16px' }}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleCoverLetter}
                disabled={!caseId}
              >
                {!!coverLetterDocId
                  ? 'Edit Cover Letter'
                  : 'Generate Cover Letter'}
              </Button>
            </div>
          )}
          {submitting && <Loading style={{ height: 'initial' }} />}
          <div style={{ padding: '20px 0px 0px 16px' }}>
            <Checkbox
              color='primary'
              checked={createTask}
              onChange={() => setCreateTask(!createTask)}
              disabled={!caseId}
            />
            Create follow-up task
            {createTask && (
              <div
                style={{
                  padding: '10px 0px 0px 16px',
                }}
              >
                <TextField
                  variant='outlined'
                  name='task_due_date'
                  type='date'
                  label='Select task due date'
                  required
                  validate={required()}
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={taskDueDate}
                  onChange={e => setTaskDueDate(e.target.value)}
                  disabled={!caseId}
                />
              </div>
            )}
          </div>
          <div style={{ padding: '20px 0px 0px 16px' }}>
            <Checkbox
              color='primary'
              checked={completeTask}
              onChange={() => setCompleteTask(!completeTask)}
              disabled={!caseId}
            />
            Complete task or update
            {completeTask && (
              <Fragment>
                <div
                  style={{
                    paddingLeft: '16px',
                    paddingBottom: '10px',
                  }}
                >
                  <CompletionToggle
                    setState={setCompletionType}
                    curValue={completionType}
                  />
                </div>

                {completionType && (
                  <TaskUpdateSelect
                    caseId={caseId}
                    type={completionType}
                    setTaskId={setTaskId}
                    setUpdateId={setUpdateId}
                  />
                )}
              </Fragment>
            )}
          </div>

          <div className={classes.sendContainer}>
            <Button
              color='primary'
              variant='contained'
              endIcon={<SendIcon />}
              onClick={handleSend}
              disabled={
                submitting ||
                (!faxNumId && !addresses.length) ||
                (createTask && !taskDueDate)
              }
            >
              Send
            </Button>
            <CustomButton
              Icon={AttachmentIcon}
              color='#829CB5'
              style={{ cursor: 'pointer' }}
              variant='text'
              size='large'
              badgeContent={files.length}
              notRed
              fullSize
              onClick={() => caseId && setAttchModal(true)}
            />
          </div>
          {attchOpen && (
            <Attachments
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              title='Add File'
              caseId={caseId}
              refresh={() => {}}
              getAllCaseFiles
              attach
            />
          )}
          {coverLetterOpen && (
            <CoverLetter
              {...coverLetterData}
              faxNumData={faxNumObj[faxNumId]}
              open={coverLetterOpen}
              onClose={() => setCoverLetterModal(false)}
              caseId={caseId}
              setCoverLetterDocId={setCoverLetterDocId}
              coverLetterDocId={coverLetterDocId}
            />
          )}
          <ConfirmedActionModal
            {...confirmBeforeSending}
            open={!!confirmBeforeSending}
            onClose={() => setConfirmBeforeSending(false)}
            onConfirm={send}
          />
        </Fragment>
      )}
    </Drawer>
  );
}

function CaseIdField({ setCaseId, fetchCase }) {
  function handleChange(value) {
    setCaseId(value);
    fetchCase(value);
  }

  return (
    <div
      style={{ marginLeft: -3, marginRight: 3, padding: '20px 16px 0px 16px' }}
    >
      <Form
        onSubmit={() => {}}
        render={() => (
          <AutocompleteInput
            reference='cases/list'
            customOnChange={handleChange}
            name='case_id'
            label='Case'
            required
            fullWidth
            openOnFocus
            autocompleteProps={{ openOnFocus: true }}
            options={{
              filter: { division: 'all', active: 0 },
            }}
          />
        )}
      />
    </div>
  );
}

function Recipients({
  caseId,
  setAddresses,
  setFaxNumId,
  faxNumId,
  setFaxNumObj,
  faxNumObj,
  type,
  facilityId,
}) {
  const classes = useStyles();
  const [isNew, setIsNew] = useState(false);
  const [manualAddress, setManualAddress] = useState('');
  const [manualFax, setManualFax] = useState({ id: '', name: '' });
  const [data, setData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    async function fetchList() {
      const response =
        type === 'email'
          ? await authGet(`/cases/${caseId}/email-recipients/list`)
          : await authGet(`/cases/${caseId}/fax-recipients/list`);
      const { data, error } = response;
      if (!error) {
        setData(data);
        setSuggestions(data.slice(0, 5));
        if (type === 'fax') {
          setFaxNumObj(cur => ({ ...cur, ...convertToObj(data) }));
        }
      }
    }
    if (facilityId && caseId) {
      fetchList();
    }
  }, [facilityId, caseId, type, setFaxNumObj]);

  function getSuggestions(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = value.length;
    let count = 0;

    const s =
      inputLength === 0
        ? data.slice(0, 5)
        : data.filter(suggestion => {
            const keep =
              count < 5 && suggestion.name.toLowerCase().includes(inputValue);
            if (keep) {
              count += 1;
            }
            return keep;
          });
    setSuggestions(s);
  }

  const debounceFilter = debounce(value => {
    getSuggestions(value);
  }, 300);

  const onChange = useCallback(
    e => {
      const { value } = e.target;
      debounceFilter(value);
    },
    [debounceFilter],
  );

  const onClick = useCallback(
    value => {
      if (value !== 'new') {
        if (type === 'email') {
          const split = value.split(',');
          const validEmails = split.filter(e => isValidEmail(e));
          setAddresses(curAddresses =>
            Array.from(new Set([...curAddresses, ...validEmails])),
          );
        } else {
          if (
            !!faxNumObj[value]?.fax &&
            (faxNumObj[value].fax + '').length === 10
          ) {
            setFaxNumId(value);
          }
        }
        setSuggestions(data.slice(0, 5));
      } else {
        setIsNew(true);
      }
    },
    [data, setAddresses, faxNumObj, setFaxNumId, type],
  );

  const onEmailBlur = useCallback(
    e => {
      onClick(e.target.value);
      setManualAddress('');
      setIsNew(false);
    },
    [onClick],
  );

  const onEmailKeyPress = useCallback(
    e => {
      if (e.keyCode === 13) {
        onEmailBlur(e);
      }
    },
    [onEmailBlur],
  );

  const onManualFaxChange = useCallback(e => {
    const { name, value } = e.target;
    const obj = { [name]: value };
    if (name === 'id') {
      obj.fax = value;
    }
    setManualFax(m => ({ ...m, ...obj }));
  }, []);

  const onFaxBlur = useCallback(
    e => {
      const { id, name } = manualFax;
      if (name && id) {
        setFaxNumObj(f => ({
          ...f,
          [id]: { name, fax: id, id },
        }));
        if (id.length === 10) {
          setFaxNumId(id);
        }
        setManualFax({ id: '', name: '', fax: '' });
        setIsNew(false);
      }
    },
    [manualFax, setFaxNumId, setFaxNumObj],
  );

  const onFaxKeyPress = useCallback(
    e => {
      if (e.keyCode === 13) {
        onFaxBlur(e);
      }
    },
    [onFaxBlur],
  );

  const displayListItem = useCallback(
    value => {
      const { name, id, company } = value;
      return id !== 'new' ? (
        <ListItem dense>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={[name, company].filter(x => !!x).join(' - ')}
            secondary={
              type === 'fax' ? normalizePhone(faxNumObj?.[id]?.fax) : id
            }
            classes={{ multiline: classes.listLine }}
          />
        </ListItem>
      ) : (
        <ListItem dense>{name}</ListItem>
      );
    },
    [type, faxNumObj, classes.listLine],
  );

  const cancelManual = useCallback(e => {
    e.stopPropagation();
    setIsNew(false);
  }, []);

  return (
    <div
      style={{
        marginBottom: type === 'fax' ? 15 : undefined,
        padding: '20px 0px 0px 0px',
      }}
    >
      <div
        style={{ display: type === 'email' ? 'flex' : 'block', width: '100%' }}
      >
        <Autocomplete
          onChange={onChange}
          suggestions={[{ id: 'new', name: 'Add new' }, ...suggestions]}
          parentOnClick={onClick}
          classes={{
            root: clsx({ [classes.inputRoot]: isNew && type === 'email' }),
            // input: clsx({ [classes.autocompleteRoot]: isNew }),
          }}
          openOnFocus
          label={`Select ${type}`}
          variant='outlined'
          displayListItem={displayListItem}
          clearInputOnClick
          fullWidth={!isNew || type === 'fax'}
          paperClassName={classes.addressPaper}
          // disabled={type === 'fax'}
          disabled={!caseId || (type === 'fax' && !!faxNumId)}
          otherInputProps={{ size: 'small' }}
        />
        {isNew && type === 'email' && (
          <TextField
            variant='outlined'
            autoFocus
            name='manualAddress'
            value={manualAddress}
            onChange={e => setManualAddress(e.target.value)}
            autoComplete='email'
            onBlur={onEmailBlur}
            onKeyDown={onEmailKeyPress}
            label='Enter Email'
            classes={{ root: classes.inputRoot }}
            size='small'
            InputProps={{
              endAdornment: (
                <CloseIcon
                  onMouseDown={cancelManual}
                  style={{ cursor: 'pointer' }}
                />
              ),
            }}
          />
        )}
        {isNew && type === 'fax' && (
          <div
            className={classes.faxInputs}
            style={{ padding: '20px 0px 0px 0px' }}
          >
            <TextField
              variant='outlined'
              autoFocus
              name='id'
              value={manualFax.id}
              onChange={onManualFaxChange}
              onBlur={onFaxBlur}
              label='Enter fax number'
              classes={{ root: classes.inputRoot }}
              size='small'
              InputProps={{
                inputComponent: NumberFormatter,
                inputProps: { format: 'phone' },
              }}
            />
            <TextField
              variant='outlined'
              name='name'
              value={manualFax.name}
              onChange={onManualFaxChange}
              onBlur={onFaxBlur}
              onKeyDown={onFaxKeyPress}
              label='Enter name'
              classes={{ root: classes.inputRoot }}
              size='small'
              InputProps={{
                endAdornment: (
                  <CloseIcon
                    onMouseDown={cancelManual}
                    style={{ cursor: 'pointer' }}
                  />
                ),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function FilesList({ files, setFiles, coverLetterDocId, setCoverLetterDocId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const removeDocument = useCallback(
    id => {
      dispatch(fileManagementActions.removeEmailFileId(id));
      if (id === coverLetterDocId) {
        setCoverLetterDocId(undefined);
      }
    },
    [coverLetterDocId, dispatch, setCoverLetterDocId],
  );

  const viewFile = useCallback(
    async id => {
      const response = await authGet(`/documents/url/${id}`);
      if (response.error) {
        notify(response.error.message, 'warning');
      }
      const win = window.open(response.data.url, '_blank');
      win.focus();
    },
    [notify],
  );

  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const items = reorder(
        files,
        result.source.index,
        result.destination.index,
      );
      setFiles(items);
    },
    [files, setFiles],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <List
            dense
            style={{ maxWidth: 448 }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {files.map((f, i) => {
              const { id: _id, document_name } = f;
              const id = `${_id}`;
              return (
                <Draggable key={id} draggableId={id} index={i}>
                  {(provided, snapshot) => (
                    <ListItem
                      onClick={() => viewFile(id)}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ListItemText
                        primary={document_name}
                        color='primary'
                        className={classes.attText}
                      />
                      <ListItemSecondaryAction>
                        <CloseIcon
                          style={{ cursor: 'pointer ' }}
                          onClick={() => removeDocument(id)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}

// a little function to help us with reordering the files
function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function keepSorted(curFiles = [], newFiles = [], coverLetterId) {
  if (!curFiles.length) return newFiles;
  const sortedByIds = curFiles
    .filter(f => +f.id !== coverLetterId)
    .map(f => f.id);
  if (coverLetterId) sortedByIds.unshift(+coverLetterId);
  const last = newFiles.length;
  return _sortby(newFiles, item =>
    sortedByIds.indexOf(item.id) !== -1 ? sortedByIds.indexOf(item.id) : last,
  );
}

function EmailTemplatesSelect({ onSelection, caseId, disabled }) {
  const [templateList, setTemplateList] = useState([]);

  useEffect(() => {
    (async function() {
      const response = await authGet([
        '/communication-template/list',
        { communication_type: 'email' },
      ]);
      const { error, data } = response;
      if (!error) {
        setTemplateList(data);
      }
    })();
  }, []);

  const handleClick = templateId => {
    if (templateId === null) return;
    (async function() {
      const response = await authGet(
        `/cases/${caseId}/communication-template/${templateId}`,
      );
      const { error, data } = response;
      if (!error) {
        onSelection(data);
      }
    })();
  };

  return (
    <TextField
      variant='outlined'
      select
      fullWidth
      label='Select email template'
      size='small'
      defaultValue=''
      style={{ marginBottom: 10 }}
      disabled={disabled}
    >
      {templateList.map(t => {
        const { id, name } = t;
        return (
          <MenuItem key={id} value={id} onClick={() => handleClick(id)}>
            {name}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

function TaskUpdateSelect({ setTaskId, setUpdateId, caseId, type }) {
  const [taskUpdateList, setTaskUpdateList] = useState([]);

  useEffect(() => {
    (async function() {
      const response =
        type === 'task'
          ? await authGet(['/open-tasks/list', { case_id: caseId }])
          : await authGet(['/open-updates/list', { case_id: caseId }]);
      const { error, data } = response;
      if (!error) {
        setTaskUpdateList(data);
      } else {
        setTaskUpdateList([]);
      }
    })();
  }, [caseId, type]);

  const handleClick = id => {
    type === 'task' ? setTaskId(id) : setUpdateId(id);
  };
  return (
    <TextField
      disabled={!caseId}
      style={{ marginBottom: -3, marginLeft: 15, width: 400 }}
      variant='outlined'
      select
      fullWidth
      label={`Select ${type}`}
      size='small'
      defaultValue=''
    >
      {taskUpdateList.map(t => {
        const { id, name } = t;
        return (
          <MenuItem key={id} value={id} onClick={() => handleClick(id)}>
            {name}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export function CompletionToggle({ setState, curValue }) {
  const handleChange = useCallback(
    e => {
      setState(e.target.value);
    },
    [setState],
  );

  return (
    <FormControl component='fieldset'>
      <RadioGroup value={curValue} onChange={handleChange} row>
        <FormControlLabel value='task' control={<Radio />} label='Task' />
        <FormControlLabel value='update' control={<Radio />} label='Update' />
      </RadioGroup>
    </FormControl>
  );
}
