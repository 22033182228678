import React, { useState, useEffect, useCallback, Fragment } from 'react';
import qs from 'query-string';
import { useSelector } from 'react-redux';

import clsx from 'clsx';
// import { format } from 'date-fns';
import { Form } from 'react-final-form';
import {
  useNotify,
  useRedirect,
  useDataProvider,
  useRefresh,
  required,
  cacheDataProviderProxy,
} from 'react-admin';
import { IconButton, FormGroup } from '@material-ui/core';
import {
  AutocompleteInput,
  FormTextField,
  SelectComponent,
  CheckboxInput,
  FileInput,
  handleNumbers,
  getDateInputValue,
  ReferenceInput,
  composeValidators,
  validateDate,
  maxTextLength,
} from '../components/common/modals/modalForms';
import { Attachments } from '../components/common/modals';
import { Loader } from '../components/common';
import { authGet } from '../server';
import { useStyles } from './progressForm.styles';
import { SaveButton, CloseIcon, AttachmentIcon, CustomButton } from '../design';

export const ProgressForm = ({ location }) => {
  const facilityId = useSelector(state => state.facility.id);
  const { pathname, search } = location;
  const { authId, nextUpdate, previousPath, progressId, title } = qs.parse(
    search,
  );
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const refresh = useRefresh();
  const [document_ids, setDocument_ids] = useState([]);
  const [originalFormData, setOriginalFormData] = useState({});
  const [attchOpen, setAttchModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const routeArr = pathname.split('/');
  const caseId = !isNaN(routeArr[2]) ? routeArr[2] : undefined;
  const isCaseLevel = routeArr[1] === 'cases' && caseId;
  const redirectTo = previousPath
    ? previousPath
    : isCaseLevel
    ? `/cases/${caseId}/updates/list`
    : '/updates';
  const [formData, setFormData] = useState({ case_id: caseId });

  const fetchForm = useCallback(async () => {
    const { update_due, authorization_id } = formData;
    if (!progressId && !nextUpdate && (!update_due || !authorization_id)) {
      return;
    }
    const url = progressId ? `/progress/${progressId}` : '/progress-report';
    setLoading(true);
    const response = await authGet(
      progressId
        ? url
        : [
            url,
            { date: nextUpdate || update_due, id: authId || authorization_id },
          ],
    );
    if (response.error) {
      notify(response.error.message, 'warning');
      setLoading(false);
      redirect(redirectTo);
      return;
    }
    const { document_ids, ...rest } = response.data;
    setFormData(f => ({
      ...f,
      ...rest,
      isComplete:
        response.data.status &&
        response.data.status.toLowerCase() === 'complete',
    }));
    setOriginalFormData(response.data);
    setDocument_ids(document_ids || []);
    setLoading(false);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authId,
    notify,
    progressId,
    redirectTo,
    nextUpdate,
    redirect,
    formData.authorization_id,
    formData.update_due,
  ]);

  useEffect(() => {
    if (facilityId) {
      fetchForm();
    }
  }, [caseId, isCaseLevel, fetchForm, nextUpdate, facilityId]);
  const handleClose = () => {
    redirect(redirectTo);
    refresh();
  };

  const onSubmit = value => {
    const { isComplete, status: _status, id, ...rest } = value;
    const status = _status ? _status.toLowerCase() : null;
    if (progressId) {
      return dataProvider
        .update('progress-report', {
          id,
          data: {
            ...rest,
            status,
          },
          previousData: { ...originalFormData },
        })
        .then(({ data }) => {
          notify('Form successfully submitted');
          handleClose();
        })
        .catch(error => notify(error.message, 'warning'));
    } else {
      return dataProvider
        .create('progress-report', {
          data: {
            ...rest,
            status: status === 'not started' ? 'in progress' : status,
          },
        })
        .then(({ data }) => {
          notify('Form successfully submitted');
          handleClose();
        })
        .catch(error => notify(error.message, 'warning'));
    }
  };

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    if (name === 'isComplete') {
      const status = value ? 'Complete' : 'In progress';
      setFormData({ ...formData, isComplete: value, status });
      return;
    }

    if (name === 'update_due' || name === 'authorization_id') {
      const { update_due, authorization_id } = formData;
      const fields = {
        update_due,
        authorization_id,
        [name]: value,
      };
      if (fields.update_due && fields.authorization_id) {
        await fetchForm();
        setFormData({ ...formData, [name]: value });
      }
      setFormData({ ...formData, [name]: value });
      return;
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <div className={classes.form}>
      <div className={classes.titleContainer}>
        <span className={classes.title}>{title || 'Progress report'}</span>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {!authId && !progressId && (
                <Fragment>
                  {!caseId && (
                    <div style={{ paddingBottom: 30 }}>
                      <AutocompleteInput
                        reference='cases/list'
                        customOnChange={customOnChange}
                        name='case_id'
                        label='Case'
                        validate={required()}
                        required
                        fullWidth
                        openOnFocus
                        autocompleteProps={{ openOnFocus: true }}
                        options={{
                          filter: { division: 'all', active: 1 },
                        }}
                      />
                    </div>
                  )}
                  {values.case_id >= 0 && (
                    <div className={clsx(classes.inputContainerWrap)}>
                      <ReferenceInput
                        reference='auth-number/list'
                        customOnChange={customOnChange}
                        name='authorization_id'
                        label='Auth number'
                        className={classes.inputCell}
                        validate={required()}
                        required
                        options={{
                          filter: { case_id: values.case_id },
                        }}
                      />
                      <FormTextField
                        name='update_due'
                        className={classes.inputCell}
                        validate={composeValidators([
                          required(),
                          validateDate(),
                        ])}
                        required
                        customOnChange={customOnChange}
                        type='date'
                        label='Date due'
                        style={{ marginRight: 0 }}
                      />
                    </div>
                  )}
                </Fragment>
              )}
              {(authId ||
                progressId ||
                (formData.authorization_id && formData.update_due)) && (
                <Fragment>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      name='start_date'
                      customOnChange={customOnChange}
                      type='date'
                      label='Start date'
                      className={classes.inputCell}
                      validate={composeValidators([required(), validateDate()])}
                      required
                    />
                    <FormTextField
                      name='end_date'
                      customOnChange={customOnChange}
                      type='date'
                      label='End date'
                      className={classes.inputCell}
                      validate={composeValidators([required(), validateDate()])}
                      required
                      style={{ marginRight: 0 }}
                    />
                  </div>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      name='status'
                      customOnChange={customOnChange}
                      label='Status'
                      className={classes.inputCell}
                      disabled
                    />
                    <FormTextField
                      name='submitted_date'
                      customOnChange={customOnChange}
                      type='date'
                      validate={composeValidators([validateDate()])}
                      label='Submitted to insurance date'
                      className={classes.inputCell}
                      style={{ marginRight: 0 }}
                    />
                  </div>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <SelectComponent
                      name='submitted_via'
                      customOnChange={customOnChange}
                      validate={values.submitted_date ? required() : undefined}
                      required={values.submitted_date ? true : false}
                      label='Submitted via'
                      choices={submitViaList}
                      className={classes.inputCell}
                      style={{
                        marginRight:
                          !values.submitted_via ||
                          values.submitted_via === 'phone' ||
                          values.submitted_via === 'portal'
                            ? 0
                            : undefined,
                      }}
                    />

                    {(values.submitted_via === 'fax' ||
                      values.submitted_via === 'email') &&
                    values.case_id >= 0 ? (
                      <ReferenceInput
                        key={[values.case_id, values.submitted_via]}
                        reference='fax-email/list'
                        customOnChange={customOnChange}
                        name='email_log_id'
                        label={`Submitted ${values.submitted_via}`}
                        options={{
                          filter: {
                            case_id: values.case_id,
                            type: values.submitted_via,
                          },
                        }}
                        className={classes.inputCell}
                        style={{ marginRight: 0 }}
                      />
                    ) : values.submitted_via === 'other' ? (
                      <FormTextField
                        name='submitted_via_notes'
                        customOnChange={customOnChange}
                        label='Submit notes'
                        className={classes.inputCell}
                        style={{ marginRight: 0 }}
                      />
                    ) : (
                      <div className={classes.inputCell} />
                    )}
                  </div>
                  <CheckboxInput
                    name='isComplete'
                    customOnChange={customOnChange}
                    label='Is this completed?'
                    checked={formData.status === 'Complete'}
                    style={{
                      flex: '1 0 235px',
                      marginRight: 25,
                      marginBottom: 26,
                    }}
                  />
                  <FormTextField
                    label='Comments'
                    name='comments'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    className={classes.inputCellFullWidth}
                  />

                  {!!document_ids.length && (
                    <CustomButton
                      Icon={AttachmentIcon}
                      color='#829CB5'
                      label='View attachments'
                      type='button'
                      variant='text'
                      size='small'
                      badgeContent={document_ids.length}
                      onClick={() => setAttchModal(true)}
                      notRed
                    />
                  )}
                  <div className={classes.insuranceTitle}>
                    Attachments
                    <FileInput
                      title='title'
                      name='attachments'
                      setFormData={setFormData}
                      formData={formData}
                      classes={{ dropZone: classes.dropZone }}
                    />
                  </div>
                  <FormTextField
                    name='plof'
                    label='PLOF'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    className={classes.inputCellFullWidth}
                  />
                  <div className={classes.sectionHeader}>Rehab details</div>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      name='pt_minutes_week'
                      customOnChange={customOnChange}
                      label='PT weekly minutes'
                      className={classes.inputCell}
                      type='number'
                    />
                    <FormTextField
                      name='ot_minutes_week'
                      customOnChange={customOnChange}
                      label='OT weekly minutes'
                      className={classes.inputCell}
                      type='number'
                    />
                    <FormTextField
                      name='slp_minutes_week'
                      customOnChange={customOnChange}
                      label='SLP weekly minutes'
                      className={classes.inputCell}
                      type='number'
                    />
                  </div>
                  <div className={classes.sectionHeader}>Nursing details</div>

                  <FormGroup row>
                    <CheckboxInput
                      name='vent'
                      customOnChange={customOnChange}
                      label='Vent'
                      // className={classes.inputCell}
                      checked={formData.vent}
                    />
                    <CheckboxInput
                      name='g_tube'
                      customOnChange={customOnChange}
                      label='G-Tube'
                      // className={classes.inputCell}
                      checked={formData.g_tube}
                    />
                    <CheckboxInput
                      name='iv'
                      customOnChange={customOnChange}
                      label='IV'
                      checked={formData.iv}
                    />
                  </FormGroup>
                  {values.iv && (
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='iv_start_date'
                        customOnChange={customOnChange}
                        type='date'
                        validate={composeValidators([validateDate()])}
                        label='IV start date'
                        className={classes.inputCell}
                      />
                      <FormTextField
                        name='iv_end_date'
                        customOnChange={customOnChange}
                        type='date'
                        validate={composeValidators([validateDate()])}
                        label='IV end date'
                        className={classes.inputCell}
                        style={{ marginRight: 0 }}
                      />
                      <FormTextField
                        name='iv_notes'
                        label='IV notes'
                        multiline
                        fullWidth
                        customOnChange={customOnChange}
                        className={classes.inputCellFullWidth}
                      />
                    </div>
                  )}
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      name='wound_count'
                      customOnChange={customOnChange}
                      label='# of wounds'
                      className={classes.inputCell}
                      type='number'
                    />
                    <FormTextField
                      name='wound_area'
                      customOnChange={customOnChange}
                      label='Wound area'
                      className={classes.inputCell}
                      style={{ marginRight: 0 }}
                    />
                  </div>
                  <FormTextField
                    name='wound_comment'
                    label='Wound comments'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    className={classes.inputCellFullWidth}
                    validate={composeValidators([
                      maxTextLength(
                        255,
                        'Please enter a comment less than 255 characters',
                      ),
                    ])}
                  />
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      label='Weight bearing status'
                      customOnChange={customOnChange}
                      name='weight_bearing_status'
                      className={classes.inputCell}
                    />
                    <FormTextField
                      label='Weight bearing area'
                      customOnChange={customOnChange}
                      name='weight_bearing_area'
                      className={classes.inputCell}
                      style={{ marginRight: 0 }}
                    />
                  </div>
                  <FormTextField
                    label='Weight bearing comments'
                    name='weight_bearing_comment'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    className={classes.inputCellFullWidth}
                  />
                  <FormTextField
                    label='Pain level, location & treatment'
                    name='pain_level'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    className={classes.inputCellFullWidth}
                  />
                  <FormTextField
                    label='Misc. other daily skilled nursing needs'
                    name='misc_nursing'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    className={classes.inputCellFullWidth}
                  />
                  <div
                    className={clsx(
                      classes.sectionHeader,
                      classes.sectionHeaderMargin50,
                    )}
                  >
                    PT performance section
                  </div>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Bed mobility'
                      name='bed_mobility'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Transfers'
                      name='transfers'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />

                    <SelectComponent
                      customOnChange={customOnChange}
                      label='W/C mobility'
                      name='wc_mobility'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />
                  </div>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      label='Stairs'
                      customOnChange={customOnChange}
                      name='stair_mobility'
                      className={classes.inputCell}
                    />
                    <FormTextField
                      label='Ambulation'
                      customOnChange={customOnChange}
                      name='ambulation'
                      className={classes.inputCell}
                    />
                    <FormTextField
                      label='Range of motion'
                      customOnChange={customOnChange}
                      name='range_of_motion'
                      className={classes.inputCell}
                    />
                    <FormTextField
                      label='Assistive device in facility'
                      customOnChange={customOnChange}
                      name='assistive_device'
                      className={classes.inputCell}
                    />
                    <div className={classes.inputCell} />
                    <div className={classes.inputCell} />
                  </div>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      label='Short term PT goals'
                      name='st_pt_goals'
                      multiline
                      fullWidth
                      customOnChange={customOnChange}
                      className={classes.inputCellFullWidth}
                    />
                    <FormTextField
                      label='Long term PT goals'
                      name='lt_pt_goals'
                      multiline
                      fullWidth
                      customOnChange={customOnChange}
                      className={classes.inputCellFullWidth}
                    />
                  </div>
                  <div
                    className={clsx(
                      classes.sectionHeader,
                      classes.sectionHeaderMargin50,
                    )}
                  >
                    OT performance section
                  </div>
                  <div
                    className={clsx(
                      classes.inputContainerWrap,
                      classes.negativeMargin,
                    )}
                  >
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Feeding'
                      name='feeding'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Grooming'
                      name='grooming'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Bathing'
                      name='bathing'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Dressing - upper body'
                      name='dressing_upper_body'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Dressing - lower body'
                      name='dressing_lower_body'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Toileting/hygiene'
                      name='toileting_hygiene'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Toileting/transfer'
                      name='toileting_transfer'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Transfer tub/shower'
                      name='transfer_tub'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Household management'
                      name='home_management'
                      choices={ADLSList}
                      className={classes.inputCell}
                    />
                  </div>
                  <FormTextField
                    label='Short term OT goals'
                    name='st_ot_goals'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    className={classes.inputCellFullWidth}
                  />
                  <FormTextField
                    label='Long term OT goals'
                    name='lt_ot_goals'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    className={classes.inputCellFullWidth}
                  />
                  <div className={classes.sectionHeader}>Speech therapy</div>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Diet'
                      name='diet'
                      choices={dietList}
                      className={classes.inputCell}
                    />
                    <FormTextField
                      label='Cognition'
                      customOnChange={customOnChange}
                      name='cognition'
                      className={classes.inputCell}
                      style={{ marginRight: 0 }}
                    />
                  </div>
                  <FormTextField
                    label='SLP notes'
                    name='slp_notes'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    className={classes.inputCellFullWidth}
                  />
                  <FormTextField
                    label='Short term SLP goals'
                    name='st_slp_goals'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    className={classes.inputCellFullWidth}
                  />
                  <FormTextField
                    label='Long term SLP goals'
                    name='lt_slp_goals'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    className={classes.inputCellFullWidth}
                  />
                  <div
                    className={clsx(
                      classes.sectionHeader,
                      classes.sectionHeaderMargin50,
                    )}
                  >
                    Standardized test
                  </div>
                  <div
                    className={clsx(
                      classes.inputContainerWrap,
                      classes.negativeMargin,
                    )}
                  >
                    <FormTextField
                      label='Score '
                      customOnChange={customOnChange}
                      name='score'
                      className={classes.inputCell}
                    />
                    <FormTextField
                      label='Name of test'
                      customOnChange={customOnChange}
                      name='test_name'
                      className={classes.inputCell}
                    />
                    <FormTextField
                      label='Explanation'
                      customOnChange={customOnChange}
                      name='test_detail'
                      className={classes.inputCell}
                    />
                  </div>

                  <div
                    className={classes.saveButtonContainer}
                    style={{
                      alignItems: 'right',
                      display: 'flex',
                    }}
                  >
                    {' '}
                    <div className={classes.inputCell} />
                    <SaveButton
                      className={classes.saveButton}
                      disabled={submitting}
                      type='submit'
                      size='large'
                    />
                  </div>
                </Fragment>
              )}
            </form>
          );
        }}
      />
      <Loader open={loading} />
      {attchOpen && (
        <Attachments
          open={attchOpen}
          handleClose={() => setAttchModal(false)}
          document_ids={document_ids}
          title='Progress'
          eventId={originalFormData.id}
          eventName='progress'
          caseId={originalFormData.case_id}
          refresh={refresh}
          add={false}
          actionMessage={{
            type: 'warning',
            msg: "The attachment will be deleted if you don't save the form.",
          }}
        />
      )}
    </div>
  );
};

const ADLSList = [
  { id: 'I/MI', name: 'I/MI' },
  { id: 'S/SBA', name: 'S/SBA' },
  { id: 'CG', name: 'CG' },
  { id: 'Min A', name: 'Min A' },
  { id: 'Mod A', name: 'Mod A' },
  { id: 'Max A', name: 'Max A' },
  { id: 'Dep', name: 'Dep' },
];

const dietList = [
  { id: 'liquid', name: 'Liquid' },
  { id: 'mech', name: 'Mech' },
  { id: 'soft', name: 'Soft' },
  { id: 'puree', name: 'Puree' },
  { id: 'regular', name: 'Regular' },
  { id: 'enteral', name: 'Enteral' },
];

const submitViaList = [
  { id: 'fax', name: 'Fax' },
  { id: 'email', name: 'Email' },
  { id: 'phone', name: 'Phone' },
  { id: 'portal', name: 'Portal' },
  { id: 'other', name: 'Other' },
];
