import { makeStyles } from '@material-ui/core';
import { styles, colors, chipStyles } from '../../design';
// import { maxWidth } from '@material-ui/system';
export const useStyles = makeStyles(theme => ({
  sidebar: {
    height: '100vh',
    minHeight: '100vh',
    position: 'fixed',
    overflowY: 'auto',
    width: 240,
    backgroundColor: '#FFFFFF',
    zIndex: 10,
  },

  facilitySidebarList: {
    backgroundColor: '#FFFFFF',
    minHeight: 760,
    marginTop: 8,
  },

  marginTop: {
    marginTop: 40,
    zIndex: 2,
  },
  rootCases: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    width: 239,
    backgroundColor: '#FFFFFF',
    paddingTop: 0,
    height: 'calc(100vh - 49px)',
    minHeight: 'calc(100vh - 49px)',
    ...styles.flexColumn,
    ...styles.justifyBetween,
    alignItems: 'flex-start',
    top: 48,
    '& .MuiPaper-root': {
      height: '100%',
      marginTop: 0,
    },
  },
  casesList: {
    paddingBottom: 0,
  },
  iconContainer: {
    display: 'inline-flex',
    minWidth: 40,
    flexShrink: 0,
  },
  withCaseContainer: {
    paddingTop: 0,
  },
  gutters: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  casesContainer: {
    backgroundColor: '#FFFFFF',
    ...styles.flexRow,
    minWidth: '100%',
    height: 75,
    ...styles.cursorPointer,
  },
  selectedCaseContainer: {
    backgroundColor: '#FFFFFF',
    ...styles.flexRow,
    minWidth: '100%',
    height: 75,
    ...styles.cursorPointer,
    paddingBottom: '10px',
  },
  casesAbsolute: {
    position: 'fixed',
    top: 43,
    zIndex: 100,
    width: 239,
    backgroundColor: colors.white,
    maxHeight: 'calc(100vh - 25px)',
    overflow: 'auto',
    borderRight: '.05px solid #E0E0E0',
    borderBottom: '1px solid #C5DAF1',
  },
  allCases: {
    width: 239,
    ...styles.flexRow,
    ...styles.justifyBetween,
    fontSize: 14,
    fontWeight: 300,
    paddingLeft: 30,
    paddingTop: 10,
    paddingBottom: 5,
    ...styles.cursorPointer,
    color: theme.palette.text.secondary,
  },
  casesNameContainer: {
    ...styles.flexColumn,
    ...styles.justifyCenter,
    alignItems: 'flex-start',
  },
  caseTitle: {
    fontSize: 13,
    fontWeight: 600,
  },
  avatar: {
    marginRight: 15,
  },
  navBack: {
    ...styles.flexRow,
    ...styles.justifystart,
    paddingLeft: 16,
    paddingBottom: 30,
  },
  backIcon: {
    marginRight: 30,
  },
  chip: {
    height: 17,
    width: 64,
    color: '#1061A0',
    backgroundColor: '#EFF4FB',
    fontSize: 10,
    fontWeight: 300,
    borderRadius: 0,
  },
  ...chipStyles,
  viewAll: {
    ...styles.cursorPointer,
    width: '100%',
    fontSize: 14,
  },
  sidebarIcons: {
    color: '#5C738E',
    '&:hover': {
      color: '#1061A0',
    },
  },
  sidebarIconsFacility: {
    color: '#5C738E',
    '&:hover': {
      color: '#1061A0',
    },
  },
  activeItem: {
    color: '#1061A0',
  },
  activeFacilityIcon: {
    color: '#1061A0',
  },
  dropdownIcon: {
    position: 'absolute',
    right: 10,
  },
  badge: {
    width: 20,
    minWidth: 20,
    height: 20,
    fontSize: 9,
    right: 5,
    top: 3,
    backgroundColor: '#E91F31',
    color: '#FFFFFF',
    fontWeight: 300,
  },
}));

export const useSidebarStyles = makeStyles(theme => ({
  root: {
    color: '#5C738E',
    minHeight: 48,
    fontWeight: 300,
    '&hover': {
      color: '#1061A0',
      backgroundColor: '#EFF4FB',
    },
  },
  active: {
    color: '#1061A0',
  },
  icon: { minWidth: theme.spacing(5) },
}));

// HACK to show active color for any route that starts with 'tasks'
export const useTasksSidebarStyles = makeStyles(theme => ({
  root: {
    color: props => (props.showActive ? '#1061A0' : '#5C738E'),
    minHeight: 48,
    fontWeight: 300,
    '&hover': {
      color: '#1061A0',
      backgroundColor: '#EFF4FB',
    },
  },
  active: {
    color: '#1061A0',
  },

  icon: { minWidth: theme.spacing(5) },
}));

// HACK to show active color for any route that starts with 'updates'
export const useUpdatesSidebarStyles = makeStyles(theme => ({
  root: {
    color: props => (props.showActive ? '#1061A0' : '#5C738E'),
    minHeight: 48,
    fontWeight: 300,
    '&hover': {
      color: '#1061A0',
      backgroundColor: '#EFF4FB',
    },
  },
  active: {
    color: '#1061A0',
  },

  icon: { minWidth: theme.spacing(5) },
}));

export const useSidebarFacilityStyles = makeStyles(theme => ({
  root: {
    color: '#5C738E',
    minHeight: 48,
    fontWeight: 300,
    '&hover': {
      color: '#1061A0',
      backgroundColor: '#EFF4FB',
    },
  },
  active: {
    color: '#1061A0',
  },

  icon: { minWidth: theme.spacing(5) },
}));
