import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { CRUD_CREATE, useCreate, useNotify } from 'react-admin';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useStyles } from '../modal.styles';

import { FileInput } from './inputs';
import { CloseIcon } from '../../../../design';
import { fileManagementActions } from '../../../../state/actions';

export const AddAttachmentForm = ({ formData, setFormData, isViewAdmin }) => {
  const classes = useStyles();
  return (
    <Form
      onSubmit={() => {}}
      initialValues={{
        ...formData,
      }}
      render={({
        submitError,
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.insuranceTitle}>
              Attachments
              <FileInput
                title='title'
                name='attachments'
                setFormData={setFormData}
                formData={formData}
                classes={{ dropZone: classes.dropZone }}
                isViewAdmin={isViewAdmin}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

export const AddAttachment = ({
  data,
  onClick,
  eventId,
  eventName,
  caseId,
  saveAndAttach,
  selected,
  handleClose,
  refresh,
  isViewAdmin,
}) => {
  const notify = useNotify();
  const dispatch = useDispatch();

  const handleAttach = (newIds = []) => {
    dispatch(fileManagementActions.addEmailFileIds([...selected, ...newIds]));
    handleClose();
  };

  const [createAtt, { loading }] = useCreate(
    'documents',
    { ...data, event_id: eventId, event_type: eventName, case_id: caseId },
    {
      action: CRUD_CREATE,
      onSuccess: ({ data: responseData }) => {
        notify('ra.notification.created', 'info', true);
        typeof refresh === 'function' && refresh();
        if (saveAndAttach) {
          const docIds = responseData.id;
          handleAttach(docIds);
        } else {
          handleClose();
        }
      },
      onFailure: error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
    },
  );
  const handleClick = () => {
    createAtt();
    if (typeof onClick === 'function') {
      onClick();
    }
  };
  return (
    <Button
      type='button'
      variant='text'
      size='small'
      onClick={handleClick}
      disabled={loading || isViewAdmin}
      style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: 'bold' }}
    >
      {saveAndAttach ? 'Save And Attach' : 'Save'}
    </Button>
  );
};

export const AddAttachmentModal = ({
  handleClose,
  title,
  open,
  eventId,
  eventName,
  caseId,
  refresh,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    attachments: [],
  });
  return (
    <Dialog open={open} onClose={handleClose} fullWidth disableScrollLock>
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <DialogTitle disableTypography id='form-dialog-title' align='left'>
        {title}
      </DialogTitle>
      <DialogContent>
        <AddAttachmentForm
          formData={formData}
          setFormData={setFormData}
          isViewAdmin={isViewAdmin}
        />
      </DialogContent>
      <DialogActions>
        {!!formData.attachments.length && (
          <AddAttachment
            data={formData}
            eventId={eventId}
            eventName={eventName}
            caseId={caseId}
            handleClose={handleClose}
            refresh={refresh}
            isViewAdmin={isViewAdmin}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};
