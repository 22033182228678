import React, { Fragment, useState, useEffect } from 'react';
import {
  useRefresh,
  TextField,
  FunctionField,
  TopToolbar,
  Pagination,
  usePermissions,
  useRedirect,
  Show,
  ListView,
  useListController,
} from 'react-admin';
import { Datagrid } from '../components/common';
import { NewEvent } from '../components/common/modals';
import { CustomButton } from '../design/material-ui';
import { useStyles } from '../resources/payers/payers.styles';
import { AddIcon, EditIcon } from '../design';
import { TrackUser } from '../components/wrappers';
import { Link } from '@material-ui/core';
import { GlobalGeneralInfo } from '../resources/payers/global-general-info';
import { Contracts } from '../pages/contracts';
import { ListSearch } from '../components/common/ListSearch';
const PayersActions = ({ basePath, data = {}, resource, setSearchText }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Global payers</span>

        {userPermissions.indexOf('admin') > -1 ||
        userPermissions.indexOf('contract_user') > -1 ? (
          <div style={{ display: 'flex' }}>
            <ListSearch
              doSearch={setSearchText}
              placeholder='Search payer name'
            />
            <div style={{ marginTop: 10 }}>
              <CustomButton
                Icon={AddIcon}
                color='#1061A0'
                backgroundColor='#EFF4FB'
                label='Add'
                type='button'
                variant='text'
                size='small'
                onClick={() => setEventModal(true)}
              />
            </div>
          </div>
        ) : (
          //for spacing...
          <div />
        )}
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='globalPayer'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const ShowActions = ({ basePath, data = {}, title, resource }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);

  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <div className={classes.title}>
          Payer details
          <div
            className={classes.subtitle}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: 10,
            }}
          >
            Last modified on <div style={{ width: 3 }} />
            <TrackUser timestamp={data.updated_at} />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <CustomButton
            Icon={EditIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Edit'
            type='button'
            variant='text'
            size='small'
            onClick={() => setEventModal(true)}
          />
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          record={data}
          form='globalPayer'
          isEdit
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

export const GlobalPayersList = props => {
  const classes = useStyles();
  const redirect = useRedirect();
  const [searchText, setSearchText] = useState('');
  const { permissions = '' } = props;
  const controllerProps = useListController({
    ...props,
    resource: 'global-payers',
    basePath: '/global-payers',
    filter: { q: searchText },
    sort: {
      field: 'name',
      order: 'ASC',
    },
  });
  if (!permissions) return null;
  const userPermissions = permissions.split(',');
  if (
    userPermissions.indexOf('admin') === -1 &&
    userPermissions.indexOf('supervisor') === -1 &&
    userPermissions.indexOf('contract_user') === -1
  ) {
    redirect('/');
    return null;
  }

  return (
    <ListView
      empty={false}
      {...controllerProps}
      exporter={false}
      bulkActionButtons={
        Array.isArray(userPermissions) &&
        userPermissions.indexOf('admin') === -1 &&
        userPermissions.indexOf('supervisor') === -1 &&
        userPermissions.indexOf('contract_user') === -1
          ? false // disable delete
          : undefined // will show default delete
      }
      className={classes.list}
      actions={<PayersActions setSearchText={setSearchText} />}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      pagination={
        <Pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          style={{
            display: 'flex',
          }}
        />
      }
    >
      <Datagrid rowClick='show'>
        <TextField
          source='name'
          label='Name'
          headerClassName={classes.listItemLabel}
        />
        <TextField
          source='parent_company'
          label='Parent company'
          headerClassName={classes.listItemLabel}
        />
        <TextField
          source='remit_address'
          label='Claims address'
          headerClassName={classes.listItemLabel}
        />
        <TextField
          source='state'
          label='State'
          headerClassName={classes.listItemLabel}
        />
        <TextField
          source='pre_auth_eligibility_contact'
          label='Benefits/eligibility phone'
          headerClassName={classes.listItemLabel}
        />
        <FunctionField
          label='Website'
          render={record => {
            const { website } = record;
            return website ? (
              <Link
                href={website}
                target='_blank'
                rel='noopener noreferrer'
                underline='none'
              >
                {website}
              </Link>
            ) : null;
          }}
        />
        <TextField
          source='submit_terms'
          label='Claim submission terms'
          headerClassName={classes.listItemLabel}
        />
        <TextField
          source='payment_terms'
          label='Claim payment terms'
          headerClassName={classes.listItemLabel}
        />
      </Datagrid>
    </ListView>
  );
};

const GlobalPayersShow = props => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <Show actions={<ShowActions />} {...props}>
        <GlobalGeneralInfo />
      </Show>
      <Show {...props}>
        <Fragment>
          <div className={classes.title} style={{ padding: 20 }}>
            Contracts
          </div>
          <Contracts {...props} />
        </Fragment>
      </Show>
    </Fragment>
  );
};

export const globalPayersResource = {
  name: 'global-payers',
  list: GlobalPayersList,
  show: GlobalPayersShow,
  icon: AddIcon,
  options: { label: 'Global payers' },
};
